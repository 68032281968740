import React, { Component } from "react";
import AppButton from "../components/AppButton";
import AppLink from "../components/AppLink";
import { magasinValidateScreenButtons } from "../Utils/Constants";

export default class MagasinValidate extends Component {
  state = {
    codeInput: "",
  };

  handleCodeInput = (e) => {
    let prevcodeInput = this.state.codeInput;
    const value = e.target.value;
    const codeInput = prevcodeInput + value;
    this.setState({ codeInput });
  };

  removePhoneDigit = () => {
    const initNumber =
      this.state.codeInput !== ""
        ? this.state.codeInput.replace(/\s/g, "")
        : "";
    const newNo = initNumber && initNumber.slice(0, -1);
    const _number = (newNo.match(/.{1,2}/g) || []).join(" ");
    this.setState({ codeInput: _number });
  };

  clearInput = () => {
    this.setState({ codeInput: "" });
  };

  validateCodeInput = () => {
    this.props.validateCodeInput(this.state.codeInput);
  };

  render() {
    const { phoneNumber, screen } = this.props;
    const { codeInput } = this.state;
    return (
      <div className="page magasin-transaction" id="magasinValidate">
          <React.Fragment>
            <div className="page-content" style={{ marginBottom: "40px" }}>
              <section className="hero">
                <div className="content-wrap">
                  <header className="magasin-back-icon-div magasin-validate-back">
                    <AppLink
                      onClick={(e) =>
                        this.props.goBack({
                          screen: 2,
                        })
                      }
                      className="btn btn-link txtLeft"
                      href="#"
                      anchor
                    >
                      <span className="icon back-arrow-icon icon-arrow-left icon-white iconBack"></span>
                    </AppLink>
                  </header>
                  <p
                    className="amountLabelTxt validateLabelTxt"
                    style={{ padding: "0px 30px", width: "unset" }}
                  >
                    Entrez le code reçu par SMS au {phoneNumber}
                  </p>
                  <div className="querryBox">
                    <div>
                      <span
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginBottom: "38px",
                        }}
                        className="amountSpan magasin-transaction-amount-span input-group"
                      >
                        <input
                          type="tel"
                          className="form-control magasin-transaction-input"
                          placeholder="000000"
                          defaultValue={codeInput}
                          style={{ pointerEvents: "none" }}
                        />
                        {/* <span className="icon icon-clear" onClick={this.clearInput}></span> */}
                      </span>
                      {this.props.validationError && <div style={{
                        marginTop: '-20px',
                        marginLeft: '20px',
                        color: 'red',
                        fontSize: '1.1em',
                      }}>Code erroné</div>}
                    </div>
                    <div>
                      <AppButton
                        className="btn btn-red removeBtn"
                        onClick={this.removePhoneDigit}
                        event="Remove phone number digit"
                      >
                        <img
                          src="/assets/tcaAssets/25112931/clear.png"
                          alt="clear"
                        />
                      </AppButton>
                    </div>
                  </div>
                </div>
                <AppLink
                  style={{
                    display: "block",
                    textAlign: "right",
                    marginRight: "40px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  className="back-to-init-btn"
                  href="#"
                  anchor
                  onClick={(e) =>
                    this.props.goBack({
                      screen: 2,
                      transformDirection: `translateX(-100%)`,
                    })
                  }
                >
                  Mauvais numéro ?
                </AppLink>
              </section>
            </div>
            <section className="content-wrap transaction-numpad-wrapper">
              <div className="transaction-num-pad">
                {magasinValidateScreenButtons.map((value, index) => {
                  return (
                    <AppButton
                      key={index}
                      onClick={
                        value != "Valider"
                          ? this.handleCodeInput
                          : this.validateCodeInput
                      }
                      value={value}
                      className={`btn btn-red ${value == "0" ? "m-35" : ""}`}
                      event={value}
                    >
                      {value}
                    </AppButton>
                  );
                })}
              </div>
            </section>
          </React.Fragment>

      </div>
    );
  }
}

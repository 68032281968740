import React from 'react';
import Magasin from './pages/Magasin';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Landing from './pages/Landing';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { Context, ContextUtil } from './tools/context';
import AdminPage from './pages/AdminPage';
import Profile from './pages/Profile';
import Logo from './components/Logo';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import DefaultPage from './pages/DefaultPage';
import ConnexionClient from './pages/Client/ConnexionClient';
import './style/index.scss';
import './style/custom.scss';
import Script from 'react-load-script';
import {initSiteContext} from './envConfig';
import {
  fetchPartner,
  siteContext,
  saveContext,
  saveUtmValues,
  hasUtmValues,
  getUtmValues,
  resetUtmValues,
  getQueryString, handlePWA,
} from './tools/toolbox';

import {checkUserData, getSeller, postUserData, getCustomerConfig, getAccount} from './tools/apitools';
import Register from './pages/Register';
import AddProduct from './pages/AddProduct';
import MesClients from './pages/MesClients';
import MonSolde from './pages/MonSolde';
import HowTo from './pages/HowTo';
import Activation from './pages/Activation';
import PageLoader from './containers/PageLoader';
import GenericHeader from './components/GenericHeader';
import DashboardNew from './pages/DashboardNew';
import TicketsPage from './pages/Client/TicketsPage';
import ClientLayout from './components/layouts/ClientLayout';
import TempPage from './pages/TemporaryPage';
import UserProfile from './pages/Client/UserProfile';
import MyTicketsUsed from './pages/Client/MyTicketsUsed';
import ConfirmedTicket from './components/ConfirmedTicket';
import SellerLayout from './components/layouts/SellerLayout';
import Withdraw from './pages/Withdraw';
import HomeDefault from './pages/Home/HomeDefault';
import Banner from './components/Popup/Banner';
import CustomerSignin from './pages/CustomerSignin';
import SavBanner from "./components/Popup/SavBanner";
import {
  AskRefund,
  ConfirmationRefund,
  ListRefund,
} from "./pages/Client/refund/";
import MyVouchers from "./pages/Client/MyVouchers";
import CookieConsent from "react-cookie-consent";
import * as tracking from './tools/tracking';
import {loadNamespace} from './tools/i18n';
import AppLink from "./components/AppLink";
import Etablissements from './pages/Etablissements';

library.add(faBars, faFileSignature);

function MainApp({test}) {
  const [partner, setPartner] = React.useState(false);
  const [loaded, setLoaded] = React.useState(!!test);
  React.useEffect(() => {
    // document.title = "Bienvenus";
    handlePWA();
    initSiteContext();
    tracking.init();

    ContextUtil.update('role', siteContext().role);
    ContextUtil.update('seller', siteContext().seller);
    ContextUtil.update('clientToken', siteContext().clientToken);

    saveContext();

    saveUtmValues();

    fetchPartner(test).then(async () => {
      await getCustomerConfig()

      // After client login, post saved utm_* values to /user/data
      if (siteContext().clientToken && hasUtmValues()) {
        await postUserData(getUtmValues(), () => {}, () => {});
        resetUtmValues();
      }

      if (getQueryString('tc') || getQueryString('ciblerClientToken')) {
        checkUserData(
          () => window.location.replace('/my-tickets'),
          () => window.location.replace('/profile-client'),
        );
      }

      if(siteContext().accessToken) {
        await getAccount();
        await getSeller(true);
      }

      setLoaded(true);
    });
  }, []);

  React.useEffect(()=>{
    const iframeTimeout = setInterval(function(){
      if (window.zE){
        Context.partner?.features?.hideOnExternalPages ?
          window.zE('webWidget', 'hide') : window.zE('webWidget', 'show')
        clearInterval(iframeTimeout);
      }
    }, 500);
  },[])

  const routes = [
    { path: '/connexion', component: Login, header: false ,banner: true},
    { path: '/partenaire', component: Login, header: false ,banner: true},
    { path: '/reset_password', component: ResetPassword, header: false, banner: true },
    { path: '/adminPage', component: AdminPage, header: true},
    { path: '/adminPage/:id', component: Profile, header: true},
    { path: '/profile', component: Profile, header: true ,banner: true},
    {
      path: '/home', component: Home,
      header: Context.partner.sponsor==='tca' || Context.partner.sponsor ==='myProvence',
      Layout: SellerLayout ,banner: true
    },
    { path: '/inscription', component: Register, header: Context.seller !== undefined && !getQueryString('iframe'), banner: true },
    { path: '/new-pos', component: Register, header: false, banner: true },
    { path: '/mes-avantages', component: Dashboard, header: true, savBanner: true},
    { path: '/addproduct', component: AddProduct, header: true, banner: true },
    { path: '/mes-clients', component: MesClients, header: true, banner: true },
    { path: '/activation', component: Activation, header: true, banner: true },
    { path: '/mon-solde', component: MonSolde, header: true, banner: true },
    { path: '/magasin-home', component: Magasin, header: false },
    { path: '/dashboard-new', component: DashboardNew, header: true, banner: true},
    { path: '/temporary', component: TempPage, header: true},
    { path: '/wtd', component: Withdraw, header: true },
    { path: '/customerAccount', component: CustomerSignin  },
    { path: '/etablissements', component: Etablissements, header: false , banner: true },
    { path: '/how-to', component: HowTo, header: true, banner: true },
  ];

  const clientRoutes = [
    { path: '/profile-client', component: UserProfile },
    { path: '/historique', component: MyTicketsUsed },
    { path: '/mes-code-promos', component: MyVouchers },
    { path: '/connexion-client', component: ConnexionClient, publicZone: true },
    { path: '/detenteur', component: ConnexionClient, publicZone: true },
    { path: '/portefeuille', component: TicketsPage },
    { path: '/my-tickets', component: TicketsPage },
    { path: '/refund/ask/:shortCode/:ticketId', component: AskRefund },
    { path: '/refund/confirmation', component: ConfirmationRefund },
    { path: '/refund/list', component: ListRefund },
  ]

  const defaultRoutes = [
    { path: '/', component: Landing, header: false, banner: true },
    { path: '/landing', component: Landing, header: false , banner: true }
  ]



  return (
    <div className={"bvnV2"}>
      { Context.partner?.features?.zendesk && <Script attributes={{id: 'ze-snippet', async: true}} url={`https://static.zdassets.com/ekr/snippet.js?key=${Context.partner?.features?.zendeskId}`}/>  }
      <ScrollToTop
        loading={!loaded}
        routes={routes}
        clientRoutes={clientRoutes}
        defaultRoutes={defaultRoutes}
      />
      <Switch>
        {routes.map((r) => {
          const Compo = r.component;
          return (
            <Route exact path={r.path} key={r.path}>
              <PageLoader loading={!loaded}>
                {Context.partner.banner && r.banner ? <Banner/> : null}
                {r.header && <GenericHeader />}

                {r.Layout ? (
                  <r.Layout>
                    <Compo />
                  </r.Layout>
                ) : (
                  <Compo />
                )}
                {Context.partner.savBanner && (r.banner || r.savBanner) ? <SavBanner/> : null}
              </PageLoader>
            </Route>
          );
        })}
        {clientRoutes.map((r) => {
          const Compo = r.component;
          return (
            <Route exact path={r.path} key={r.path}>
              <PageLoader loading={!loaded}>
                {Context.partner.banner && r.banner ? <Banner/> : null}
                <ClientLayout publicZone={r.publicZone}>
                  <Compo />
                </ClientLayout>
                {Context.partner.savBanner && (r.banner || r.savBanner) ? <SavBanner/> : null}
              </PageLoader>
            </Route>
          );
        })}
        {defaultRoutes.map((r) => {
          const Compo = r.component;
          return (
            <Route exact path={r.path} key={r.path}>
              <PageLoader loading={!loaded}>
                {Context.partner.banner && r.banner ? <Banner/> : null}
                <Compo />
                {Context.partner.savBanner && (r.banner || r.savBanner) ? <SavBanner/> : null}
              </PageLoader>
            </Route>
          );
        })}
        <Route component={DefaultPage}>
          <DefaultPage loaded={loaded} />
        </Route>
      </Switch>
      {siteContext().adminToken && siteContext().adminToken !== null  &&
      <div>
        {'logged in as :' +siteContext().seller.contactEmail}
        <AppLink onClick={()=>window.location.href='/adminPage'} className="btn btn-primary  btn-inline btn-sm mode-magasin">back to admin page</AppLink>
      </div>
      }
      <Footer />
      {Context.partner?.client?.cookieConsentText &&
      <CookieConsent
        style={{
          background: Context.partner.client.style.color2,
          color: Context.partner.client.style.color1,
        }}
        buttonStyle={{
          background: Context.partner.client.style.mainBtnColorBg,
          color: Context.partner.client.style.mainBtnColorText,
        }}
        declineButtonStyle={{
          background: Context.partner.client.style.mainBtnColorBg,
          color: Context.partner.client.style.mainBtnColorText,
        }}
        enableDeclineButton
        buttonText={"Accepter"}
        declineButtonText={"Refuser"}
      >
        <span dangerouslySetInnerHTML={{__html: Context.partner?.client?.cookieConsentText}} />
      </CookieConsent>
      }
    </div>
  );
}

export default MainApp;

import React from 'react'

const MagasinPointsCard = (props) => {
  return (
    <div className="card-user-wrap">
      <div className="card card-client red magasin-transaction-user-card">
        <div className="avatar-wrap">
          <img src="" className="avatar"/>
          <span className="icon icon-avatar"></span>
        </div>
        <div>
          <p>
            <strong> {props.phoneNumber} </strong>
          </p>
          <p>
            <small>
              <span>{props.nbVisite} visite{props.nbVisite > 1 && 's'}</span>
              <span style={{marginLeft: "1em"}}>
                {props.nbGiftPurchase} {props.nbGiftPurchase > 1 ? 'avantages utilisés' : 'avantage utilisé'}
              </span>
            </small>
          </p>
          <p>
            <small>
              <span>Fréquence d’achat <strong>{props.purchaseFrequency}</strong> par mois</span>
            </small>
          </p>

        </div>
        <div className="points">
          <p><strong>{props.currentBalance}</strong></p>
          <p>Point{props.currentBalance > 1 && 's'}</p>
        </div>
      </div>
    </div>
  )
}

export default MagasinPointsCard;

import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {getQueryString} from "../tools/toolbox";
import Header from './Landing/Header';
import VendorsMap from './Landing/VendorsMap';

class Etablissements extends Component {
  constructor() {
    super();

    this.state = {
      isLaunchDate: false,
      date: '',
      sellerShortCode: 'AAAAAA',
      refundPrizes: '9FI33KDVHDR1SA'
    };
  }

  componentDidMount() {
    if (window.location.hash === '#map' && document.querySelector('.map')) {
      setTimeout(() => document.querySelector('.map').scrollIntoView({behavior: "smooth"}), 500);
    }
    this.setState({refundPrizes: getQueryString('coupon')});
  }

  render() {
    return (
      <div className={`Etablissements ${this.props.platform}`}>
        <Header />
        <VendorsMap {...this.props} coupon={this.state.refundPrizes} displayShortcode />
      </div>
    );
  }
}

export default withRouter(Etablissements);
import React from 'react';
import AdminPage from './pages/AdminPage';
import Profile from './pages/Profile';
import {Route, Switch} from 'react-router-dom';
import {initSiteContext} from './envConfig';
import {
  siteContext,
} from './tools/toolbox';
import {ContextUtil} from "./tools/context";

function AdminApp() {
  if (!siteContext() || !Object.keys(siteContext()).length) {
    initSiteContext();
    if (!window.__STATE__) {
      window.__STATE__ = {
        customerKey: '',
      };
    }
    window.siteContext = {
      ...siteContext(),
      role: {
        ROLE_ADMIN: true,
      },
      ...window.__STATE__,
      partner: {
        sponsor: window.__STATE__.customerKey,
      }
    }

    ContextUtil.update('partner', {
      sponsor: window.__STATE__.customerKey.toLowerCase(),
    });
  }

  console.log({...siteContext()})
  return (
    <div className={"adminBvn"}>
      <Switch>
        <Route path={'/Account/AdminPage/:id'} component={Profile}/>
        <Route path={'/Account/AdminPage'} component={AdminPage}/>
        <Route>
          <div>No page found</div>
        </Route>
      </Switch>
    </div>
  );
}

export default AdminApp;

import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Context} from '../tools/context'
import AppLink from "./AppLink";
import Logo from "./Logo";
import Menu from "./Menu";

class GenericHeader extends Component {
    constructor() {
      super();
      this.state = {
          open: false,
      }
    }

  renderMagasin = () => {
    if (!['/mes-avantages', '/mon-solde', '/dashboard-new'].includes(this.props.location.pathname)) return;
    if (Context.partner?.navigation?.remove?.includes('/magasin-home')) return;

    return (
      <AppLink href="/magasin-home" className="btn btn-secondary btn-light btn-inline btn-sm mode-magasin">
        <span className="icon icon-star-filled start"> </span>
        MODE MAGASIN
        <span className="icon icon-star-filled end"> </span>
      </AppLink>
    )
  }

  handleMenuState = (state) => {
    this.setState({open: state})
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: 10,
      }}>
        <div style={{flex: 1}}>
          <Menu onChange={this.handleMenuState} open={this.state.open} />
        </div>
        <div style={{flex: 1}}>
          <Logo big style={{margin: '0 auto'}}/>
        </div>
        <div style={{flex: 1, textAlign: "right"}}>
          {this.renderMagasin()}
        </div>
      </div>
    );
  }
}

export default withRouter(GenericHeader)

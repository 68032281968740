import * as React from "react";
import {Link} from "react-router-dom";
import {track} from "../tools/tracking";

function AppLink({href, client, anchor, ...props}) {
  const handleClick = (e) => {
    track('click link', {
      href,
    }, client)
    props.onClick && props.onClick(e);
  }

  const children = props.dangerouslySetInnerHTML ? null : props.children

  if (!href || href.startsWith('//') || href.startsWith('http') || href.startsWith('mailto') || anchor) {
    return <a
      href={href}
      {...props}
      onClick={handleClick}
    >{children}</a>
  }

  return <Link
    to={href}
    {...props}
    onClick={handleClick}
  >{children}</Link>
}

export default AppLink;

import React, { useState } from 'react';
import { Context } from '../tools/context';
const MagasinFeedback = ({ backAndResetState, isSeller }) => {
	const [loaded, setLoaded] = useState(false);

	const onImageLoad = () => {
		setLoaded(true);
		setTimeout(() => {
			backAndResetState();
		},10000)
	};

	return (
		<div className="page magasin-init" id="magasinFeedback">
			<div className="magasin-init-text-div" style={{marginTop: "80px", textAlign: "center", marginBottom: "43px"}}>
				<p className="magasin-init-text">Transaction terminée !</p>
			</div>

			<div style={{paddingLeft: "29px", paddingRight: "29px", marginBottom: "26px"}}>
				<img src="/assets/tcaAssets/01162827/order-done2.png"
					alt="transactionfeedback" onLoad={() => onImageLoad()} />
			</div>
			{ loaded &&
				<>
					<div className={`clientFeedbackTextDiv ${isSeller ? "d-block" : "d-none"}`}
						style={{textAlign: "center", paddingLeft: "30px", paddingRight: "30px", color: "white", marginBottom: "57px"}}
					>
						{ isSeller
							? <p className="clientFeedbackText" style={{fontSize: "20px", fontWeight: "normal"}}>
									{Context.seller.name}
									<br/>
									vous remercie pour votre soutien !
								</p>
							: ""
						}
						<p style={{fontSize: "20px", fontWeight: "normal", marginTop: "10px"}}>À bientôt !</p>
					</div>

					<div style={{padding: "0px 30px 0px 30px", textAlign: "center", color: "white"}}>
						<p style={{fontWeight: "500", fontSize: "12px"}}>
							Fermeture dans 10 secondes
						</p>
						<div className="round-time-bar">
							<div />
						</div>
					</div>
				</>
			}
		</div>
	);
}

export default MagasinFeedback;


import React, {Component} from 'react';
import {siteContext, saveContext} from "../../tools/toolbox";
import AppButton from '../AppButton';
import AppLink from '../AppLink';

export default class UsedBudgetPopup extends Component {
  closePopup = () => {
    siteContext().budgetControled = new Date().getMonth();
    saveContext();
    this.props.close();
  }

  render() {
    const monthLabels = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

    return (
      <div id="usedBudgetPopup">
        <div id="popupCommerceBoxContent">
          <div className="popupContentWrapper">
            <div className="usedBudgetImgContainer">
              <img
                src="/assets/tcaAssets/25135841/security.png"
                alt="security"/>
            </div>
            <div className="usedBudgetContent">
              <h3 className="usedBudgetPopupHeading">Bravo ! Vous avez atteint le palier de {1000}€ de tickets utilisés
                pour le mois de {monthLabels[new Date().getMonth()]}</h3>
              <p className="usedBudgetPopupTxt">Nous devons justifier les versements sur votre compte. Envoyez vos
                preuves d’achat par e-mail et débloquez vos versements bancaires en un rien de temps.</p>
                <AppLink className="sendEmailBtn"
                   anchor
                   href="mailto:contact@ticket-commercant.fr?subject=Pièces justificatives"
                >
                  <AppButton
                    className="sendEmailBudgetPopup"
                    onClick={() => this.props.close()}
                    event="Envoyer par e-mail mes justificatifs"
                  >
                    Envoyer par e-mail mes justificatifs
                  </AppButton>
                </AppLink>
              <AppButton event="Je l’ai déjà fait" className="cancelBudgetPopup" onClick={this.closePopup}>
                Je l’ai déjà fait
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import * as React from "react";

function SvgInfoPicto(props) {
  return (
    <div
      className="infoPicto"
      style={{
        height: props.height || '20px',
        width: props.width || '20px',
        fontSize: props.height || '20px',
        borderRadius: props.height || '20px',
        ...props.style
      }}
    ><span>i</span></div>
  );
}

export default SvgInfoPicto;

import React from "react";
import "../style/loader.scss";
import LoaderSpinner from "react-loader-spinner";

const Loader = () => {
  const pathname = window.location.pathname;
  return (
    <div className={`LoaderWrapper ${pathname.includes("/magasin") ? "bg-magasin" : "bg-blue"}`}>
      <div>
        <LoaderSpinner
          type="ThreeDots"
          color={`${pathname.includes("/magasin") ? "white" : "#00BFFF"}`}
          height={100}
          width={100}
          className="Loader"
        />
      </div>
      <p>CHARGEMENT EN COURS....</p>
    </div>
  );
};

export default Loader;

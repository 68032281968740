import React, { Component } from 'react';
import Context from '../../tools/context';
import { authClient } from '../../tools/apitools';
import '../../style/TicketsPage.scss';
import moment from 'moment';
import Ticket from "./ticket";
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import Icon from "../../components/Icon";
import AppLink from '../../components/AppLink';
import { clientT } from '../../tools/i18n';

class MyVouchers extends Component {
    constructor() {
        super();
        this.state = {
            tickets: [],
            selectedTicket: null,
        }
        moment.locale('fr');
        this.getGifts();
    }

    onGetGiftResponse = (res) =>{
        if (!res) {
            this.setState({tickets: ['not']});
            return;
        }
        const buffer = res.filter(ticket => (!ticket.used));
        console.log(buffer)
        if (buffer.length !== 0)
            this.setState({tickets: buffer});
        else
            this.setState({tickets: ['not']});
    }

    onGetGiftError = (status, err) => {
        console.error('Either customerId is incorrect or partner is not correctly set', err);
        this.props.history.push('temporary');
    }

    getGifts = async () => {
            authClient(`api/bienvenus/user/ticket?customer=${Context.partner.customerId}`, {method: 'GET'},
              this.onGetGiftResponse,this.onGetGiftError);
    }

    toDisplay = () => {
        const { tickets } = this.state;

        if (tickets[0] !== 'not')
            return (
                <>
                    <div className='used'>
                        <div className="ticketList">
                            {tickets.map((ticket, index) => <Ticket
                              ticket={ticket}
                              index={index}
                              showPromo
                              handleSelection={(selectedTicket) => this.setState({selectedTicket})}
                            />)}
                        </div>
                    </div>
                </>
            );
        else
            return (
                <div>
                    <p>{clientT('myVouchers.noPromoCode')}</p>
                </div>
            );
    }

    render () {
        const {selectedTicket} = this.state;
        const sellerData = selectedTicket?.sellerData;
        return (
            <div className='my-tickets-container'>
                <h1 className="title">{clientT('myVouchers.title')}</h1>
                <hr />
                {this.toDisplay()}
                {selectedTicket && <TicketsInfoPopup
                  title={'Contactez le professionnel'}
                  lines={[
                      !!sellerData?.mail && <div className="popupLink"><Icon name="mail" /> <AppLink client anchor href={`mailto:${sellerData.mail}`}>{sellerData.mail}</AppLink></div>,
                      !!sellerData?.website && <div className="popupLink"><Icon name="website" /> <AppLink client href={sellerData.website || "#"}>consulter le site web</AppLink></div>,
                    !!sellerData?.phone && <div className="popupPhone"><Icon name="phone" /> {sellerData.phone}</div>,
                  ]}
                  close={() => this.setState({selectedTicket: null})}
                  crossButton renderBlock
                />}
            </div>
        )
    }
}

export default MyVouchers;

import React, {Component} from "react";
import {Context} from "../../tools/context";
import Logo from "../../components/Logo";
import LogoTicketCommercant from "../../components/LogoTicketCommercant";
import LogoNegative from "../../components/LogoNegative";

export default ({children}) => (
  <div className='login ticketCommercantLogin'>
    <div className="header" style={{display: "flex", padding: '10px 0'}}>
      <div style={{flex: 1,
        display: 'flex',
        textAlign: 'left',}}>
        <LogoTicketCommercant />
      </div>
      <Logo />
      <div style={{flex: 1}} />
    </div>
    <div className="background" style={{
      backgroundImage: `url('/assets/bg_tico.jpeg')`,
    }}>
      <LogoNegative big />
      <div className="formBody">
        {children}
      </div>
    </div>
  </div>
)
import React, { Component } from "react";
import ErrorPopup from "../components/Popup/ErrorPopup";
import Loader from "../components/Loader";
import "../style/MesClients.scss";
import {authCall} from "../tools/apitools";
import {customer} from "../tools/toolbox";
import AppButton from "../components/AppButton";
import {clientT} from "../tools/i18n";
export default class MesClients extends Component {
  state = {
    loader: false,
    isErrorPopup: false,
    content : null,
    search : null,
  };

  componentDidMount() {
    this.fetchMesClients();
  }

  fetchMesClients = async () => {
    await authCall(
      `/api/sellers/user/all?customerId=${customer()}`,
      {},
      (result) => {
        console.log(result);
        this.setState({content: result})
      },
      () => undefined
    );
  };

  searchUsers = (event) => {
    let keyword = event.target.value;
    this.setState({search:keyword})
  }


  render() {
    const { loader, isErrorPopup, content } = this.state;
    let phoneNo = "";
    const filteredCards = this.state.search
      ? content && content.filter(d => {
        let phone = d.userData.phone.toLowerCase()
        if (phone != undefined) {
          if (phone.startsWith("+33")) {
            phone = phone.replace('+33', "0");
          }

          if (phone.startsWith("33")) {
            phone = phone.replace('33', "0");
          }
        }
        return phone.includes(this.state.search.toLowerCase())
    })
      : content;

    const userCards = filteredCards && filteredCards.map((data,index) => {
        phoneNo = data.userData.phone;
        if (phoneNo != undefined) {
          if (phoneNo.startsWith("+33")) {
            phoneNo = phoneNo.replace('+33', "0");
          }

          if (phoneNo.startsWith("33")) {
            phoneNo = phoneNo.replace('33', "0");
          }
        } else { phoneNo = ""; }
        return <div
              className="card card-client"
              data-search={data.userData.phone}
              key={index}
            >
              <div className="avatar-wrap" style={{display: "none"}}>
                <img
                  src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                  className="avatar"
                />
              </div>
              <div className="content-wrap">
                <p>
                  <strong className="phone">
                    {(phoneNo.match(/.{1,2}/g) || []).join(" ")}{" "}
                  </strong>
                  <span className="points">
                      {clientT('myClients.point', {count: data.currentBalance})}
                  </span>
                </p>
                <p>
                  <small>
                    <span>{clientT('myClients.totalSpent', {count: data.totalSpent})}</span>
                    <span style={{marginLeft:"1em"}}>
                      {clientT('myClients.purchase', {count: data.purchaseFrequency})}
                    </span>
                  </small>
                </p>
                <p>
                  <small>
                    <span>
                      {clientT('myClients.giftTitle', {count: data.nbGiftPurchase})}
                    </span>
                    <span style={{marginLeft:"1em"}}>
                      {clientT('myClients.noOfVisitor', {count: data.nbVisite})}
                    </span>
                  </small>
                </p>
              </div>
            </div>
    });

    return (
    <React.Fragment>
      {
        loader ? <Loader /> :  <div className="dashboard">
        <main className="main" id="main">
          <div className="search-wrap">
            <div className="input-group icon-left">
              <input
                type="text"
                id="clientSearch"
                className="form-control"
                placeholder={clientT('myClients.searchPlaceholder')}
                onChange={(e)=>this.searchUsers(e)}
              />
              <span className="icon icon-glass"></span>
            </div>
            <AppButton
              className="btn btn-primary btn-light btn-inline"
              style={{ display: "none" }}
            >
              <span className="icon icon-export"></span>
            </AppButton>
          </div>
          <div id="offers">
            { userCards }
          </div>
          {isErrorPopup && <ErrorPopup error={isErrorPopup} />}
        </main>
        </div>
      }
     </React.Fragment>
    );
  }
}

import React, {Component} from 'react'
import {Context} from "../tools/context";
import '../style/custom.scss';
import LinkWrapper from "./LinkWrapper";

export default class Logo extends Component {
  render() {
    const src = Context.partner.customLogo
      ? `/assets/${Context.partner.sponsor}_logo.png`
      : '/assets/logo.png';
    const logoRedirection = Context.partner.logoClickRedirection || '/';
    return (
      <div
        className={`LogoBlock ${this.props.big ? 'big' : ''} ${this.props.className}`}
        style={this.props.style}
      >
        <LinkWrapper to={logoRedirection}>
        <img
          src={src}
          alt="" title="logo" 
          className="logoImage"/>
        </LinkWrapper>
      </div>
    );
  }
}

import React from 'react'
import {Context} from "../tools/context";

const MagasinStoreHeader = (props) => {
    return (
        <div className="shop-wrap">
          <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
                    <image xlinkHref={`https://cdn.cibler.io/seller/${props.sellerId}/storeimage.png`} x="-25" width="150" />
                  </pattern>
                </defs>
              <polygon className="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img)"/>
          </svg>
        <p><small id="storeName" className="storeName">{props.storeName || ''}</small></p>
      </div>
    )
};

export default MagasinStoreHeader;

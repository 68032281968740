import React, { Component } from "react";
import AppButton from "../components/AppButton";
import AppLink from "../components/AppLink";
import MagasinPointsCard from "../components/MagasinPointsCard";
import QrScanner from "../components/QrScanner";
import Context from "../tools/context";

const normalGiftsData = [
  {
    combinable: true,
    description: "GAMMA TESTING",
    discountType: "VALUE",
    distributionType: "Linear",
    draw: false,
    expirationDate: "2021-10-08T15:09:09Z",
    expirationDelay: 86400,
    generic: false,
    genericCode: null,
    giftDisplayType: "Code",
    giftType: "Virtual",
    id: 654267292,
    link: "",
    mailContent: null,
    maxValue: null,
    name: "654267292",
    offerId: null,
    paused: false,
    sponsor: null,
    stock: null,
    threshold: 34,
    thresholdType: "BASKET",
    tryIndex: 0,
    usedBudget: null,
    value: 0,
  },
  {
    combinable: true,
    description: "ABC TESTING",
    discountType: "PERCENT",
    distributionType: "Linear",
    draw: false,
    expirationDate: "2021-10-08T15:09:09Z",
    expirationDelay: 86400,
    generic: false,
    genericCode: null,
    giftDisplayType: "Code",
    giftType: "Virtual",
    id: 654278099,
    link: "",
    mailContent: null,
    maxValue: null,
    name: "654278099",
    offerId: null,
    paused: false,
    sponsor: null,
    stock: null,
    threshold: 3210,
    thresholdType: "BASKET",
    tryIndex: 0,
    usedBudget: null,
    value: 32,
  },
  {
    combinable: true,
    description: "ALPHA TESTING",
    discountType: "PERCENT",
    distributionType: "Linear",
    draw: false,
    expirationDate: "2021-10-08T15:09:09Z",
    expirationDelay: 86400,
    generic: false,
    genericCode: null,
    giftDisplayType: "Code",
    giftType: "Virtual",
    id: 654278099,
    link: "",
    mailContent: null,
    maxValue: null,
    name: "654278099",
    offerId: null,
    paused: false,
    sponsor: true,
    stock: null,
    threshold: 500,
    thresholdType: "BASKET",
    tryIndex: 0,
    usedBudget: null,
    value: 400,
  },
]

export default class MagasinGifts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeGifts: {},
      qrPopupOpen: this.props.magasinLite || false,
      activeGift: {},
    }
  }

  closeQrPopup = () => {
    if (this.props.magasinLite)
      this.props.backAndResetState();
    else
    this.setState({ qrPopupOpen: false});
  }
  handleCoupons = (coupon) => {
    // const gifts = {...this.state.activeGifts};
    // if (coupon.id in gifts) {
    //   delete gifts[coupon.id];
    // } else {
    //   gifts[coupon.id] = coupon;
    // }

    this.setState({activeGift: coupon});
  }

  proceedCoupons = () => {
    this.props.proceedCoupons(this.state.activeGift);
  }

  proceedQrCoupon = async (data,giftcode) => {
    await this.setState({activeGift: data, qrPopupOpen: false});
    this.props.proceedCoupons(data,giftcode);
  }

  renderDiscountType = (type) => {
    switch (type.toLowerCase()) {
      case 'value':
        return <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: "5px"}} width="20" height="20"
                    viewBox="0 0 25 25">
          <g>
            <path fill="#0091ff"
                  d="M17.5 5A12.5 12.5 0 1 0 30 17.5 12.483 12.483 0 0 0 17.5 5zm4.083 17.139c0 .083-.028.139-.028.194a.4.4 0 0 1-.056.167.736.736 0 0 1-.139.167 2.643 2.643 0 0 1-.417.306 6.742 6.742 0 0 1-.778.389 7.909 7.909 0 0 1-1.056.333 4.831 4.831 0 0 1-1.306.139 5.659 5.659 0 0 1-2.222-.417 4.355 4.355 0 0 1-1.694-1.222 5.407 5.407 0 0 1-1.083-1.972 5.482 5.482 0 0 1-.194-.722h-2.471l.472-1.028h1.861c-.028-.306-.056-.611-.056-.944 0-.361.028-.722.056-1.083h-2.333l.472-1.028h2.028a5.067 5.067 0 0 1 .194-.694 5.776 5.776 0 0 1 1.139-2.083 4.99 4.99 0 0 1 1.75-1.306 5.425 5.425 0 0 1 2.25-.444 4.393 4.393 0 0 1 1.056.109c.333.083.667.167.944.25a4.064 4.064 0 0 1 .778.361 3.1 3.1 0 0 1 .472.333c.083.083.139.167.167.194a.4.4 0 0 1 .056.167.542.542 0 0 1 .028.222c0 .083.028.194.028.306a1.145 1.145 0 0 1-.028.333.523.523 0 0 1-.056.222.607.607 0 0 1-.083.139.212.212 0 0 1-.139.056.7.7 0 0 1-.389-.194 3.18 3.18 0 0 0-.639-.417 3.718 3.718 0 0 0-.944-.417 3.754 3.754 0 0 0-1.333-.194 3.427 3.427 0 0 0-1.528.333 3.164 3.164 0 0 0-1.194 1 4.646 4.646 0 0 0-.75 1.583 1.2 1.2 0 0 1-.028.139h4.75l-.472 1.028H14.25c-.028.306-.056.639-.056.972 0 .361.028.722.056 1.056h4.944l-.472 1.028h-4.278v.056a4.273 4.273 0 0 0 .75 1.556 3.283 3.283 0 0 0 1.194.944 3.709 3.709 0 0 0 1.612.305 4.638 4.638 0 0 0 2.306-.611 3.18 3.18 0 0 0 .639-.417.981.981 0 0 1 .389-.194c.056 0 .083 0 .111.028a.383.383 0 0 1 .083.111c.028.056.028.139.056.222a1.358 1.358 0 0 1 .028.361c-.001.111-.029.222-.029.278z"
                  transform="translate(-5 -5)"/>
          </g>
        </svg>;
      case 'percent':
        return <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: "5px"}} width="20" height="20"
                    viewBox="0 0 25 25">
          <path id="Tracé_679" data-name="Tracé 679"
                d="M26.78,13.25h0a2.534,2.534,0,0,1-.658-2.474V10.75a2.556,2.556,0,0,0-1.816-3.132h0A2.538,2.538,0,0,1,22.489,5.8V5.775a2.556,2.556,0,0,0-3.132-1.816A2.534,2.534,0,0,1,16.883,3.3h0a2.574,2.574,0,0,0-3.633,0h0a2.55,2.55,0,0,1-2.474.658H10.75A2.556,2.556,0,0,0,7.617,5.775h0A2.538,2.538,0,0,1,5.8,7.591H5.775a2.544,2.544,0,0,0-1.816,3.132h0A2.579,2.579,0,0,1,3.3,13.2h0a2.574,2.574,0,0,0,0,3.633A2.534,2.534,0,0,1,3.958,19.3v.026a2.556,2.556,0,0,0,1.816,3.132h0a2.538,2.538,0,0,1,1.816,1.816v.026a2.556,2.556,0,0,0,3.132,1.816h0a2.579,2.579,0,0,1,2.474.658h0a2.574,2.574,0,0,0,3.633,0h0a2.55,2.55,0,0,1,2.474-.658h.026a2.556,2.556,0,0,0,3.132-1.816,2.538,2.538,0,0,1,1.816-1.816h.026a2.556,2.556,0,0,0,1.816-3.132h0a2.579,2.579,0,0,1,.658-2.474h0A2.527,2.527,0,0,0,26.78,13.25ZM9.96,11.723a1.764,1.764,0,1,1,1.764,1.764A1.764,1.764,0,0,1,9.96,11.723Zm2.264,7.713a1.054,1.054,0,0,1-.763.316,1.1,1.1,0,0,1-.763-.316,1.08,1.08,0,0,1,0-1.527L17.909,10.7a1.08,1.08,0,1,1,1.527,1.527Zm6.186.763a1.764,1.764,0,1,1,1.764-1.764A1.764,1.764,0,0,1,18.409,20.2Z"
                transform="translate(-2.55 -2.55)" fill="#f7b500"></path>
        </svg>;
      default:
        return null;
    }
  }

  render() {
    const {phoneNumber, transactionAmount} = this.props;
    return (
      <>
      <div className="page magasin-transaction" id="magasinTransaction">
        <div className="page-content">
          <section className="hero">
            <div className="content-wrap">
              <header className="page-header">
                <AppLink
                  className="btn btn-link magasinTransactionBackArrow"
                  onClick={() => this.props.goBack({screen: 5})}
                  href="#"
                >
                  <span className="icon icon-arrow-left icon-white iconBack"></span>
                </AppLink>
                <h1>Appliquez un avantage</h1>
              </header>
              <MagasinPointsCard {...this.props.clientData}/>
            </div>
          </section>
          <section className="content-wrap" id="mid-section">
            <div id="offersWrap">
              {
                this.props.giftList.map((item, index) => {
                  // const active = item.id in this.state.activeGifts;
                  const active = this.state.activeGift && this.state.activeGift.id === item.id;
                  // disable the gift if threshold gift type is "BASKET" and transaction amount < gift.threshold
                  const disableGift = item.thresholdType === 'BASKET' && transactionAmount < item.threshold ;
                  return <div
                    key={index}
                    className={`card card-offer ${item.paused || disableGift ? "inactive" : ""} `}
                    onClick={() => !(item.paused || disableGift) && this.handleCoupons(item)}
                  >
                    <div>
                      {this.renderDiscountType(item.discountType)}
                    </div>
                    <div className="inner-content-wrap">
                      <p style={{color: "rgba(0, 0, 0, 0.85)", fontSize: "12px"}}/>
                      <p className="offer"><strong>{item.description}</strong></p>
                      {item.sponsor
                        ? <div className="sponsor-div">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.125" height="15" viewBox="0 0 13.125 15">
                            <path id="Tracé_666" data-name="Tracé 666"
                                  d="M29,26v8.563c0,4.125,4.063,5.781,6.563,6.438,2.5-.625,6.563-2.312,6.563-6.437V26Zm8.75,10.156-2.187-1.219-2.187,1.219.5-2.438-1.844-1.687,2.5-.281,1.031-2.281,1.031,2.281,2.5.281L37.25,33.719Zm-3.469-1.219Z"
                                  transform="translate(-29 -26)" fill="#0091ff"></path>
                          </svg>
                          <p className="sponsor-text-custom">Offert par <strong>{item.sponsor}</strong></p>
                        </div>
                        : <p className="sponsor-text-custom">Reduction</p>
                      }
                    </div>
                    <div style={{marginLeft: "auto"}} className={`${item.threshold > 0 ? "points-required" : ""}`}>
                      <AppButton event={active ? 'Appliqué' : 'Appliquer'} className={`btn btn-inline btn-secondary ${active && 'active'} offer-btn`} disabled={disableGift}>
                        {active ? 'Appliqué' : 'Appliquer'}
                      </AppButton>
                    </div>
                  </div>
                })
              }
            </div>
          </section>
          <div
            className="transaction-amount-wrap magasin-transaction-amount-wrap expand"
            id="transaction"
          >
            {Context.partner &&
            Context.partner.features &&
            Context.partner.features.showQRScanner && (
              <div
                className="transaction-proceed scanner-cust"
                style={{ width: "100%" }}
              >
                <AppButton
                  className="btn btn-primary btn-outlined magasin-transaction-btn"
                  onClick={() => this.setState({ qrPopupOpen: true })}
                >
                  <svg
                    id="Scan"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 30 30"
                  >
                    <path
                      id="Tracé_716"
                      data-name="Tracé 716"
                      d="M0,0H30V30H0Z"
                      fill="none"
                    ></path>
                    <path
                      id="Tracé_717"
                      data-name="Tracé 717"
                      d="M4,7.75V6.5A2.5,2.5,0,0,1,6.5,4H9"
                      transform="translate(1 1)"
                      fill="none"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokelinewidth="2"
                      stroke-width="3"
                    ></path>
                    <path
                      id="Tracé_718"
                      data-name="Tracé 718"
                      d="M4,17v1.25a2.5,2.5,0,0,0,2.5,2.5H9"
                      transform="translate(1 4.25)"
                      fill="none"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokelinewidth="2"
                      stroke-width="3"
                    ></path>
                    <path
                      id="Tracé_719"
                      data-name="Tracé 719"
                      d="M16,4h2.5A2.5,2.5,0,0,1,21,6.5V7.75"
                      transform="translate(4 1)"
                      fill="none"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokelinewidth="2"
                      stroke-width="3"
                    ></path>
                    <path
                      id="Tracé_720"
                      data-name="Tracé 720"
                      d="M16,20.75h2.5a2.5,2.5,0,0,0,2.5-2.5V17"
                      transform="translate(4 4.25)"
                      fill="none"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokelinewidth="2"
                      stroke-width="3"
                    ></path>
                    <line
                      id="Ligne_23"
                      data-name="Ligne 23"
                      x2="12.188"
                      transform="translate(8.685 15.278)"
                      fill="none"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokelinewidth="2"
                      stroke-width="3"
                    ></line>
                  </svg>{" "}
                  <span>Validez ici votre chèque cadeau</span>
                </AppButton>
              </div>
            )}
            <div className="transaction-proceed" style={{width: "100%"}}>
              <AppButton
                className="btn btn-primary btn-outlined magasin-transaction-btn"
                onClick={this.proceedCoupons}
              >
                Suivant{" "}
                <span className="icon icon-arrow-right icon-white"/>
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    {this.state.qrPopupOpen && (
      <QrScanner closeQrPopup={() => { this.closeQrPopup()}} proceedQrCoupon={this.proceedQrCoupon} validateCoupon={true} />
    )}
    </>
    )
  }
}

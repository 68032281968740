import React, { Component } from 'react'
import Loader from '../components/Loader'

export default class PageLoader extends Component {
    render() {
        const { loading } = this.props;

        return (
            <React.Fragment>
            {
              loading ? <Loader /> : this.props.children
            }
            </React.Fragment>
        )
    }
}

import Context from './context';
import { categories, categoryList, departments, technologies } from '../Utils/Constants';
import {t} from './i18n'

const fields = () => ({
  name: {
    label: "Nom de l'enseigne commerciale",
  },
  legalname: {
    label: 'Raison sociale',
  },
  registrationNumber: {
    label: 'Numéro de SIRET',
    pattern: '\\d{14}',
    errorMsgKey: 'registrationNumberErrorMsg',
    customError: 'Le SIRET doit contenir 14 chiffres',
  },
  address: {
    label: 'Adresse',
  },
  zipcode: {
    label: 'Code Postal',
    pattern: '\\d{5}',
    defaultLabel: '-- Sélectionner un code postal --',
    errorMsgKey: 'ZipCodeError',
    type:
      Context.partner?.zipcode?.length ? 'select' : 'text',
    values: Context.partner.zipcode,
    // errorMsg: this.state.showZipCodeErrorMessage ? this.errorMessages.zipcodeErrorMessage : null,
  },
  city: {
    label: 'Ville',
    type:
      Context.partner.city && Context.partner.city.length ? 'select' : 'text',
    defaultLabel: '--Choisir une ville--',
    values: Context.partner.city,
  },
  country: {
    label: 'Pays',
    type: Context.partner?.clientProfile?.countryList?.length? 'select' :'text',
    defaultLabel: '-- Sélectionner un pays --',
    values: Context.partner?.clientProfile?.countryList || null
  },
  description: {
    label: 'Description de votre établissement',
    type: 'textArea',
    defaultLabel: '--Choisir une description--',
    values: Context.partner.description,
    rows: 4,
    optional: true
  },
  departments: {
    label: 'Département',
    type: 'select',
    defaultLabel: '--Choisir un département--',
    values: departments,
  },
  category: {
    label: 'Catégories',
    type: getCategoriesType(),
    defaultLabel: '--Choisir une catégorie--',
    values: getCategories(),
  },
  website: {
    label: 'Site internet',
    type: 'text',
    placeholder: 'https://www.exemple.com',
    optional:true
  },
  tech: {
    label:"Technologie utilisée",
    type: 'select',
    defaultLabel: '--Choisir une technologie--',
    values:technologies,
    optional:true
  },
  contactPhone: {
    label: 'Téléphone de l\'établissement',
    type: 'phone',
    placeholder: '06XXXXXXXX',
    errorMsgKey: 'contactPhoneError',
  },
  ownerPhone: {
    label: 'Téléphone mobile de contact',
    subLabel: 'vous y recevrez des SMS pour vous connecter à votre compte',
    type: 'phone',
    errorMsgKey: 'ownerPhoneError',
  },
  loginEmail: {
    label: 'Email de connexion',
    pattern: '^[^@\\s]+@[^@\\s\\.]+\\.[^@\\.\\s]+(\\.[a-z]+)*$',
    errorMsgKey: 'EmailError',
    customError: "Veuillez renseigner une adresse email valide.",
  },
  contactEmail: {
    label: 'Email de l\'établissement',
    pattern: '^[^@\\s]+@[^@\\s\\.]+\\.[^@\\.\\s]+(\\.[a-z]+)*$',
    errorMsgKey: 'EmailError',
    customError: "Veuillez renseigner une adresse email valide.",
  },
  password1: {
    label: 'Mot de passe',
    type: 'password',
    pattern: '.{8,}',
    errorMsgKey: 'PasswordError',
    customError: 'Votre mot de passe doit contenir au moins 8 caractères',
    // errorMsg: this.state.showPasswordErrorMessage ? this.errorMessages.passwordErrorMessage : null,
  },
  password2: {
    label: 'Confirmez votre mot de passe',
    type: 'password',
    pattern: '.{8,}',
    customError: 'Votre mot de passe doit contenir au moins 8 caractères',
    errorMsgKey: 'PasswordError',
    // errorMsg: this.state.showPasswordErrorMessage ? this.errorMessages.passwordErrorMessage : null,
  },
  iban: {
    label: 'IBAN',
    subLabel: 'IMPORTANT: Vous recevrez les remboursements sur ce compte',
    pattern: '[a-zA-Z]{2}\\s?\\d{2}\\s?\\d{4}\\s?\\d{4}\\s?\\S{4}\\s?\\S{4}\\s?\\S{4}\\s?\\S{2,}',
    customError: 'L\'IBAN doit être sous le format FR12 1234 1234 1234 12A4 123412',
    optional: Context.partner.ibanOptional ? true : false
  },
  bic: {
    label: 'BIC',
    pattern: '\\S{8,11}',
  },
  title: {
    label: 'Civilité',
    type: 'select',
    defaultLabel: '--Civilité--',
    values: [
      {value: "Monsieur", label: "Monsieur"},
      {value: "Madame", label: "Madame"},
    ],
  },
  firstname: {
    label: 'Prénom',
  },
  lastname: {
    label: 'Nom',
  },

  signatureTitle: {
    label: 'Civilité',
    type: 'select',
    defaultLabel: '--Civilité--',
    values: [
      {value: "Monsieur", label: "Monsieur"},
      {value: "Madame", label: "Madame"},
    ],
  },
  customTextField1:{
    label:'custom'
  },
  signatureFirstname: {
    label: 'Prénom',
  },
  signatureLastname: {
    label: 'Nom',
  },
  fonction:{
    label:'Fonction',
  },
  birthdate: {
    label: 'Date de naissance',
    type: 'date',
    pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
    errorMsgKey: 'BirthdateError',
  },
  image: {
    label: 'Importer',
    type: 'import',
    optional: true,
  },
  imagepartner: {
    label: 'importer par plateforme',
    type: 'import',
    optional: true,
  },


});

const fieldBase = {
  'Mon établissement': {
    name: true,
    legalname: true,
    contactPhone: true,
    contactEmail: true,
    registrationNumber: true,
    address: true,
    zipcode: true,
    city: true,
    description: true,
    website:true,
    tech:true
  },
  "Département de l'établissement": {
    departments: false,
  },
  "Catégorie de l'établissement": {
    category: true,
  },
  "Image de l'établissement (560 × 360 px)": {
    image: false,
  },
  IBAN  : {
    iban: false,
  },
  BIC: {
    bic: false,
  },
  "Responsable de l'établissement": {
    title: false,
    firstname: false,
    lastname: false,
    ownerPhone: true,
  },
  "Signataire de la convention": {
    signatureTitle: false,
    signatureFirstname: false,
    signatureLastname: false,
    fonction:false
  },
  'Mes identifiants de connexion': {
    loginEmail: true,
    password1: true,
    password2: true,
  },
};

const userProfileFieldBase = {
  Informations: {
    firstname: true,
    lastname: true,
    birthdate: true,
    contactEmail: true,
    customTextField1: false
  },
  Adresse: {
    zipcode: true,
    country: true,
  },
};

const formatFields = (scheme) => {
  const base = scheme || fieldBase;
  let output = {};

  const gotSub = Context.partner.register && Context.partner.register.subLabels;
  const sub = Context.partner.register?.subLabels;

  Object.keys(base).forEach((g) => {
    const group = base[g];
    if (!Object.keys(group).filter((k) => group[k] && fields()[k]).length)
      return;

    output[g] = Object.keys(group)
      .filter((k) => group[k] && fields()[k])
      .map((f) => ({
        ...fields()[f],
          id: f,
          label: t(`form.${f}`, { fallback: fields()[f].label }),
          subLabel: gotSub ? sub[f] : fields()[f].subLabel,
        }
      ));
  });

  if (Object.keys(output).length === 1) {
    output = { oneGroup: Object.values(output)[0] };
  }

  return output;
};

const formatFieldToArray = (scheme, required, optional) => {
  const base = scheme || fieldBase;
  let output = [];

  Object.keys(base).forEach((g) => {
    const group = base[g];

    output = output.concat(
      Object.keys(group).filter(
        (k) => group[k] && fields()[k]
          && ((!fields()[k].optional || (required || []).includes(k)) && !(optional || []).includes(k))
      )
    );
  });

  return output;
};

const setFields = (scheme, list) => {
  Object.keys(scheme).forEach((g) => {
    const group = scheme[g];
    Object.keys(group).forEach((f) => {
      group[f] = list.includes(f);
    });
  });

  return scheme;
};

const updateFieldsList = (scheme, list, set) => {
  list.forEach((k) => {
    Object.keys(scheme).forEach((g) => {
      const group = scheme[g];
      if (Object.keys(group).includes(k)) {
        group[k] = set;
      }
    });
  });

  return scheme;
};

const updateFields = (scheme, list, set) => {
  list.forEach((k) => {
    Object.keys(scheme).forEach((g) => {
      const group = scheme[g];
      const field = group.find(f => f.id === k);
      if (field) {field.optional = !set;}
    });
  });

  return scheme;
};

const getFields = (field, base = fieldBase) => {
  if (
    !Context.partner[field] ||
    (!Context.partner[field].addFields &&
      !Context.partner[field].removeFields &&
      !Context.partner[field].requiredFields &&
      !Context.partner[field].optionalFields
    )
  )
    return formatFields(base); //context partner fields

  let scheme = JSON.parse(JSON.stringify(base)); // lazy clone
  scheme = updateFieldsList(scheme, Context.partner[field].addFields || [], true);
  scheme = updateFieldsList(
    scheme,
    Context.partner[field].removeFields || [],
    false
  );

  let fieldList = formatFields(scheme);
  fieldList = updateFields(
    fieldList,
    Context.partner[field].requiredFields || [],
    true
  );
  fieldList = updateFields(
    fieldList,
    Context.partner[field].optionalFields || [],
    false
  );

  return fieldList
};

const getFlatFieldKeys = (field) => {
  if (
    !Context.partner[field] ||
    (!Context.partner[field].addFields &&
      !Context.partner[field].removeFields &&
      !Context.partner[field].requiredFields &&
      !Context.partner[field].optionalFields
    )
  )
    return formatFieldToArray(); //context partner fields

  let scheme = JSON.parse(JSON.stringify(fieldBase)); // lazy clone
  scheme = updateFieldsList(scheme, Context.partner[field].addFields || [], true);
  scheme = updateFieldsList(
    scheme,
    Context.partner[field].removeFields || [],
    false
  );

  return formatFieldToArray(scheme, Context.partner[field].requiredFields, Context.partner[field].optionalFields);
};

const getCompletionFields = () => {
  if (!Context.partner.profile || !Context.partner.profile.requiredFields)
    return {}; //context partner fields

  let scheme = JSON.parse(JSON.stringify(fieldBase)); // lazy clone
  scheme = setFields(scheme, Context.partner.profile.requiredFields, true);

  return formatFields(scheme);
};

const partnerName = () => {
  switch (Context.partner.sponsor) {
    case 'stbrieucarmoragglo':
      return ' et Saint Brieuc Armor Agglomération';
    case 'lavalagglo':
      return ' et Laval Agglomération';
    default:
      return '';
  }
};

const getCheckboxes = (profile) => {
  const list =
    Context.partner.optins && Context.partner.optins.length
      ? Context.partner.optins
      : [
          {
            id: 'eligibleTypeOfShop',
            label: `Afin de profiter du service Ticket Commercant, je certifie que
            mon entreprise répond aux critères suivants*: - Commerce de
            détail hors GMS (hypermarché, supermarché, supérette),
            Bar-tabac-presse-jeux, Pharmacie, Banque, Assurance, Agence
            immobilière, Auto-école, Garage, Vente hors magasin (marché,
            porte à porte, vente via des automates).`,
            optional: false,
          },
          {
            id: Context?.partner?.sponsor
              ? `${Context.partner.sponsor}_optInMarketing`
              : `optInMarketing`,
            label: t('optInMarketing', {partnerName: partnerName()}),
            optional: true,
          },
          {
            id: 'optInTermsAndConditions',
            label: `J'ai lu et j'accepte les <a target=\"_blank\" href="https://ticket-commercant.fr/cgu/">
              conditions générales d'utilisation.*</a>`,
            optional: false,
          },
        ];

  return list;
};

const getCategoriesType = () => {
  if (Context?.partner?.filteredCategories) return 'filteredSelect';

  return "select"
}

const getCategories = () => {
  if (Context?.partner?.filteredCategories) {
    const output = {};
    for (let lab in Context.partner.filteredCategories) {
      output[lab] = setCategoriesLabel(Context.partner.filteredCategories[lab]);
    }

    return output;
  }

  if (!Context.partner.categories?.length) {
    return categories.map((c) => ({ value: c, label: categoryList[c] }));
  }

  return setCategoriesLabel(Context.partner.categories);
};

const setCategoriesLabel = (input) => {
  const output = [];
  input.forEach((c) => {
    if (c.value) {
      const line = categoryList[c.value];
      output.push({ value: c.value, label: line ? c.label : '' });
    } else {
      const line = categoryList[c];
      output.push({ value: c, label: line });
    }
  });

  return output;
}

const getCategoryKeys = () => {
  if (Context?.partner?.filteredCategories) {
    const output = {};
    for (let lab in Context.partner.filteredCategories) {
      Context.partner.filteredCategories[lab].forEach(c => {
        if(c.value) {
          output[c.value] = c.label;
        }
      });
    }

    return output;
  }

  if (!Context.partner.categories?.length) {
    return categoryList;
  }

  const output = {...categoryList};
  Context.partner.categories.forEach(c => {
    if(c.value) {
      output[c.value] = c.label;
    }
  });

  return output;
};

export {
  fields,
  getFields,
  getCheckboxes,
  getCompletionFields,
  getFlatFieldKeys,
  getCategories,
  getCategoryKeys,
  partnerName,
  fieldBase,
  userProfileFieldBase,
};

import React, { Component } from 'react';
import { Context } from '../../tools/context';
import '../../style/homeMyprovence.scss';
import Logo from '../../components/Logo';
import {Link, withRouter} from "react-router-dom";
import GenericHeader from "../../components/GenericHeader";

class HomeTCA extends Component {
  constructor(props) {
    super(props);

    //props.history.replace('profile')
     props.history.replace('dashboard-new')
  }
  render() {
    return null;
  }
}

export default withRouter(HomeTCA);

import React, {useState} from "react";
import {clientT, t} from "../../../tools/i18n";
import {withRouter} from "react-router-dom";
import InfoPicto from "../../../components/InfoPicto";
import AppLink from "../../../components/AppLink";
import TicketsInfoPopup from "../../../components/Popup/TicketsPopup";

const ConfirmationRefund = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <div className="Refund Confirmation">
        <div className="title">{clientT('refund.confirmation.title')}</div>
        <div className="subTitle">{clientT('refund.confirmation.subTitle')}</div>
        <div className="image">
          <svg width="113" height="111" viewBox="0 0 113 111" fill="none" xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M110.595 55.4558C110.595 84.9503 86.3135 108.912 56.2974 108.912C26.2813 108.912 2 84.9503 2 55.4558C2 25.9612 26.2813 2 56.2974 2C86.3135 2 110.595 25.9612 110.595 55.4558Z"
                fill="white" strokeWidth="4"/>
          <rect x="36.5376" y="35.9912" width="40.2657" height="39.6637" fill="url(#pattern0)" stroke="none"/>
          <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image0_1809_1259" transform="translate(0 -0.00758819) scale(0.0416667 0.042299)"/>
            </pattern>
            <image id="image0_1809_1259" width="24" height="24"
                   xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACA0lEQVR4nO3WWyhkcRwH8N/svC2rtbXtnt1ZO/Ysu812Zp3V1rbJIDLKJcWDy5NbopDLg4gSHhQRDxJ5QYkSLybRoBTlOkOYwUxjZJQUhidzxu+4zHSMcc7M83zq1O/3ezjfzjn1+x8RPCc8niCJwD7D0XE8Y7e/wolbIUES44X1Wndybs2HlWkTjjhcA+i4T9T3z8u9dVXET+lXCPD3w+HLtvYNkFHdcKw9MP97GuISQCZmLEy0N/+XkVJ4RMSlguX0DCsnyYf3cKgaxeqeRr8PSSXVapNqJAZbB5eAkORMg258UApeCE3JMuonhoKxdBDhxRUWaReLxWCz2bARjpZTsKbRAqzPc+7JaYD9oLTC1jE8BgGB73AgHMMwkKuM5glITxeD/uSmf2oOG88w+MS5Cfj6fQHu+AJ4+QJ4CQt4WBVeBQhaFSxcdjSFi0uLi8sDYbjs1nmXHQsD7GtzWHhORCu8D1jQbIMipxgomQzaygog+o8cp1yCAtwdOPKsQsirb75b462lhaAd6sYpl6AD54syTT3Z1RL1iwzGzkm9qoHy9h6sADoriiDitwwrp43dPVAWV6osM+MJ2DqI8OIKjw2ivkkWBxprCXkoiQN+G7o9yK5psmweGv/C0qwZRw6uASwM+fjWv+eN3+sfepNZCi9gf1sur652LNbz/Kc3Z90C7gYdKGceFYQAAAAASUVORK5CYII="/>
          </defs>
        </svg>
        </div>
        <div className="bigText">{clientT('refund.confirmation.explanation')}</div>
        <button className="moreInfoButton" onClick={() => setShowPopup(true)}>
          <InfoPicto/><span className="text">{clientT('refund.confirmation.moreInfo')}</span>
        </button>
        <AppLink href={'/my-tickets'} className="mainButton" client>{clientT('common.finish')}</AppLink>
        <AppLink href={'/refund/list'} className="secButton" client>{clientT('refund.confirmation.seeMyRefunds')}</AppLink>
        {showPopup &&
          <TicketsInfoPopup
            close={() => setShowPopup(false)}
          >
            <div className="title">{clientT('refund.confirmation.popup.title')}</div>
            <div className="subTitle">{clientT('refund.confirmation.popup.subTitle')}</div>
            <div className="popupBlock" dangerouslySetInnerHTML={{__html: clientT('refund.confirmation.popup.customer')}} />
            <div className="popupBlock small"  dangerouslySetInnerHTML={{__html: clientT('refund.confirmation.popup.notCustomer')}} />
          </TicketsInfoPopup>
        }
      </div>
    </>
  );
}

export default withRouter(ConfirmationRefund);
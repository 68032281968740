import React, {Component} from 'react';
import '../../style/TicketsPage.scss';
import {authClient} from '../../tools/apitools';
import {getQueryString, getPartnerDenomination, getTicketDenomination} from '../../tools/toolbox';
import MyTickets from './MyTickets';
import TicketConsumer from "./TicketConsumer";
import TicketExchange from "../../components/TicketExchange";
import ConfirmedTicket from "../../components/ConfirmedTicket"
import Context from '../../tools/context';
import moment from 'moment';
import {withRouter} from 'react-router';
import TicketsPopup from '../../components/Popup/TicketsPopup';
import {popupTexts, popupTypes} from '../../Utils/Constants';
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import AppButton from '../../components/AppButton';
import {clientT, t} from "../../tools/i18n";
import {Link} from "react-router-dom";
import AppLink from "../../components/AppLink";
import {track} from "../../tools/tracking";

class TicketsPage extends Component {
    constructor() {
        super();

        this.shortcode = getQueryString('shortcode');
        this.coupon = getQueryString('coupon');

        this.initialState = {
            displayList: false,
            tickets: [],
            selectedTickets: [],
            showTicketsConsumer: false,
            showTicketsExchange: false,
            showTerminationPage: false,
            showSelectedPan: false,
            canConfirm: false,
            confirmPopup: false,
            notConfirmedPopup: false,
            confirmed: false,
            shortCode: '',
            sellerName: '',
            totalPrize: '',
            activationError: '',
            activationLoader: false,
            partnerName: '',
            infoPopup: false,
            typePopup: '',
            title: '',
            lines: [],
            exchangeData: ''
        };

        this.state = {...this.initialState};
    }

    onGetGiftResponse = (res) => {
        // console.log(res)
        const buffer = res?.couponList?.filter(ticket => (ticket.used === false && (ticket.giftType && ticket.giftType.toLowerCase() === "standard") && moment().isBefore(ticket.expiration)));
        console.log('buffer', buffer)
        if ((!buffer || !buffer.length) && !Context.partner?.popupTexts?.noPopup) {
            if (res.notEligible) {
                this.setState({typePopup: popupTypes.notEligible})
                return;
            }
            if (res.noCampaign) {
                this.setState({typePopup: popupTypes.noCampaign})
                return;
            }
            if (res.hasTicket) {
                this.setState({typePopup: popupTypes.allUsed})
                return;
            }

            this.setState({typePopup: popupTypes.noTicket})
            return;
        }

        if (buffer.length !== 0) {
            this.setState({tickets: buffer});

            const coup = buffer.find(b => b.prize?.toLowerCase() === this.coupon?.toLowerCase());
            if (this.coupon && coup) {
                this.setState({
                    selectedTickets: [coup],
                    showSelectedPan: true,
                    showTicketsConsumer: true,
                    shortCode: this.shortcode,
                    tickets: []
                })
            }
        } else {
            this.setState({tickets: []});
        }
    }

    onGetGiftError = (status, err) => {
        console.error('Either customerId is incorrect or partner is not correctly set', err);
        this.setState({typePopup: 'custom', title: "Une erreur est survenue"});
        // this.props.history.push('temporary');
    }

    getGifts = async () => {
        let sellerMode = getQueryString("seller");
        if (!sellerMode) {
            authClient(`api/bienvenus/user/ticketV2?customer=${Context.partner.customerId}`, {method: 'GET'},
                this.onGetGiftResponse, this.onGetGiftError);
        }
        this.setState({activationLoader: false});
    }

    componentDidMount() {
        this.getGifts();
    }

    handleActivateCode = (input) => {
        this.setState({activationError: '', activationLoader: true});
        if (!input) return this.setState({activationError: 'Le code est vide.', activationLoader: false})

        if (Context.features.claimCode) {
            authClient(`/api/prizes/claim?pwd=${input}&customerId=${Context.partner.customerId}`, {
                    method: 'GET'
                },
              (res) => {
                if (!res.length)
                  return this.setState({activationError: 'Le code est invalide.', activationLoader: false})
                this.getGifts();
              },
              (_e, status) => {
                    let error;
                    if (status === 404) error = 'Le code est invalide.'
                    else error = 'Une erreur est survenue';

                    this.setState({activationError: error, activationLoader: false});
                });
        } else {
            authClient(`/api/bienvenus/earn/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        code: input,
                        customerId: Context.partner.customerId,
                    })
                },
                this.getGifts, (_e, status) => {
                    let error;
                    if (status === 404) error = 'Le code est invalide.'
                    else error = 'Une erreur est survenue';

                    this.setState({activationError: error, activationLoader: false});
                });
        }
    }

//TODO AUCUN CODE DISPO REVENEZ LUNDI

    select = (ticket) => {
        let selectedTickets = this.state.selectedTickets;

        if (!selectedTickets.length) return [ticket];

        const i = selectedTickets.findIndex(id => id.uuid === ticket.uuid);
        if (i >= 0) {
            selectedTickets.splice(i, 1);
            return selectedTickets;
        }

        if (Context.features.disableMultiSelection) return [ticket];

        if (selectedTickets[0].campaignId !== ticket.campaignId) {
            this.setState({typePopup: popupTypes.severalCampaigns})
            return selectedTickets;
        }

        selectedTickets.push(ticket);
        return selectedTickets;
    }

    handleSelection = (ticket) => {
        const selectedTickets = this.select(ticket);

        this.setState({
            selectedTickets,
            showSelectedPan: selectedTickets.length > 0,
        })
    }

    handleTicketConsumer = () => {
        this.setState({showTicketsConsumer: !this.state.showTicketsConsumer});
    }

    toggleInfoPopup = () => {
        this.setState({infoPopup: !this.state.infoPopup});
    }

    trackTicket = () => {
        if (!Array.isArray(this.state.selectedTickets)) return;
        track('viewConfirmPopup', {
            prize: this.state.selectedTickets.map(ticket => ticket.prize).join('|'),
            uuid: this.state.selectedTickets.map(ticket => ticket.uuid).join('|'),
            shortcode: this.state.shortCode
        });
    }

    handleConfirmed = (showTicketsConsumer) => {
        if (!showTicketsConsumer) {
            return this.setState({showTicketsConsumer: true});
        }
        if (this.state.canConfirm)
            if (Context.features.bypassBurnConfirmation) {
                this.handleBurn(this.state.selectedTickets)
            } else {
                this.setState({confirmPopup: true});
                this.trackTicket();
            }
    }

    onBurnResponse = (res) => {
        if (this.state.showTicketsExchange) {
            this.setState({
                showTerminationPage: true,
                exchangeData: res?.exchangeData,
                sellerName: res?.sellerName,
                totalPrize: res?.totalPrize
            });
        } else {
            this.setState({
                confirmed: true, confirmPopup: false,
                sellerName: res.sellerName, totalPrize: res.totalPrize
            });
        }
    }

    onBurnError = (error, status) => {
        this.setState({typePopup: popupTypes[error.message] || popupTypes.unknownError, confirmPopup: false});
    }

    handleBurn = async (selectedTickets) => {
        try {
            authClient(
                `api/${this.state.showTicketsExchange ? "prizes/exchange" : "bienvenus/coupons/burn"}/${Context.partner.customerId}/?shortcode=${this.state.shortCode}`,
                {
                    method: 'POST',
                    body: this.state.showTicketsExchange ? selectedTickets[0]?.uuid : JSON.stringify(selectedTickets.map(t => t.uuid))
                },
                this.onBurnResponse,
                this.onBurnError,
            );
        } catch (err) {
            console.error(err);
        }
    }

    popUp = (canConfirm, shortCode, name) => {
        this.setState({canConfirm: canConfirm, shortCode: shortCode, partnerName: name});
    }

    reset = () => {
        this.setState({...this.initialState})
        this.getGifts();
    }

    renderSelectPan = () => {
        const {
            showSelectedPan,
            selectedTickets,
            showTicketsConsumer,
            showTerminationPage,
            showTicketsExchange
        } = this.state;
        if (!showSelectedPan) return null;
        const total = selectedTickets.reduce((acc, t) => acc + t.value, 0);
        const totalThreshold = selectedTickets.reduce((acc, t) => acc + t.threshold, 0);
        const condition = selectedTickets[0].condition || '';

        console.log(selectedTickets)
        return (
            <div className="selected-pan">
                {
                    showTicketsConsumer && !showTicketsExchange
                    && Context.partner?.features?.showTicketExchangeBanner
                    && selectedTickets.length == 1
                    && !this.state.shortCode
                    && selectedTickets[0].campaignId !='63091565'
                    && <div onClick={() => this.setState({showTicketsExchange: true, shortCode: 'THEFORK'})}
                            className="coupon-exchange-wrapper">
                        <div className="left-container">
                            <img src="./assets/tcaAssets/08115822/Gift-Cards.png"/>
                            <div>
                                <p className="font-weight-bold">Aucun partenaire ne correspond à vos critères ?</p>
                                <p>Transformez vos chèques en carte cadeau TheFork utilisable dans plus de 5 000
                                    restaurants</p>
                            </div>
                        </div>
                        <div className="right-container">
                            <button>CHOISIR la carte cadeau thefork</button>
                            <img src="./assets/tcaAssets/08115821/logo-1.png"/>
                        </div>
                    </div>
                }
                {!showTerminationPage &&
                    <div className={`ticket-steps-wrapper ${showTicketsExchange && "bg-liteGreen"}`}>
                        <div className="step1-actions">
                            {
                                showTicketsExchange ? <div className="ticketsExchangeTextWrapper">
                                        <p>Vous allez échanger votre {getTicketDenomination()}</p>
                                        <p>pour une carte cadeau {"TheFork"}</p>
                                        <p>d’une valeur de {total}€</p>
                                    </div>
                                    :
                                    <div>
                                        <p>{clientT(`myTickets.action.${showTicketsConsumer ? 'willUse' : 'selected'}`)}</p>
                                        <p>{clientT('myTickets.action.amount', {
                                            count: selectedTickets.length,
                                            amount: total
                                        })}</p>
                                        <p>{!!totalThreshold && t('myTickets.action.threshold', {threshold: totalThreshold})}
                                            {' '}<span dangerouslySetInnerHTML={{__html: condition}}/></p>
                                        {this.state.partnerName &&
                                            <p dangerouslySetInnerHTML={{__html: clientT('myTickets.action.partner', {partnerName: this.state.partnerName})}}/>}
                                    </div>
                            }

                            <div className="step2-actions">
                                {showTicketsConsumer &&
                                    <AppButton
                                        className={`secButton ${showTicketsExchange && "stepBackBtn"}`}
                                        client
                                        event="Précédent"
                                        onClick={() => {
                                            showTicketsExchange ? this.setState({showTicketsExchange: false}) : this.setState({showTicketsConsumer: false})
                                        }}>{t('common.previous')}</AppButton>
                                }
                                <AppButton
                                    className={`mainButton ${showTicketsExchange && "text-liteGreen confirmExchangeBtn"}`}
                                    event="Suivant"
                                    client
                                    onClick={() => {
                                        showTicketsExchange ? this.handleBurn(selectedTickets) : this.handleConfirmed(showTicketsConsumer)
                                    }}>{showTicketsExchange ? "Confirmer" : t('common.next')}</AppButton>
                            </div>
                        </div>
                        {!showTicketsExchange ? <div className="ticket-steps">
                            <span>{showTicketsConsumer ? '2' : '1'}/2</span>
                        </div> : ""}
                    </div>
                }
            </div>
        )
    }

    render() {
        const {
            showTicketsConsumer, tickets, displayList,
            selectedTickets, confirmPopup, confirmed, notConfirmedPopup,
            sellerName, activationError, activationLoader, showTicketsExchange, showTerminationPage,
            exchangeData, shortCode,
        } = this.state;

        if (confirmed) {
            return <ConfirmedTicket
                selectedTickets={selectedTickets}
                sellerShortCode={shortCode}
                sellerName={sellerName}
                reset={this.reset}
            />
        }
        const refundPrizes = encodeURIComponent(selectedTickets.map(p => p.prize).join('|'));

        return (
            <>
                {!showTicketsConsumer && !showTicketsExchange && <MyTickets
                        tickets={tickets}
                        handleSelection={this.handleSelection}
                        displayList={displayList}
                        selectedTickets={selectedTickets}
                        shortcode={this.state.shortCode}
                        activationError={activationError}
                        activationLoader={activationLoader}
                        handleActivateCode={this.handleActivateCode}
                />}
                {showTicketsConsumer && !showTicketsExchange && <TicketConsumer
                    dispPopup={this.popUp}
                    selectedTickets={selectedTickets}
                    shortcode={this.state.shortCode}
                    infoPopup={this.toggleInfoPopup}
                />}
                {showTicketsExchange && <TicketExchange
                    showTerminationPage={showTerminationPage}
                    exchangeData={exchangeData}
                    selectedTickets={selectedTickets}
                />}
                {confirmPopup &&
                    <TicketsInfoPopup
                        rich
                        title={`<p>${clientT('myTickets.step2.confirmation')}</p>`}
                        bottomButtons={[
                            {label: t('common.yes'), action: () => this.handleBurn(selectedTickets)},
                            {
                                label: t('common.no'),
                                action: () => this.setState({confirmPopup: false, notConfirmedPopup: true}),
                                class: 'secButton'
                            },
                        ]}
                        addendum={Context.partner.features.allowUserRefund && <div className="refundBlock">
                            <AppLink href={`/refund/ask/${shortCode}/${refundPrizes}?noBurn=true`}
                                     dangerouslySetInnerHTML={{__html: clientT('refund.goto')}}
                            /></div>}
                    />
                }
                {notConfirmedPopup &&
                    <TicketsInfoPopup
                        rich
                        title={`<p>${clientT('myTickets.step2.notConfirmed')}</p>`}
                        bottomButtons={[
                            {
                                label: t('common.close'),
                                action: () => this.setState({notConfirmedPopup: false}),
                                class: 'secButton'
                            },
                        ]}
                    />
                }
                {this.renderSelectPan()}
                {this.state.typePopup &&
                    <TicketsInfoPopup
                        type={this.state.typePopup}
                        title={this.state.title}
                        lines={this.state.lines}
                        close={() => this.setState({typePopup: ''})}
                    />}
                {this.state.infoPopup &&
                    <TicketsInfoPopup
                        bottomButtons={[
                            {label: t('common.understood'), action: () => this.setState({infoPopup: false})},
                            Context.partner?.infoBurnPopup?.linkButton ? {
                                label: `Chercher un ${getPartnerDenomination()}`, action: () => {
                                    if (!selectedTickets.length) return;
                                    window.location.href = (Context.partner.client?.sellerListRedirection || '')
                                        .replace('{{coupon}}', selectedTickets[0].prize);
                                }
                            } : {},
                        ]}
                        type="custom"
                        title={Context.partner?.infoBurnPopup?.content}
                        richContent
                    />
                }
            </>
        );
    }
}

export default TicketsPage;

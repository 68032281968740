import { Context} from "../../tools/context";
import React from 'react'
import AppLink from "../AppLink";

 const GenericFooter = () => {
    if (Context.partner.footerImages && Context.partner.footerImages.length > 0){
        return <div className='homePage_footer'>
            {
                Context.partner.footerImages.map(((item,i) => {
                    return <img key={i} style={Context.partner.footerProps && Context.partner.footerProps} src={item.src} alt={item.alt || ''} />
                }))
            }

            <div style={{color:"black",display:"block"}}>
            <div><AppLink href={Context.partner.contactURL||'https://bienvenus.zendesk.com/hc/fr/requests/new'} target='_blank'>Contact</AppLink></div>
            <div><AppLink href={Context.partner.privacyURL||'https://ticket-commercant.fr/politique-de-confidentialite'} target='_blank'>Politique de confidentialité</AppLink></div>
            <div><AppLink href={Context.partner.CGUURL||'https://ticket-commercant.fr/cgu/?partner=lavalagglo'} target='_blank'>CGU</AppLink></div>

            </div>
        </div>
    }
    else return null;
}

export default GenericFooter;




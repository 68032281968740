export const envConfig = {
  prod:{
    endpoint:"https://prod.cibler.io/"
  },
  dev:{
    endpoint:"https://qa.cibler.io/"
  },
  fork:{
    endpoint:"https://qa.cibler.io/"
    // endpoint:"https://fork.cibler.io/"
  },
  local:{
    endpoint:"http://localhost:8080/"
  }
}
export const offersData = [
  {
    key: 1,
    text: "Une réduction",
    value: "reduction",
  },
  {
    key: 2,
    text: "Un service / produit",
    value: "service",
  },
];

export const discountData = [
  {
    key: 1,
    text: "Euro",
    value: "value",
    image: {
      avatar: true,
      src:
        "/assets/tcaAssets/31150920/Coin.png",
    },
  },
  {
    key: 2,
    text: "Pour cent",
    value: "percent",
    image: {
      avatar: true,
      src:
        "/assets/tcaAssets/28092644/Pourcent.png",
    },
  },
];

export const thresholdData = [
  {
    key: 1,
    text: "Une valeur d'achat",
    value: "Basket",
  },

  {
    key: 3,
    text: "Un nombre de commandes",
    value: "Orders",
  },
];

const categories = [
  "agriculture",
  "ateliers",
  "auto_moto",
  "autre",
  "bar",
  "bebe_puericulture",
  "bijoux",
  "boucherie_charcuterie",
  "boulangerie_patisserie",
  "bricolage",
  "cafe",
  "camping",
  "caviste",
  "chambre_dhotes",
  "chaussure_sac",
  "cinema",
  "club_associations",
  "coiffure",
  "decoration",
  "epicerie",
  "evenementiel",
  "fleuriste",
  "fromagerie",
  "gite",
  "high_tech",
  "hotel",
  "institut",
  "jeux_jouets",
  "librairie_presse",
  "maquillage",
  "mobilier",
  "musee",
  "ostreiculteur",
  "opticien",
  "beaute_parfum",
  "pharmacie",
  "photographie",
  "restaurant",
  "sac_accessoire",
  "spa",
  "sport",
  "traiteur",
  "vetement_accessoire",
  "vignoble",
  "voyage"
];

const categoryList = {
  agriculture: "Agriculture",
  ateliers: "Ateliers",
  auto_moto: "Auto Moto",
  autre: "Autres",
  bar: "Bar",
  beaute_parfum: "Parfum",
  bebe_puericulture: "Bébé",
  bijoux: "Bijoux",
  boucherie_charcuterie: "Boucherie Charcuterie",
  boulangerie_patisserie: "Boulangerie Pâtisserie",
  bricolage: "Bricolage",
  cafe: "Café",
  camping: "Camping",
  carte_cadeau: "Carte cadeau",
  caviste: "Caviste", //Cave
  chambre_dhotes: "Chambre d'hôtes",
  chaussure_sac: "Chaussures et Sacs",
  chocolat_cafe_the: "Chocolat café et Thé",
  cinema: "Cinema",
  club_association_sportive: "Club et associations sportives",
  club_associations: "Club associations",
  coiffure: "Coiffeur et Barbier",
  cuisine: "Cuisine & maison",
  decoration: "Décoration",
  epicerie: "Épicerie",
  evenementiel: "Événementiel",
  faitmain_creation: "Fait-main et Créations",
  festival: "Festival",
  fleuriste: "Fleuristes",
  fromagerie: "Fromagerie",
  gite: "Gite",
  high_tech: "High-tech",
  hotel: "Hôtels",
  hygiene_sante: "Hygiène et Santé",
  institut: "Instituts",
  jeux_jouets: "Jeux et Jouets",
  librairie_presse: "Librairie et Presse",
  loisir_nature: "Loisirs nature",
  maquillage: "Maquillage",
  mobilier: "Mobilier",
  musee: "Monuments et musées",
  oenotourisme: "Oenotourisme",
  ostreiculteur: "Ostreiculteurs",
  opticien:"Opticien",
  pharmacie: "Pharmacie",
  photographie: "Photographie",
  restaurant: "Restaurants",
  sac_accessoire: "Sacs et Accessoires",
  salon_the: "Salon de thé",
  spa: "Spa",
  sport: "Sport",
  sport_loisir: "Sports et Loisirs",
  supermarche: "Supermarché",
  theatre: "Théâtre",
  thermalisme: "Thermalisme",
  torrefacteur: "Torréfacteur",
  traiteur: "Traiteur",
  vetement_accessoire: "Vêtements et accessoires",
  vignoble: "Vignobles",
  voyage: "Voyages",
  //
  loisir_activite_nature: "Loisirs et activités nature (ex: fermes découvertes, guide accompagnateur nature, équitation, golf, vélo…)",
  loisir_activite_nautique: "Loisirs et activités nautiques (ex: bateliers, location de kayaks, paddles…)",
  parc_theme: "Parcs à thèmes (ex: accrobranche, escape game…)",
  site_monument: "Sites culturels et monuments (ex: château, site antique…)",
  salle_de_spectacle: "Salle de spectacle",
  visite: "Visites guidées et découvertes (ex: visites guidées des Offices de Tourisme, petits trains, bus touristiques…)",
  //
  commerce_alimentaire: "Commerce Alimentaire",
  hebergement: "Hébergement",
  mode: "Mode",
  activite_loisir: "Activités & Loisirs",
  maison_jardin: "Maison & Jardin",
  beaute_sante_bien_etre: "Beauté, Santé & Bien Etre",
  artisan_producteur: "Producteurs & Artisans",
  restaurant_bar: "Restaurants & Bars",
  //
  theme_park: "Parc à thèmes",
  tourist_walk: "Promenade touristique",
  site_and_monument: "Site et monument",
  //
  association_local_products : "Associations proposant des produits du terroir",
  local_artisans: "Artisans locaux",
  local_producers: "Producteurs locaux"
};

const metaCategories = (cat) => {
  switch(cat) {
    case 'epicerie':
    case 'caviste':
    case 'supermarche':
    case 'boucherie_charcuterie':
    case 'boulangerie_patisserie':
    case 'fromagerie':
      return "Commerce Alimentaire";

    case 'hotel':
    case 'gite':
    case 'camping':
    case 'chambre_dhotes':
      return "Hébergement";

    case 'vetement_accessoire':
    case 'bijoux':
    case 'chaussure_sac':
    case 'sac_accessoire':
      return "Mode";

    case 'sport':
    case 'sport_loisir':
    case 'jeux_jouets':
    case 'high_tech':
    case 'cinema':
    case 'theatre':
    case 'librairie_presse':
    case 'club_association_sportive':
    case 'club_associations':
    case 'ateliers':
    case 'evenementiel':
    case 'photographie':
    case 'voyage':
    case 'loisir_nature':
    case 'oenotourisme':
    case 'festival':
    case 'auto_moto':
      return "Activités & Loisirs";

    case 'cuisine':
    case 'decoration':
    case 'mobilier':
    case 'bricolage':
      return "Maison & Jardin";

    case 'spa':
    case 'thermalisme':
    case 'institut':
    case 'maquillage':
    case 'coiffure':
    case 'beaute_parfum':
    case 'bebe_puericulture':
    case 'hygiene_sante':
    case 'pharmacie':
      return "Beauté, Santé & Bien Etre";

    case 'agriculture':
    case 'fleuriste':
    case 'faitmain_creation':
    case 'vignoble':
    case 'ostreiculteur':
      return "Producteurs & Artisans";

    case 'restaurant':
    case 'chocolat_cafe_the':
    case 'salon_the':
    case 'cafe':
    case 'bar':
    case 'traiteur':
    case 'torrefacteur':
      return "Restaurants & Bars";

    default:
      return 'Autres';
  }
};
const metaCategoryColors = {
  'Commerce Alimentaire': '#1a5dc1',
  'Hébergement': '#c15d1a',
  'Mode': '#c11a84',
  'Activités & Loisirs': '#dbd53a',
  'Maison & Jardin': '#e44747',
  'Beauté, Santé & Bien Etre': '#f6aada',
  'Producteurs & Artisans': '#94c11a',
  'Restaurants & Bars': '#0ed7e9',
  'Autres': '#38ec91',
}
const technologies =[
  {value: "Shopify", label: "Shopify"},
  {value: "Prestashop", label: "Prestashop"},
  {value: "Woocommerce", label: "Woocommerce"},
  {value: "Magento", label: "Magento"},
  {value: "Autres", label: "Autres"},
]
const departments = [
  {value: "01", label: "01 - Ain"},
  {value: "02", label: "02 - Aisne"},
  {value: "03", label: "03 - Allier"},
  {value: "04", label: "04 - Alpes-de-Haute-Provence"},
  {value: "05", label: "05 - Hautes-Alpes"},
  {value: "06", label: "06 - Alpes-Maritimes"},
  {value: "07", label: "07 - Ardèche"},
  {value: "08", label: "08 - Ardennes"},
  {value: "09", label: "09 - Ariège"},
  {value: "10", label: "10 - Aube"},
  {value: "11", label: "11 - Aude"},
  {value: "12", label: "12 - Aveyron"},
  {value: "13", label: "13 - Bouches-du-Rhône"},
  {value: "14", label: "14 - Calvados"},
  {value: "15", label: "15 - Cantal"},
  {value: "16", label: "16 - Charente"},
  {value: "17", label: "17 - Charente-Maritime"},
  {value: "18", label: "18 - Cher"},
  {value: "19", label: "19 - Corrèze"},
  {value: "2A", label: "2A - Corse-du-Sud"},
  {value: "2B", label: "2B - Haute-Corse"},
  {value: "21", label: "21 - Côte-d'Or"},
  {value: "22", label: "22 - Côtes d'Armor"},
  {value: "23", label: "23 - Creuse"},
  {value: "24", label: "24 - Dordogne"},
  {value: "25", label: "25 - Doubs"},
  {value: "26", label: "26 - Drôme"},
  {value: "27", label: "27 - Eure"},
  {value: "28", label: "28 - Eure-et-Loir"},
  {value: "29", label: "29 - Finistère"},
  {value: "30", label: "30 - Gard"},
  {value: "31", label: "31 - Haute-Garonne"},
  {value: "32", label: "32 - Gers"},
  {value: "33", label: "33 - Gironde"},
  {value: "34", label: "34 - Hérault"},
  {value: "35", label: "35 - Ille-et-Vilaine"},
  {value: "36", label: "36 - Indre"},
  {value: "37", label: "37 - Indre-et-Loire"},
  {value: "38", label: "38 - Isère"},
  {value: "39", label: "39 - Jura"},
  {value: "40", label: "40 - Landes"},
  {value: "41", label: "41 - Loir-et-Cher"},
  {value: "42", label: "42 - Loire"},
  {value: "43", label: "43 - Haute-Loire"},
  {value: "44", label: "44 - Loire-Atlantique"},
  {value: "45", label: "45 - Loiret"},
  {value: "46", label: "46 - Lot"},
  {value: "47", label: "47 - Lot-et-Garonne"},
  {value: "48", label: "48 - Lozère"},
  {value: "49", label: "49 - Maine-et-Loire"},
  {value: "50", label: "50 - Manche"},
  {value: "51", label: "51 - Marne"},
  {value: "52", label: "52 - Haute-Marne"},
  {value: "53", label: "53 - Mayenne"},
  {value: "54", label: "54 - Meurthe-et-Moselle"},
  {value: "55", label: "55 - Meuse"},
  {value: "56", label: "56 - Morbihan"},
  {value: "57", label: "57 - Moselle"},
  {value: "58", label: "58 - Nièvre"},
  {value: "59", label: "59 - Nord"},
  {value: "60", label: "60 - Oise"},
  {value: "61", label: "61 - Orne"},
  {value: "62", label: "62 - Pas-de-Calais"},
  {value: "63", label: "63 - Puy-de-Dôme"},
  {value: "64", label: "64 - Pyrénées-Atlantiques"},
  {value: "65", label: "65 - Hautes-Pyrénées"},
  {value: "66", label: "66 - Pyrénées-Orientales"},
  {value: "67", label: "67 - Bas-Rhin"},
  {value: "68", label: "68 - Haut-Rhin"},
  {value: "69", label: "69 - Rhône"},
  {value: "70", label: "70 - Haute-Saône"},
  {value: "71", label: "71 - Saône-et-Loire"},
  {value: "72", label: "72 - Sarthe"},
  {value: "73", label: "73 - Savoie"},
  {value: "74", label: "74 - Haute-Savoie"},
  {value: "75", label: "75 - Paris"},
  {value: "76", label: "76 - Seine-Maritime"},
  {value: "77", label: "77 - Seine-et-Marne"},
  {value: "78", label: "78 - Yvelines"},
  {value: "79", label: "79 - Deux-Sèvres"},
  {value: "80", label: "80 - Somme"},
  {value: "81", label: "81 - Tarn"},
  {value: "82", label: "82 - Tarn-et-Garonne"},
  {value: "83", label: "83 - Var"},
  {value: "84", label: "84 - Vaucluse"},
  {value: "85", label: "85 - Vendée"},
  {value: "86", label: "86 - Vienne"},
  {value: "87", label: "87 - Haute-Vienne"},
  {value: "88", label: "88 - Vosges"},
  {value: "89", label: "89 - Yonne"},
  {value: "90", label: "90 - Territoire de Belfort"},
  {value: "91", label: "91 - Essonne"},
  {value: "92", label: "92 - Hauts-de-Seine"},
  {value: "93", label: "93 - Seine-St-Denis"},
  {value: "94", label: "94 - Val-de-Marne"},
  {value: "95", label: "95 - Val-D'Oise"},
  {value: "971", label: "971 - Guadeloupe"},
  {value: "972", label: "972 - Martinique"},
  {value: "973", label: "973 - Guyane"},
  {value: "974", label: "974 - La Réunion"},
  {value: "976", label: "976 - Mayotte"},
];

export {categories, categoryList, departments, metaCategories, metaCategoryColors,technologies};
export const magasinDialpadButtons = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
];

export const magasinValidateScreenButtons = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "Valider",
];

export const magasinTransactionScreenButtons = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ".",
  "0",
  "Valider",
];

export const popupTexts = {
  noTicket: {
    title: 'Retrouvez vos Tickets-Commerçants le mois prochain.',
    lines: [
      'L\'opération est victime de son succès, revenez le mois prochain pour récupérer vos tickets.',
      'Nous ne manquerons pas de vous prévenir lorsque les tickets seront disponibles !',
      'À très vite !',
      'L’équipe TICKET COMMERÇANT'
    ]
  },
  allUsed: {
    title: 'Merci pour votre soutien !',
    lines: [
      'Vous avez utilisé tous vos Tickets Commerçants Pour cette période, et vos commerçants vous remercient !',
      'Nous ne manquerons pas de vous prévenir dès que de nouveaux tickets seront disponibles au téléchargement.',
      'A très bientôt.',
      'L’équipe TICKET COMMERÇANT'
    ]
  },
  operationEnd: {
    title: 'L’opération TICKET COMMERÇANT se termine.',
    lines: [
      'Grâce à la forte mobilisation de chacun, TICKET COMMERÇANT s’est révélé être une aide efficace pour dynamiser le petit commerce tout en favorisant le pouvoir d’achat. Nous souhaitons que TICKET COMMERÇANT vous ait donné envie de continuer à consommer local.',
      'TICKET COMMERÇANT pourrait revenir bientôt et nous ne manquerons pas de vous prévenir.',
      'À très vite !',
      'L’équipe TICKET COMMERÇANT'
    ]
  },
  notEligible: "Vous n'êtes pas éligible a cette opération Ticket Commerçant",
  noCampaign: {
    title: 'Retrouvez vos Tickets-Commerçants bientôt.',
    lines: [
      'Nous ne manquerons pas de vous prévenir dès que de nouveaux tickets seront disponibles au téléchargement.',
      'A très bientôt.',
      'L’équipe TICKET COMMERÇANT'
    ],
  },
  sellerNotEligible:  'Le professionnel n\'est pas élligible',
  invalidSeller:  'Vendeur Introuvable',
  noCoupons: 'Aucun {{ticket}} selectionné',
  maxBatchSizeExceeded: 'Trop de {{ticket}}s selectionné',
  oneOrMoreInvalidCoupon: 'Un ou plusieurs {{ticket}}s sont invalides',
  wrongPrizeOwner: '{{ticket}} introuvable',
  prizeExpired: '{{ticket}} expiré',
  unknownError: 'Erreur lors de la consommation du {{ticket}} veuillez réessayer ultérieurement',
  loginError: 'Une erreur est survenue lors de la connexion',
  reverseLogin: {
    lines: [
      'Envoyez le code ci-dessous par SMS au 06 44 63 03 48',
      '{{code}}',
      'Patientez quelques secondes vous serez automatiquement dirigé sur votre espace client',
      // 'Si rien ne se passe contactez le service client <a href="contact@ticket-commercant.fr">contact@ticket-commercant.fr</a>',
    ]
  },
  notYetEligible: {},
  severalCampaigns: {}
}

export const popupTypes = {
  noTicket: 'noTicket',
  allUsed: 'allUsed',
  operationEnd: 'operationEnd',
  notEligible: 'notEligible',
  noCampaign: 'noCampaign',
  SellerNotEligible: 'sellerNotEligible',
  InvalidSeller: 'invalidSeller',
  NoCoupons: 'noCoupons',
  MaxBatchSizeExceeded: 'maxBatchSizeExceeded',
  OneOrMoreInvalidCoupon: 'oneOrMoreInvalidCoupon',
  WrongPrizeOwner: 'wrongPrizeOwner',
  PrizeExpired: 'prizeExpired',
  unknownError: 'unknownError',
  reverseLogin: 'reverseLogin',
  loginError: 'loginError',
  notYetEligible: 'notYetEligible',
  severalCampaigns: 'severalCampaigns',
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { siteContext, saveContext } from '../tools/toolbox';
import { Redirect } from 'react-router-dom';
import { authCall } from '../tools/apitools';
import '../style/customerSignin.scss';
import { Context } from '../tools/context';
import AppButton from '../components/AppButton';

class CustomerSignin extends Component {
  state = {
    customerPhoneNumber: '',
    errorMessage: '',
  };

  handleChange = (e) => {
    this.setState({
      customerPhoneNumber: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { customerPhoneNumber } = this.state;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${siteContext().accessToken}`);
    const reqParams = { headers: myHeaders };
    authCall(
      `api/users/stccodelistpage?customerId=163&email=${customerPhoneNumber}@ciblerphonemail.com`,
      reqParams,
      async (result) => {
        if (Context.partner.sponsor === 'myprovence' || Context.partner.sponsor === 'tca') {
          let sliceStart = result.indexOf('?') + 1;
          let sliceEnd = result.indexOf('&');
          const redirectionParams = result.slice(sliceStart, sliceEnd);
          const hostname = Context.partner.sponsor === 'tca'
            ? 'https://tourismebyca.fr/mes-avantages/'
            : window.location.hostname;
          window.open(`${hostname}/?${redirectionParams}`);
        } else {
          window.open(`${result}&partner=${Context.partner.sponsor}`);
        }
      },
      () => {
        console.log('reject');
        this.setState({
          errorMessage: 'Erreur',
        });
      },
      true
    );
  };
  render() {
    const isRoleAdmin = siteContext().role.ROLE_ADMIN;
    console.log('Etes vous admin?', isRoleAdmin);
    return (
      <>
        <div
          style={{
            color: 'red',
            textAlign: 'center',
            fontSize: '3em',
            marginTop: '30px',
          }}
        >
          {this.state.errorMessage}
        </div>
        {!isRoleAdmin ? (
          <Redirect to='/' />
        ) : (
          <div className='signinWrapper'>
            <form onSubmit={this.handleSubmit}>
              <label>
                SAISIR LE N° DE TEL CLIENT SUIVANT LE FORMAT INTERNATIONAL
                33XXXXXXXXX sans le "+"
                <br />
                ex: 33612345678
              </label>
              <input
                required
                type='text'
                minlength='11'
                maxLength='12'
                value={this.state.customerPhoneNumber}
                onChange={this.handleChange}
              />
              <AppButton type="submit" event="Connexion" client>Connexion</AppButton>
            </form>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(CustomerSignin);

import React, { Component } from 'react'
import {Context} from '../tools/context'
import MagasinStoreHeader from '../components/MagasinStoreHeader'
import AppLink from '../components/AppLink'
import AppButton from '../components/AppButton'
import { clientT } from '../tools/i18n'

export default class MagasinInit extends Component {

    render() {
      const {setIsSeller} = this.props
        return (
            <div className="page magasin-init" id="magasinInit">
            {
            <div className="page-content">
              <section className="hero">
                <div className="content-wrap">
                  <header className="page-header">
                    <AppLink className="btn btn-link" href="/dashboard-new">
                      <span className="icon icon-close icon-white" />
                    </AppLink>
                  </header>
                  <MagasinStoreHeader storeName={Context.seller.name} sellerId={Context.seller.id}/>
                  <div className="magasin-init-text-div">
                    <p className="magasin-init-text">{clientT('magasinInit.magasinInitText')}</p>
                  </div>
                  <div className="magasin-init-btn-div">
                    <AppButton event="Les Clients" className="magasin-init-btn" name="storeOwner" onClick={() => setIsSeller(true)}>{clientT('magasinInit.customerButtonText')}</AppButton>
                  </div>
                  <div className="magasin-init-btn-div">
                   <AppButton event="Moi, commerçant" className="magasin-init-btn" onClick={() => setIsSeller(false)} data-page="1" name="client" >{clientT('magasinInit.traderButtonText')}</AppButton>
                  </div>
                </div>
              </section>
            </div>
             }
          </div>
        )
    }
}

import Context, { ContextUtil } from "./context";
import {metaCategories, metaCategoryColors} from "../Utils/Constants";
import {loadNamespace, t} from "./i18n";



const partnerDomains = {
  "myprovence.fr": "passprovence",
  "passmyprovence.fr": "passprovence",
  "tourismebyca.fr": "tca",
  "bienvenus.fr": 'bvn',
  "lavalagglo.bienvenus.com":"lavalagglo",
  "loreedelabrie.bienvenus.com":"loreedelabrie",
  "tickets-intermarche.fr": "intermarche",
};



const siteContext = () => window.siteContext || {};

const sellerLoggedIn = ()=>{
  return !!window.siteContext.accessToken
}

const userLoggedIn = ()=> {
  return !!window.siteContext.clientToken
}

const getPartnerKey = () => {
  let partner = getCookie("partner");

  const partnerFromUrl = [
    '.bienvenus.com',
    '.ticket-pei.fr',
    '.ticket-commercant.fr',
    '.reducpeyi.fr',
  ]
  let bvnDomain = partnerFromUrl.some(url => window.location.hostname.endsWith(url)) ? window.location.hostname.split('.')[0] : null;
  bvnDomain = bvnDomain !== 'dev' && bvnDomain !== 'fork' ? bvnDomain : null;

  partner =
    getQueryString("part") ||
    getQueryString("partner") ||
    getQueryString("sponsor") ||
    bvnDomain || partner|| 'bvn';

  if (partner === 'prod') partner='bvn';

  const domPartKey = Object.keys(partnerDomains).find((k) =>
    window.location.hostname.endsWith(k)
  );
  partner = domPartKey ? partnerDomains[domPartKey] : partner;

  return partner;
}


const fetchPartner = async (test) => {
  const partner = getPartnerKey();


  if (!partner) return false;
  const result = await fetch(`/configuration/${partner.toLowerCase()}.json?cachebust=${Math.random()*100}`).then((r) => {
    if (r.ok && r.status === 200) {
      return r.json();
    }
  });

  if (!result) return false;
  let root = document.documentElement;
  if (!result.sponsor) result.sponsor = partner;
  loadNamespace(''+result.customerId)
  if(result.browserTabText){
    document.title = result.browserTabText;
  }
  if (result.textColor1) {
    root.style.setProperty("--partnerTxtColor1", result.textColor1);
  }
  if(result.textColor2 || result.textColor1){
    root.style.setProperty(
      "--partnerTxtColor2",
      result.textColor2 || result.textColor1
    );
  }
  if(result.textColor3 || result.textColor1){
    root.style.setProperty(
      "--partnerTxtColor3",
      result.textColor3 || result.textColor1
    );
  }
  if(result.textColor4 || result.textColor1){
    root.style.setProperty(
      "--partnerTxtColor4",
      result.textColor4 || result.textColor1
    );
  }
  if(result.textColor5 || result.textColor1){
    root.style.setProperty(
      "--partnerTxtColor5",
      result.textColor5 || result.textColor1
    );
  }
  if(result.textColor7 || result.textColor2){
    root.style.setProperty("--partnerTxtColor7", result.textColor7 || result.textColor2);
  }
  if(result.textColor8){
    root.style.setProperty("--partnerTxtColor8", result.textColor8);
  }
  if(result.textColor9){
    root.style.setProperty("--partnerTxtColor9", result.textColor9);
  }
  if(result.linkColor){
    root.style.setProperty("--link-blue", result.linkColor);
  }
  if( result.textColor1){
    root.style.setProperty("--secondaryColor", result.textColor1);
  }
  if(result.textColor1){
    root.style.setProperty("--magasinLoadingBackground", result.textColor1);
  }
  if(result.borderColor1){
    root.style.setProperty("--partnerBorderColor1", result.borderColor1);
  }
  if(result.buttonColor1){
    root.style.setProperty("--partnerBtnColor1", result.buttonColor1);
    root.style.setProperty("--partnerBtnColor4", result.buttonColor4 || result.buttonColor1);
    root.style.setProperty("--partnerBtnColor5", result.buttonColor5 || result.buttonColor1);
  }
  if(result.buttonTxtColor1){
  root.style.setProperty("--partnerBtnTxtColor1", result.buttonTxtColor1);
  root.style.setProperty("--partnerBtnTxtColor4", result.buttonTxtColor4 || result.buttonTxtColor1);
  root.style.setProperty("--partnerBtnTxtColor5", result.buttonTxtColor5 || result.buttonTxtColor1);
  }
  if(result.buttonColor2 || result.buttonColor1){
    root.style.setProperty(
      "--partnerBtnColor2",
      result.buttonColor2 || result.buttonColor1
    );
  }
  if(result.buttonTxtColor2 || result.buttonTxtColor1){
    root.style.setProperty(
      "--partnerBtnTxtColor2",
      result.buttonTxtColor2 || result.buttonTxtColor1
    );
  }
  if(result.buttonColor3 || result.buttonColor2 || result.buttonColor1){
    root.style.setProperty(
      "--partnerBtnColor3",
      result.buttonColor3 || result.buttonColor2 || result.buttonColor1
    );
  }
  if(result.buttonTxtColor3 || result.buttonTxtColor2 || result.buttonTxtColor1){
    root.style.setProperty(
      "--partnerBtnTxtColor3",
      result.buttonTxtColor3 || result.buttonTxtColor2 || result.buttonTxtColor1
    );
  }
  if( result.buttonBorderWidth){
    root.style.setProperty(
      "--partnerBtnBorderWidth",
      result.buttonBorderWidth || "2px"
    );
  }
  if (result.backgroundColor) {
    root.style.setProperty("--partnerBgColor", result.backgroundColor);
    root.style.setProperty("--partnerBgColor2", result.backgroundColor2 || result.backgroundColor);
  }
  if (result.fontFamily) {
    root.style.setProperty("--partnerFontFamily", result.fontFamily);
  }
  if (result.titleFontFamily) {
    root.style.setProperty("--partnerTitleFontFamily", result.titleFontFamily);
  }
  if (result.buttonColorRed)
     root.style.setProperty("--partnerBtnRed", result.buttonColorRed);
  if (result.dashboardNew) {
    if (result.dashboardNew.boxShadow4)
      root.style.setProperty("--boxShadow4", result.dashboardNew.boxShadow4);
  }

  if(result?.client?.style?.color1){
    root.style.setProperty("--clientColor1", result?.client.style.color1);}
  if(result?.client?.style?.color2){
    root.style.setProperty("--clientColor2", result?.client.style.color2);}
  if(result?.client?.style?.color3){
    root.style.setProperty("--clientColor3", result?.client.style.color3);}
  if(result?.client?.style?.mainBtnColorBg){
    root.style.setProperty("--clientMainBtnColorBg", result?.client.style.mainBtnColorBg);}
  if(result?.client?.style?.mainBtnColorText){
    root.style.setProperty("--clientMainBtnColorText", result?.client.style.mainBtnColorText);}
  if(result?.client?.style?.secBtnColorBg){
    root.style.setProperty("--clientSecBtnColorBg", result?.client.style.secBtnColorBg);}
  if(result?.client?.style?.secBtnColorText){
    root.style.setProperty("--clientSecBtnColorText", result?.client.style.secBtnColorText);}
  if(result?.client?.style?.backgroundColor){
    root.style.setProperty("--clientBackgroundColor", result?.client.style.backgroundColor);}

  if (result.register && result.register.edito) {
    const edito = await fetch(`/edito/${partner}-register.html`).then((r) => {
      if (r.ok && r.status === 200) {
        return r.text();
      }
    });
    result.register.editoHtml = edito;
  }

  ContextUtil.update("partner", result);
  if (result.features) {
    ContextUtil.update("features", result.features);
  }
  setCookie("partner", partner);

  //Change text in Search Engine results
    //create new meta tag
  const metaToInsertInHead = document.createElement("meta")
    //set meta tag attributes
  metaToInsertInHead.setAttribute('name', 'description');
  metaToInsertInHead.setAttribute('content', result.searchEngineText);
  metaToInsertInHead.setAttribute('http-equiv', 'X-UA-Compatible');
  const htmlHead = document.querySelector("head");
    //append the new meta tag inside head 
  htmlHead.appendChild(metaToInsertInHead)

  //Customize browser tab icon based on partner
    //create new link tag 
    const linkTagToAdd = document.createElement("link")
    //set new link tag attributes
    linkTagToAdd.setAttribute("href",`/assets/${result.browserTabIcon}`)
    linkTagToAdd.setAttribute("rel","icon")
    linkTagToAdd.setAttribute("type","image/x-icon")
    linkTagToAdd.setAttribute("sizes","16x16")
    htmlHead.appendChild(linkTagToAdd)

  return partner;
};

const isStringJson = (str) => {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return !!result;
};

const saveContext = () => {
  localStorage.setItem("siteContext", JSON.stringify(siteContext()));
};
window.saveContext = saveContext;

const saveOAuthInfo = (inputs) => {
  localStorage.setItem("oauthInfos", JSON.stringify(inputs));
};

const urlEncodeParameters = (details) => {
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
};

const loadOAuthInfos = () => {
  const infos = localStorage.getItem("oauthInfos");
  if (!infos || !isStringJson(infos)) return false;

  return JSON.parse(infos);
};

const defaultConfig = {
  textColor: "#ed6d91",
  buttonColor: "#ffffff",
};

window.partnerConfig = defaultConfig;

const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  const temp = window.location.host.split('.').reverse();
  const root_domain = temp[1] ? ';domain=' + temp[1] + '.' + temp[0] : '';
  document.cookie = name + "=" + (value || "") + expires + "; path=/"+root_domain;
};

const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    ca.reverse();
    const res = ca.find(r => r.includes(nameEQ));
    return res ? res.split('=')[1] : null;
  }
  return null;
};

const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const isFirstTimeLogin = () => {
  return localStorage.getItem("isFirstTime") == null;
};

const markFirstTimeLoginDone = () => {
  localStorage.setItem("isFirstTime", "false");
};

function hasUtmValues() {
  return localStorage.getItem("clientUtmValues") != null;
}

const getUtmValues = () => {
  return hasUtmValues() ? JSON.parse(localStorage.getItem("clientUtmValues")) : {};
};

const resetUtmValues = () => {
  localStorage.removeItem("clientUtmValues");
};

const saveUtmValues = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let utmValues = {};
  for (let [key, value] of urlSearchParams.entries()) {
    if (key.toLowerCase().startsWith("utm")) {
      utmValues[key] = value;
    }
  }

  if (Object.keys(utmValues).length) {
    localStorage.setItem("clientUtmValues", JSON.stringify(utmValues));
  }
};

// function init() {
//     partner = getCookie("partner");
//
//     path = window.location.pathname;
//     const regex = "commerces-([a-z]*)";
//     match = path.match(regex);
//     if (match) {
//         partner = match[1];
//     }
//     if (!window.sellerData)
//         sellerData = {};
//
//     partner = sellerData.partner ||
//         getQueryString("part") ||
//         getQueryString('partner') ||
//         getQueryString('sponsor') ||
//         partner;
//
//     if (partner) {
//         let root = document.documentElement;
//         partnerConfig = partnerConfigMap[partner] || defaultConfig;
//         if (!partnerConfig.sponsor)
//             partnerConfig.sponsor = partner;
//         root.style.setProperty('--partnerTxtColor', partnerConfig.textColor);
//         root.style.setProperty('--partnerBtnColor', partnerConfig.buttonColor);
//         setCookie("partner", partner);
//     }
// }

const getQueryString = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const logout = () => {
  window.siteContext.seller = null;
  window.siteContext.accessToken = null;
  window.siteContext.refreshToken = null;
  window.siteContext.alts = null;
  window.siteContext.role = null
  window.siteContext.clientToken = null;
  saveContext()
  if (window.zE)
  Context.partner?.features?.hideOnExternalPages ?
  window.zE('webWidget', 'hide') : window.zE('webWidget', 'show')
};

// function ACtrackEvent(data, event, retry = 0) {
//     if (!window.php_data && retry < 3) {
//         setTimeout(() => ACtrackEvent(data, event, retry + 1), 500);
//         return;
//     }
//     var visit = '{"email":"' + php_data.user_email + '"}'
//     // build the eventString based on the variables you just edited above ☝
//     var eventString = "actid=" + '799887211' +
//         "&key=" + 'bb8c48398cb92757b92f597c4afce2306778e6d0' +
//         "&event=" + event +
//         "&visit=" + encodeURIComponent(visit) +
//         "&eventdata=" + data;
//     // send the event to the ActiveCampaign API with our event values
//     fetch('https://cibler.io/api/activecampaign', {
//         mode: 'no-cors',
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/x-www-form-urlencoded'
//         },
//         body: eventString
//     });
// }

const getTicketDenomination = (plural) =>
  Context.partner.client?.ticketDenomination
    ? Context.partner.client?.ticketDenomination[plural ? 1 : 0]
    : (plural ? t('common.tickets') : t('common.ticket'))

const getPartnerDenomination = (plural) =>
  Context.partner.client?.partnerDenomination
    ? Context.partner.client?.partnerDenomination[plural ? 1 : 0]
    : (plural ? t('common.partners') : t('common.partner'))

const customer = () => Context.partner.customerId || 163;

const handlePWA = () => {
  // Initialize deferredPrompt for use later to show browser install prompt.
  let deferredPrompt;
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });
}

const getMetaCategories = (cat) => {
  const meta = Context.partner?.metaCategories?.find(m => m.keys?.includes(cat));
  if (meta) return meta.label;

  return metaCategories(cat);
}

const getMetaCategoryColors = (cat) => {
  const meta = Context.partner?.metaCategories?.find(m => m.label === cat);
  if (meta) return meta.color;

  return metaCategoryColors[cat];
}

export {
  fetchPartner,
  saveContext,
  saveOAuthInfo,
  loadOAuthInfos,
  isStringJson,
  siteContext,
  urlEncodeParameters,
  getQueryString,
  logout,
  isFirstTimeLogin,
  markFirstTimeLoginDone,
  userLoggedIn,
  sellerLoggedIn,
  customer,
  saveUtmValues,
  hasUtmValues,
  resetUtmValues,
  getUtmValues,
  getTicketDenomination,
  getPartnerDenomination,
  handlePWA,
  getMetaCategories,
  getMetaCategoryColors,
  getCookie,
  setCookie,
  getPartnerKey,
};

import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import MessageIcon from 'mdi-react/MessageIcon';
import {Context, ContextUtil} from '../../tools/context';
import '../../style/clientLayout.scss';
import {
  userLoggedIn, logout,
  getTicketDenomination, siteContext,
  saveContext, setCookie
} from '../../tools/toolbox';
import Icon from "../Icon";
import AppLink from '../AppLink';
import AppButton from '../AppButton';
import {clientT, t} from '../../tools/i18n'
import Header from "../../pages/Landing/Header";
import LanguageSelector from "../LanguageSelector";

const ClientLayout = ({publicZone, children}) => {
  const [show, toggleMenu] = useState(false);
  const history = useHistory();
  const label = getTicketDenomination(true);

  let menuItems = [
    {
      Icon: "mes-cheques-icon",
      href: '/portefeuille',
    },
    {
      Icon: "mes-cheques-utilises-icon",
      href: '/historique',
    },
    {
      Icon: "mes-code-promos-icon",
      href: '/mes-code-promos',
    },
    {
      Icon: "mes-remboursements-icon",
      href: '/refund/list',
      hide: !Context.partner?.features.allowUserRefund,
    },
    {
      Icon: "mes-informations-icon",
      href: '/profile-client',
    },
    {
      Icon: "retour-accueil-icon",
      key: 'home',
      href: Context.partner?.clientNavigation?.homeUrl || '#',
      external: true,
    },
  ];
  if (window.zE && Context.partner?.features?.zendesk ){
      window.zE('webWidget', 'show')
      window.zE('webWidget', 'close')
  }
      if (Context.partner.clientNavigation) {
    menuItems = menuItems.concat(Context.partner.clientNavigation.add || []);
    (Context.partner.clientNavigation.remove || []).forEach(r => {
      const index = menuItems.findIndex(p => p.href === r);
      if (index >= 0) {
        menuItems.splice(index, 1);
      }
    })
  }

  if (!publicZone && !userLoggedIn()) {
    history.push('/');
  }

  const src = Context.partner.customLogo
    ? `/assets/${Context.partner.sponsor}_logo.png`
    : '/assets/logo.png';

  const handleDisconnect = () => {
    ContextUtil.update('clientToken', null);
    siteContext().clientToken = null;
    saveContext();
    setCookie('loginToken', '', -1);
    history.replace('/')
  }

  if (publicZone) {
    return <div className={`client-layout ${Context.partner.platform}`}>
      <Header />
      {children}
    </div>
  }

  return (
    <div className='client-layout'>
      <header className='client-header'>
        {!publicZone &&
        <AppButton className="menuButton" onClick={() => toggleMenu(!show)} event="Client menu button" client>
          <svg
            className="menuIcon"
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M4 8H28V10.6667H4V8ZM4 14.6667H28V17.3333H4V14.6667ZM4 21.3333H28V24H4V21.3333Z'
              // fill={Context?.partner?.textColor5 || Context?.partner?.textColor1}
            />
          </svg>
        </AppButton>
        }
        <div className='logo-wrapper'>
          <AppLink client href={Context.partner?.clientNavigation?.homeUrl || '/'}>
            <img src={src} alt='' title='logo'/>
          </AppLink>
        </div>
      </header>
      {/*<div style={{display: "flex"}}>*/}
      {!publicZone &&
      <div
        className='client-menu'
        style={show ? {transform: 'translateX(0)'} : {}}
      >
        <ul>
          {menuItems.filter(m => !m.hide).map((item, index) => {
            const content = (<>
              <Icon name={item.Icon}/>
              <span className='menulabel'>
                  {clientT(`menu.${item.key || item.href}`, {label: label})}
                </span>
            </>)
            const selected = document.location.pathname === item.href;

            return (
              <li key={index} className={selected ? "selected" : ''}>
                {item.external
                    ? <AppLink href={item.href} client>{content}</AppLink>
                    : <AppLink href={item.href} client>{content}</AppLink>}
              </li>
            );
          })}
        </ul>
        <div className='bottom-menu'>
          <ul>
            {Context.partner?.clientNavigation?.helpUrl &&
            <li>
              <AppLink anchor client href={Context.partner.clientNavigation.helpUrl.url || Context.partner.clientNavigation.helpUrl}
                 target='_blank'>
                <Icon name={'help-icon'}/>
                <span
                  className='menulabel'>{Context.partner.clientNavigation.helpUrl.label || t('menu.help')}</span>
              </AppLink>
            </li>
            }
            <li>
              <a onClick={handleDisconnect}>
                <Icon name={'disconnect'}/>
                <span className='menulabel'>{t('menu.logout')}</span>
              </a>
            </li>
          </ul>
        </div>
        <LanguageSelector />
      </div>
      }
      {publicZone ? children :
        <div className="screenAdapter" onClick={() => toggleMenu(false)}>
          {children}
        </div>
      }
      {/*</div>*/}
    </div>
  );
}
;

export default ClientLayout;
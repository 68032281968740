import * as React from "react";

function Icon(props) {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...props} className={`svgIcon ${props.className}`}>
      <mask id={props.name} x="0" y="0" width="100" height="100">
        <image xlinkHref={`/assets/icons/${props.name}.png`} x="0" y="0" width="100" height="100"/>
      </mask>
      <rect x="0" y="0" width="100" height="100" fill="currentColor" mask={`url(#${props.name})`}/>
    </svg>
  );
}

export default Icon;

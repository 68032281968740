import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import {siteContext} from "../tools/toolbox";
import '../style/howto.scss';

class HowTo extends Component {

  componentDidMount() {
    if (!this.props.loaded) return;
  }

  componentDidUpdate() {
    if (!this.props.loaded) return;
  }

  render() {
        return (
          <div className="dashboard">
            <main className="main" id="main">
              <div className="textDiv">
                <strong>1.</strong> <br/>Récupérez votre code partenaire sur votre espace et indiquez-le en caisse sur demande du titulaire du Pass My Provence afin qu'il puisse le saisir dans son espace client
                <br/><br/><strong>2.</strong> <br/>Effectuez la remise en caisse (une place offerte pour l’achat d’une place plein tarif) lorsqu’un client présente son justificatif directement depuis son smartphone
                <br/><br/><strong>3.</strong> <br/>Recevez une preuve de remise par EMAIL & SMS. Et consultez l’historique des transactions sur votre espace partenaire
                <br/><br/><br/>
                Pour toutes questions n’hésitez pas à nous contacter par email schahid@myprovence.fr ou par téléphone au 04 91 13 84 47.
              </div>
            </main>
          </div>
        );
    }
}

export default withRouter(HowTo);

import React from 'react';
import '../style/languageSelector.scss'
import i18n from '../tools/i18n'
import Context from '../tools/context'

const LanguageSelector = () => {
  const [open, setOpen] = React.useState(false);

  if (!Context.features.i18n) return null;
  const langs = [
    {label: 'Français', key: 'fr'},
    {label: 'English', key: 'en'},
  ]

  return <div className="languageSelector">
    <button className="toggle" onClick={() => setOpen(!open)}>
      {(i18n.language || '').split('-')[0]}
    </button>
    {
      open && <div className="list">
        {langs.map(l =>
          <button
            className="choice"
            key={l.key}
            onClick={() => {
              i18n.changeLanguage(l.key);
              // window.location.reload();
              setOpen(false);
            }}>
            {l.label}
          </button>
        )}
      </div>
    }
  </div>
}

export default LanguageSelector;
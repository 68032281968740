import React, {Component} from "react";
import "../style/MonSolde.scss";
import {authCall, getSeller} from "../tools/apitools";
import moment from "moment";
import AppButton from "../components/AppButton";

export default class Activation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activationKey: ""
    }
  }

  componentDidMount() {
    this.setState({
      activationKey: ""
    })
  }

  handleResponse = (json) => {
    this.setState({status: "OK", tickets: json});
  }

  renderResponse = () => {
    let sum = 0;
    let json = this.state.tickets;
    if (!json || !json.length)
      return null;
    let expiration = moment(json[0].expiration).format('L');
    json.forEach(t => sum += t.gift ? t.gift.value : 0);

    return <div className="successBlock">
      <div>
        <div className="title">Carte activée avec succès</div>
      <div> Valeur : <span>{sum}</span></div>
      <div> Nombre de ticket : <span>{json.length}</span></div>
      <div> Expiration : <span>{expiration}</span></div>
      </div>
    </div>
  }


  handleReject = (err, s) => {
    console.log(err)
    if (err) {
      try {
        let jsonbody = JSON.parse(err)
        switch (jsonbody.description) {
          case "AlreadyInitialized" :
            this.setState({error: "Ce code est déja activé"});
            break;
          default:
            this.setState({status: "ERREUR:" + s, error: jsonbody.description});
        }
      } catch (e) {
        this.setState({status: "KO:" + s, error: "err"});
      }
    } else {
      let error = null;
      switch (s) {
        case 404:
        case 403:
          error = 'ce code d\'activation n\'est pas reconnus.';
          break;
      }
      this.setState({status: "KO:" + s, error});
    }
  }

  saveData = (e) => {
    e.preventDefault();
    this.state.error = "";
    this.state.response = "";
    authCall(`api/prizes/activate/${this.state.activationKey+"-"+this.state.pwdid}`, {
      method: 'POST'
    }, (json) => this.handleResponse(json), (err, status) => this.handleReject(err, status))

    return false;
  }


  render() {
    const {activationKey,pwdid} = this.state;
    return (
      <div className="activationPage form-group" style={{marginTop: "60px"}}>
        <label htmlFor="phoneInput">
          Entrer le code d'activation
        </label>
        <form onSubmit={this.saveData} className="input-group icon-left">
          <div style={{display:"flex"}} >
            <input
                type="text"
                id="activationKey"
                className="form-control"
                placeholder="XXXX"
                value={activationKey}
                onChange={(e) => {
                  this.setState({activationKey: e.target.value})
                }}
                pattern={'^[a-zA-Z0-9]{4}$'}
                required
            />
            <div style={{marginTop:"18px", fontSize: "40px"}}>-</div>
            <input
                type="text"
                id="activationKey"
                className="form-control"
                placeholder="123456"
                value={pwdid}
                onChange={(e) => {
                  this.setState({pwdid: e.target.value})
                }}
                pattern={'^[0-9]*$'}
                required
            />
          </div>

          <AppButton type="submit" event="Activer la carte" id="activateCard" className="btn btn-primary">Activer la carte</AppButton>
          {/*<div>{JSON.stringify(this.state.status)}</div>*/}
          {this.renderResponse()}
          {this.state.error && <div className="errorBlock">
            {this.state.error}
          </div>}
          {/*<div>{JSON.stringify(this.state.error)}</div>*/}
        </form>
      </div>
    );
  }
}

import moment from "moment";
import React, {Component} from "react";
import {Link, useHistory, withRouter} from "react-router-dom";
import AppButton from "../components/AppButton";
import AppLink from "../components/AppLink";
import CommerceBoxPopup from "../components/Popup/CommerceBoxPopup";
import ErrorPopup2 from "../components/Popup/ErrorPopup2";
import PartnerPopup from "../components/Popup/PartnerPopup";
import UsedBudgetPopup from "../components/Popup/UsedBudgetPopup";
import "../style/dashboard.scss";
import {authCall} from '../tools/apitools';
import Context from "../tools/context";
import {clientT} from "../tools/i18n";
class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.myCard = React.createRef()
    this.state = {
      commerceBoxOpen: false,
      partnerPopupOpen: false,
      toggleTabs: false,
      offers: [],
      offerItems: [],
      offerMod: [],
      displayDeleter: false,
      errorMessage: '',
    };

    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.getGifts();
    this.getGiftsSponsor();
  }

  deleteOffer = offer => {
    //apicall w/ offer.id
    authCall(`api/sellers/gifts/${offer.id}`,
      {
        method: 'DELETE'
      },
      () => {
        const tmp = [...this.state.offers];
        const index = tmp.findIndex(o => o.id === offer.id);
        tmp.splice(index, 1);
        this.setState({offers: tmp, deleteOffer: null});
      },
      (r) => {
        this.setState({errorMessage: 'Une erreur est survenue.'})
      })
  }

  renderDeleteOffer = () => {
    console.log("DELETE OFFER",this.state.deleteOffer);
    if (!this.state.deleteOffer) return;

    const offer = this.state.deleteOffer
    return (
      <div id="popupCancel">
        <div id="popupContent">
          <div style={{height: "37%", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
            <div style={{marginTop: "10px", fontWeight: "normal"}}>{clientT('dashboard.deleteConfirmation')}</div>
            <div style={{marginTop: "15px"}}><strong> {offer.description} ?</strong></div>
            <div style={{marginTop: "20px"}}>
              <AppLink href="#" anchor className="btn btn-secondary btn-inline" style={{width: "48%", lineHeight: 1, height: "55px"}}
                 onClick={() => {
                   this.setState({deleteOffer: null})
                 }}> {clientT('common.cancel')}</AppLink>
              <AppLink href="#" anchor className="btn  btn-primary btn-inline"
                 style={{width: "48%", lineHeight: 1, height: "55px", marginLeft: "2.5%"}} onClick={() => {
                this.deleteOffer(offer)
              }}> {clientT('common.yes')}</AppLink>
            </div>
          </div>
        </div>
      </div>)
  }

  getGifts = async () => {
    await authCall(`api/sellers/gifts?shortCode=${Context.seller.shortcode}`,
      {method: 'GET'},
      result => {
        console.log('result', result);
        result = result || [];
        this.setState({offers: this.state.offers.concat(result)});
      }, () => undefined);
  }

  getGiftsSponsor = async () => {
    await authCall(`api/sellers/gifts/sponsor?shortCode=${Context.seller.shortcode}`,
      {method: 'GET'},
      result => {
        console.log('sponsor', result);
        result = result || [];
        this.setState({offers: result.concat(this.state.offers)});
      }, () => undefined);
  }

  onSwitchChange = async (index) => {
    const tmp = [...this.state.offers];
    tmp[index].paused = !tmp[index].paused;
    this.setState({offers: tmp})
    authCall(`api/sellers/gifts/togglepause/${tmp[index].id}`,
      {
        method: 'POST'
      },
      () => {
      },
      () => {
      })
  }

  redirectOffer = (link) => {
    this.props.history.push(link);
  }

  render() {
    return (
      <>
        {this.state.errorMessage && <ErrorPopup2
          style={{position: 'absolute'}}
          message={this.state.errorMessage}
          onClose={() => this.setState({errorMessage: ''})}
        />}
        <div className="dashboard">
          <main className="main" id="main">
            <div className="tabs">
              <div className="tab-buttons">
                <div
                  className={`btn-link btn-inline btn-tab alwaysActive ${!this.state.toggleTabs && "active"}`}>
                {clientT('dashboard.title')}
                </div>
              </div>
              <div className={`tab-content ${!this.state.toggleTabs && "active"}`} data-tab="avantage-en-cours">
                <div id="sponsoredOffers"/>
                <div id="offers">
                  {this.state.offers.map((offer, index) => {
                    const link = `/addproduct?product_id=${offer.id}`;
                    const inactive = offer.paused || (offer.sponsor && !offer.stock);
                    return (
                      <div className={`card card-offer ${inactive && 'inactive'} card-padding`}
                           data-id={offer.id}
                           onClick={() => {!offer.sponsor && this.redirectOffer(link)}}
                      >
                        <img style={{maxHeight:'30px'}} src="https://tourismebyca.s3.eu-west-3.amazonaws.com/wp-content/static/icons/iconupdate.png"></img>
                        <div className="content-wrap">
                          <p className="offer">
                            <strong>{offer.description}</strong>
                          </p>
                          {offer.sponsor &&
                          <p style={{color: '#0091ff'}}>
                            Offert par <span style={{fontWeight: 'bold'}}>{offer.customerName}</span>
                          </p>}
                        </div>
                        {!offer.sponsor ?
                        <div className="switch-wrap" onClick={(event) => {
                          event.stopPropagation()
                        }}>
                          <label className="switch">
                            <input type="checkbox" defaultChecked={!offer.paused} value={offer.id} data-switcher=""
                                   onChange={() => {
                                     this.onSwitchChange(index)
                                   }}/>
                            <span className="slider round"/>
                          </label>
                        </div>
                          :
                          <div className="switch-wrap">
                            <p style={{color: '#0091ff', fontSize: '0.8em', textTransform: 'uppercase'}}>
                              {offer.stock ? `${offer.stock} encore en stock` : 'STOCK ÉPUISÉ'}
                            </p>
                          </div>
                        }
                      </div>
                    );
                  })}
                </div>
                {/* <div className="loader-wrap" id="offersLoader">
                <div className="loader"></div>
            </div> */}
              </div>
              <div className={`tab-content ${this.state.toggleTabs && "active"}`} data-tab="avantages-modifier">
                <div id="modifiersdata">
                  {this.state.offers.map((offer) => {
                    const link = `/addproduct?product_id=${offer.id}`;
                    return (
                      <div className="card avantagesCard card-padding inactive" data-id={offer.id}>
                      <span className="icon icon-close cross-icon" onClick={() => {
                        this.setState({deleteOffer: offer})
                      }}/>
                        <div>
                          <p className="date-until">{offer.expirationDate &&
                            `Jusqu’au ${moment(offer.expirationDate).format('L')}`} </p>
                          <p className="offer"><strong>{offer.description.trimEnd()}</strong></p>
                        </div>
                        <div className="switch-wrap" style={{textAlign: 'right'}}>
                          <AppLink href={link}>
                            <i className="fa fa-chevron-right"></i></AppLink>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
            <div className="create-btn">
              <AppLink href="addproduct">
                <AppButton className="btn btn-primary">{clientT('dashboard.createButton')}</AppButton>
              </AppLink>
            </div>
          </main>
        </div>
        {this.state.partnerPopupOpen && <PartnerPopup parentWidth={this.myCard} closePopup={this.setState}/>}
        {this.state.commerceBoxOpen && <CommerceBoxPopup closePopup={this.setState}/>}
        {this.renderDeleteOffer()}
      </>
    );
  }
}

export default withRouter(Dashboard);
import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { offersData, discountData, thresholdData } from "../../Utils/Constants";
import ErrorPopup from "../../components/Popup/ErrorPopup";
import { authCall } from "../../tools/apitools";
import Context from "../../tools/context";
import AppButton from "../../components/AppButton";
import {clientT} from "../../tools/i18n";
const sellerData = {
  SELLER_ID: "2816",
  SELLER_UUID: "4f39a03c-4ee0-40b3-9f59-949022231802",
};

export default class AddProductHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: props.productId,
      productDescription: "",
      isProductDescription: false,
      offersValue: null,
      discountValue: "",
      isDiscountValue: false,
      discountType: null,
      thresholdType: null,
      thresholdValue: "",
      isThresholdValue: false,
      isErrorPopup: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.previousData !== this.props.previousData) {
      this.setState(this.props.previousData);
    }
    if (prevProps.productId !== this.props.productId) {
      this.setState({productId:this.props.productId})
    }
  }

  generateDescription = () => {
    const value = this.state.thresholdValue;
    let type = '';
    let threshold = ''
    
    if (this.state.discountType === 'value')
      type = '€ de remise';
    else if (this.state.discountType === 'percent')
      type = '% de remise';
      switch (this.state.thresholdType) {
      case 'BASKET':
        threshold =  `dès ${value}€ d'achat`;
        break;
      case 'POINTS':
        threshold = `pour ${value} points accumulés`;
        break;
      case 'ORDERS':
        threshold = `pour ${value} points accumulés`;
        break;
      default:
        threshold = ``;
        break;
    }
    return `${this.state.discountValue} ${type} ${threshold}`;
  }

  handleSubmit = (e) => {
    const tmp = {...this.state};
    const {
      productDescription,
      discountValue,
      thresholdValue,
    } = this.state;

    e.preventDefault();
    tmp.isProductDescription = productDescription === "";
    tmp.isDiscountValue = discountValue === "";
    tmp.isThresholdValue = thresholdValue === "";

    if (!tmp.productDescription) {
      const desc = this.generateDescription();
      tmp.productDescription = desc;
    }
    // Checking if form has been filled properly
    if (
      isNaN(parseInt(discountValue)) === false &&
      isNaN(parseInt(thresholdValue)) === false
    ) {
      this.setState(tmp);
      this.props.setHomeData({
        homeData: { ...tmp },
      });
    }
  };

  render() {
    const { screen } = this.props;
    const {
      productDescription,
      isProductDescription,
      offersValue,
      isThresholdValue,
      productId,
      isDiscountValue,
      discountValue,
      discountType,
      thresholdType,
      thresholdValue,
    } = this.state;

    const isService = offersValue === 'service';
    return (
      <React.Fragment>
        <div className="page productBox">
          {screen === 1 || screen === 2 ? (
            <>
              {Context.partner &&
              Context.partner !== {} &&
              Context.partner.showHeading ? (
                <p className="titleHeader mt-7">
                   {clientT(productId == null ?'addProductHome.createPlanTitle':'addProductHome.updatePlanTitle')}
                </p>
              ) : (
                <p className="titleHeader">{clientT('addProductHome.titles.0')}</p>
              )}
              {Context.partner &&
              Context.partner !== {} &&
              Context.partner.showSubHeading ? (
                <p className="titleChild subHeading">
               {clientT('addProductHome.titles.1')}
                  <span className="star pl-1">*</span>
                </p>
              ) : (
                <p className="titleChild">{clientT('addProductHome.titles.2')}</p>
              )}
              <div className="form-group form-inline nopad mt-16">
                <label>J’offre</label>
                <div className="custom-select">
                  <Dropdown
                    onChange={(e, { value }) => {
                      this.setState({ offersValue: value });
                    }}
                    options={offersData}
                    placeholder="Sélectionner"
                    selection
                    value={offersValue}
                  />
                </div>
              </div>
              {isService ? (
                <div
                  className="form-group form-inline nopad mt-16"
                  id="discountDiv"
                >
                  {/* <label>Description</label> */}
                  <input
                    id="productDescription"
                    type="text"
                    className={`customInputField large ${
                      isProductDescription && "validate-error"
                    }`}
                    placeholder="Description du service / produit offert"
                    value={productDescription}
                    onChange={(e) => {
                      this.setState({ productDescription: e.target.value });
                    }}
                  />
                </div>
              ) : null}

              {offersValue && (
                <div
                  className="form-group form-inline nopad mt-16"
                  id="discountDiv"
                >
                  <label>{isService ? 'd’une valeur de' : 'de'}</label>
                  <input
                    id="discountAmount"
                    type="tel"
                    className={`customInputField ${
                      isDiscountValue && "validate-error"
                    }`}
                    placeholder="00"
                    value={discountValue}
                    onChange={(e) => {
                      this.setState({ discountValue: e.target.value });
                    }}
                  />
                  {isService ? <label style={{marginLeft: '20px'}}>€</label> :
                    <div className="custom-select discountSelection">
                      <Dropdown
                        onChange={(e, {value}) => {
                          this.setState({discountType: value});
                        }}
                        options={discountData}
                        placeholder="Sélectionner"
                        selection
                        value={discountType}
                      />
                    </div>
                  }
                </div>
              )}
             

              {(discountType || isService) && (
                <div
                  className="form-group form-inline nopad mt-23"
                  id="thresholdDiv"
                >
                  <label>pour</label>
                  <div className="custom-select" style={{ marginLeft: "23px" }}>
                    <Dropdown
                      onChange={(e, { value }) => {
                        console.log('eeeeeee', value)
                        this.setState({ thresholdType: value });
                      }}
                      options={thresholdData}
                      placeholder="Sélectionner"
                      selection
                      value={thresholdType}
                    />
                  </div>
                </div>
              )}

              {thresholdType && (
                <div
                  className="form-group form-inline nopad mt-24"
                  id="thresholdInputDiv"
                >
                  <label>
                  {thresholdType === 'Basket' && 'De '}
                  {thresholdType === 'Points' && 'avec'}
                  {thresholdType === 'Orders' && 'De '}
                  </label>
                  <input
                    id="thresholdValue"
                    type="tel"
                    className={`customInputField ${
                      isThresholdValue && "validate-error"
                    }`}
                    placeholder="000"
                    value={thresholdValue}
                    onChange={(e) =>
                      this.setState({ thresholdValue: e.target.value })
                    }
                    style={{ marginLeft: "38px" }}
                  />
                  <label id="thresholdText" style={{ marginLeft: "8px" }}>
                    {thresholdType === 'Basket' && '€'}
                    {thresholdType === 'Points' && 'point(s)'}
                    {thresholdType === 'Orders' && 'commande(s)'}
                  </label>
                </div>
              )}

              <div className="sticky-bottom">
                <AppButton
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSubmit}
                >
                  {Context.partner &&
                  Context.partner !== {} &&
                  Context.partner.showSubmitBtn ? (
                    <span>{clientT('addProductHome.submitButton')}</span>
                  ) : (
                    <>
                      <span className="icon start icon-md icon-save"></span>
                      <span>{clientT('addProductHome.saveButton')}</span>
                    </>
                  )}
                </AppButton>
              </div>

              {this.state.isErrorPopup && (
                <ErrorPopup error={this.state.isErrorPopup} />
              )}
            </>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

import React from 'react';
import '../../style/banner.scss';
import Context from '../../tools/context';

const Banner = () => {
  return (
    <div className='bannerWrapper'
    dangerouslySetInnerHTML={{
      __html: Context.partner.banner,
    }}
    >
    </div>
  );
};

export default Banner;

import React, { Component } from 'react';
import "../style/ticketExchange.scss";

export default class TicketExchange extends Component {

    redirectToSummaryPage = () => {
       if (this.props.exchangeData)
            window.location.href = this.props.exchangeData;
    }

    renderContent = () => {
        const {showTerminationPage} = this.props;
        if (showTerminationPage){
            return( <div className="terminationContainer bg-liteGreen h-full">
               <p className="terminationHeading">Merci !</p>
               <img src="./assets/tcaAssets/09083448/chatFork-1.png" />
               <p className="terminationTxt">Retrouvez votre carte cadeau {"TheFork"} en cliquant sur le lien ci- dessous</p>
               <img onClick={() => { this.redirectToSummaryPage() }} style={{border: '1px solid #ffffff', marginBottom: '28px', cursor: "pointer"}} src="./assets/tcaAssets/09073743/giftcard-fr-1.png" />
               <button onClick={() => { this.redirectToSummaryPage() }} className="terminationBtn text-liteGreen">Terminer</button>
               </div>
            )
        }
        else{
            return (<>
                <p className="ticketExchangeHeading text-liteGreen">Validez votre choix</p>
                <img src="./assets/tcaAssets/09073743/giftcard-fr-1.png" />
                </>
            )
        }
    }

    render() {
        return (
            <div className="ticketExchangeWrapper">
                {this.renderContent()}
            </div>
        )
    }
}

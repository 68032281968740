import React, {Component} from 'react';

export default class FilteredSelectField extends Component {
  constructor(props) {
    super(props);

    const {
      value,
      values,
    } = props;
    const filter = value ? Object.keys(values).find(k => values[k].some(v => v.value == value)) : null;

    this.state = {
      filter,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const {
        value,
        values,
      } = this.props;
      const filter = value ? Object.keys(values).find(k => values[k].some(v => v.value == value)) : null;

      this.setState({filter})
    }
  }

  handleFilterChange = (e) => {
    const filter = e.target.value;
    this.setState({filter});

    const {
      id,
      handleChange,
      values,
    } = this.props;

    if (values[filter].length === 1) {
      handleChange({target: {id, value: values[filter][0].value}})
    } else {
      handleChange({target: {id, value: ""}})
    }
  }

  render() {
    const {
      id,
      label,
      handleChange,
      optional,
      subLabel,
      defaultLabel,
      value,
      values,
    } = this.props;

    const valueList = this.state.filter ? values[this.state.filter] : [];
    let localval = valueList && valueList.some(v => v.value == value) ? value : null;

    const monoValue = valueList.length === 1;
    return (
      <p>
        <label htmlFor={id}>
          {label}{!optional ? <span
          className="required">*</span> : null} <span
          className="subLabel">{subLabel}</span>
        </label>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}>
          {Object.keys(values).map((k) => (
            <span>
              <input
                type="radio"
                id={k}
                value={k}
                name={`${id}_filter`}
                checked={k === this.state.filter}
                onChange={this.handleFilterChange}
                required={!optional}
              />
              <label htmlFor={k}>
                {k}
              </label>
            </span>
          ))}
        </div>
        {this.state.filter && <select
          required={!optional}
          className='categoryList'
          id={id}
          onChange={handleChange}
          value={localval}
          disabled={monoValue}
        >
          {monoValue ?
            <option value={valueList[0].value}>
              {valueList[0].label}
            </option>
            : <>
              <option value="" selected={!localval} disabled>
                {defaultLabel}
              </option>
              {valueList.map((v, i) => (
                <option key={`${i}_${v.value}`} value={v.value}>
                  {v.label}
                </option>
              ))}
            </>}
        </select>}
      </p>
    );
  }
}

import React, { Component } from 'react';
import QrScanner from '../QrScanner';
import Context from "../../tools/context";
import AppLink from '../AppLink';

export default class CommerceBoxPopup extends Component {

  constructor(props){
    super(props);
    this.state = {
      qrPopupOpen : false
    }

    this.setState = this.setState.bind(this);
  }

    componentWillMount(){
       document.body.classList.add("has-popup");
    }

    componentWillUnmount(){
       document.body.classList.remove("has-popup");
    }

    render() {
        return (
          <>
            <div id="commerceBox">
            <div id="popupCommerceBoxContent">
              <div className="popupContentWrapper">
                    <div className="popupImageHeader">
                  <div>
                    <img className="popupImage" src="/assets/tcaAssets/16094107/image-1%403x.png" />
                    </div>
               <div className="popupCloseButtonWrapper">
                <svg className="popupCloseButton" onClick={() => this.props.closePopup({commerceBoxOpen : false, partnerPopupOpen: false})} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.063 12.063">
                     <g id="Symbol_85_1" data-name="Symbol 85 – 1">
                      <path id="Union_3" data-name="Union 3" d="M6.031,7.128,1.1,12.063,0,10.966,4.935,6.031,0,1.1,1.1,0,6.031,4.935,10.966,0l1.1,1.1L7.128,6.031l4.935,4.935-1.1,1.1Z" fill="#3a4581"></path>
                      </g>
                  </svg>
              </div>
            </div> 
         <div className="popupBodyContentWrapper">
          <div><AppLink id="stclink" data-category="bons" href={`https://sauvetoncommerce.fr/dashboard/products/?from=bvn&accesstoken=${Context.seller.uuid}`} className="jsEvent stcLinkBtn btn btn-secondary btn-light btn-inline btn-sm">Gérer mes bons solidaires</AppLink></div>
          <div><AppLink id="stcValidateLink" data-category="coupons" href={`https://sauvetoncommerce.fr/merci?from=bvn&accesstoken=${Context.seller.uuid}`} className="jsEvent stcLinkBtn btn btn-secondary btn-light btn-inline btn-sm">Valider code client</AppLink></div>
          </div>  
          <div className="qrScannerBtnWrapper">
          <AppLink href="#" onClick={() => this.setState({qrPopupOpen : true})} id="qrScannerr" data-category="scan" className="jsEvent qrScannerBtn btn btn-secondary btn-light btn-inline btn-sm">          
            <svg id="Scan" style={{marginBottom: "-9px"}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path id="Tracé_716" data-name="Tracé 716" d="M0,0H30V30H0Z" fill="none"/>
            <path id="Tracé_717" data-name="Tracé 717" d="M4,7.75V6.5A2.5,2.5,0,0,1,6.5,4H9" transform="translate(1 1)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Tracé_718" data-name="Tracé 718" d="M4,17v1.25a2.5,2.5,0,0,0,2.5,2.5H9" transform="translate(1 4.25)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Tracé_719" data-name="Tracé 719" d="M16,4h2.5A2.5,2.5,0,0,1,21,6.5V7.75" transform="translate(4 1)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Tracé_720" data-name="Tracé 720" d="M16,20.75h2.5a2.5,2.5,0,0,0,2.5-2.5V17" transform="translate(4 4.25)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Ligne_23" data-name="Ligne 23" x2="17.188" transform="translate(6.685 15.278)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </svg> Scanner QR code
          </AppLink>
          </div>
          <hr className="popupSeparatorLine" />
          <div className="popupFooterWrapper">
           <div>
             <p className="popupFooterText">Diffusez votre présence sur la plateforme</p>      
             <AppLink data-category="kit" href="https://cdn.cibler.io/stc/kit-com.zip" className="jsEvent popupFooterBtn btn btn-secondary btn-light btn-inline btn-sm">Kit de communication</AppLink>
            </div>
        <div>
          <p className="popupFooterText">Besoins d’aide ? Appelez-nous</p>
          <AppLink data-category="help" href="tel:0980800885" anchor className="jsEvent popupFooterBtn btn btn-secondary btn-light btn-inline btn-sm">09 80 80 08 85</AppLink>
       </div>
    </div>
    </div>
    </div>
    </div>
    { this.state.qrPopupOpen && <QrScanner closeQrPopup={() => this.setState({qrPopupOpen: false})} /> }
    </>
        )
    }
}

import React, {Component, useState} from 'react';
import '../../style/home.scss';
import { Context, ContextUtil } from '../../tools/context';
import { logout } from '../../tools/toolbox';
import { Link } from 'react-router-dom';
import Menu from "../../components/Menu";
import AppLink from '../../components/AppLink';

class HomeDefault extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    }
  }

  componentDidMount() {
    if (Context.partner.sponsor) {
      document.title = 'Ticket Commerçant';
    }
  }

  handleMenuState = (state) => {
    this.setState({open: state})
  }

  render() {
    const ticketCoLogo_src = Context.partner.ticketcommercant
      ? `/assets/ticket_co_${Context.partner.sponsor}.png`
      : '/assets/ticket_co_lavalagglo.png';
    const partnerLogo_src =
      `/assets/${Context.partner.sponsor}_logo.png` || '#';
    console.log(Context.seller.shortcode, Context);

    return (
      <div className='homePage_Container'>
        <div className='homePage_header'>
          <div style={{flex: 0}}>
              <Menu onChange={this.handleMenuState} open={this.state.open} />
          </div>
          <div style={{transition: 'margin-left 500ms', marginLeft: this.state.open ? '375px': '0'}}>
            <img src={ticketCoLogo_src} />
          </div>
          <div>
            <img src={partnerLogo_src} />
          </div>
        </div>

        <div className='homePage_backGroundImage1'>
          <div className='homePageBody'>
            <h1 className='homePage_MainTitle'>
              BIENVENUE DANS VOTRE ESPACE COMMERÇANT
            </h1>
            <br />
            <br />
            <p className='homePage_Titles'>
              RAPPROCHEZ-VOUS DE VOTRE COMMUNE POUR RÉCUPÉRER VOTRE AFFICHETTE
              ET VITROPHANIE AFIN DE COMMUNIQUER EN MAGASIN
            </p>
            <br />
            <p className='startDate'>
              L'OPÉRATION TICKET COMMERÇANT® DÉBUTE <br />À PARTIR DU{' '}
              {Context.partner.campaignStartDate
                ? Context.partner.campaignStartDate
                : Context.partner.launchDate}
            </p>
            <br />
            <br />
            <AppLink href="/dashboard-new">
              <p className='homePage_whiteButton'>
                JE DECOUVRE MON TABLEAU DE BORD
              </p>
            </AppLink>
            <p className='homePage_Titles'>
              VOICI VOTRE CODE COMMERÇANT QUI SERA UTILISÉ EN TANT
              QU'IDENTIFIANT PAR VOS CLIENTS POUR VOUS ATTRIBUER UN TICKET
              COMMERÇANT.
            </p>
            <p className='homePage_whiteButton sellerCode'>
              {Context.seller.shortcode}
            </p>
            <p className='homePage_Titles'>
              COMPLÉTEZ VOS INFORMATIONS PERSONNELLES
            </p>
            <AppLink className='homePage_whiteButton' href={'/profile'}>
              JE RENSEIGNE MES INFORMATIONS PERSONNELLES
            </AppLink>
            {Context.features?.KYCEnabled && <>
              <p className='homePage_Titles'>
                COMPLÉTEZ VOS COORDONNÉES BANCAIRES
              </p>
              <AppLink className='homePage_whiteButton' href={'/mon-solde'}>
              JE RENSEIGNE MES COORDONNÉES BANCAIRES
              </AppLink>
            </>}
            <p className='homePage_Titles'>
              VOUS POUVEZ TÉLÉCHARGER CI-DESSOUS VOTRE KIT DE COMMUNICATION
              EXPLIQUANT LES DÉTAILS DE L'OPÉRATION À VOS CLIENTS.
            </p>
            {Context.partner &&
            Context.partner.bvnKitURL && Context.partner.bvnKitURL !== '' &&
            <p className='homePage_whiteButton'>
              <AppLink
                href={
                  Context.partner &&
                  Context.partner.bvnKitURL &&
                  Context.partner.bvnKitURL !== ''
                    ? `${Context.partner.bvnKitURL}`
                    : '#'
                }
                target="_blank"
              >
                JE TÉLÉCHARGE MON KIT DE COMMUNICATION
              </AppLink>
            </p>
          }
            <p
              className='homePage_LogOut'
              onClick={() => {
                logout();
                this.props.history.push('/connexion');
              }}
            >
              JE ME DÉCONNECTE
            </p>
          </div>
        </div>
        <div className='process_title'>
          <h1>Comment ça marche?</h1>
        </div>
        <div className='process_Wrapper'>
          <div>
            <picture className='backGroundImage2'>
              <source
                media='(max-width: 992px)'
                srcSet='/assets/tcaAssets/26170155/750x118.png'
              />
              <img
                src='/assets/tcaAssets/08173558/commentcamarche-1.png'
                alt='some picture'
              />
            </picture>
          </div>

          <div className='helpDesk'>
            <p>CE N'EST PAS CLAIR ?</p>
            <p>UN NUMERO VERT VOUS ACCOMPAGNE</p>
            <p> 09 80 80 37 49</p>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeDefault;

import React from 'react';
import Dropzone from 'react-dropzone';
import {withTranslation} from "react-i18next";
import '../pages/Client/refund/index.scss';
import {clientT} from "../tools/i18n";
import '../style/imageZone.scss';

class ImageZone extends React.Component {
  onDrop = (file) => {
    const newFile = file[0];
    newFile.preview = URL.createObjectURL(file[0]);
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.addEventListener('load', () => {
      newFile.data = reader.result;
      this.props.setFile(newFile);
    })
  }

  renderImage = () => {
    if (this.props.file) {
      return (
        <div className="imageBlock">
          <button onClick={(e) => {
            e.stopPropagation();
            this.props.setFile(null)
            return null;
          }}>X</button>
        <img src={this.props.file.preview} alt={this.props.file.name} />
      </div>
      )
    }

    return (
      <div className="emptyBlock">
        <svg width="244" height="85" viewBox="0 0 244 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="242" height="83" rx="13" strokeWidth="2" strokeDasharray="6 6"/>
          <path d="M108.389 29.8972C107.017 30.2425 105.818 31.077 105.018 32.2439C104.218 33.4108 103.871 34.8298 104.043 36.2343C104.215 37.6388 104.894 38.9321 105.953 39.8712C107.011 40.8104 108.376 41.3307 109.791 41.3344H111.242M118.496 48.5888V35.5309V48.5888ZM118.496 48.5888L114.869 45.687L118.496 48.5888ZM118.496 48.5888L122.123 45.687L118.496 48.5888Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M124.053 27.026C123.557 25.0623 122.333 23.3607 120.629 22.266C118.925 21.1714 116.869 20.7656 114.877 21.1309C112.885 21.4963 111.106 22.6054 109.901 24.2335C108.697 25.8616 108.156 27.8869 108.39 29.8987C108.39 29.8987 108.612 31.1783 109.066 31.9038" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M125.751 41.3344C126.775 41.3337 127.788 41.116 128.722 40.6958C129.656 40.2755 130.491 39.6622 131.171 38.8962C131.851 38.1301 132.361 37.2287 132.668 36.2513C132.974 35.2739 133.07 34.2426 132.95 33.2254C132.829 32.2082 132.494 31.228 131.967 30.3495C131.441 29.4709 130.734 28.7139 129.893 28.1283C129.053 27.5426 128.098 27.1416 127.091 26.9516C126.085 26.7616 125.049 26.7869 124.053 27.0259L122.124 27.5511" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <span>
          {clientT('refund.ask.proofOfPurchase')}
        </span>
      </div>
    );
  }

  render() {
    return (
      <div className="ImageZone">
        <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {this.renderImage()}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default withTranslation()(ImageZone)
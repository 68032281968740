import React from 'react';
import Context from "../../../tools/context";
import TCATickets from "./TCATickets";
import TCTickets from "./TCTickets";

const Ticket = (props) => {
  const {ticket, selected, handleSelection, showPromo} = props;
  if (Context.partner.sponsor === 'myprovence' && false) {
    return (
      <div
        className={`ticketCard ${selected ? 'selected' : ''}`}
        onClick={() => {
          handleSelection(ticket);
        }}
      >
        <img
          src='/assets/myprovence_logo.png'
          alt='my-provence logo'
        />
        <h1>{ticket.value}€</h1>
        <div className={'code'}>{ticket.prize}</div>
        <p>{ticket.description.split(' ').slice(-3).join(' ')}</p>
      </div>
    );
  }

  const ca = ticket.giftType === 'Standard';
  if ((showPromo && ca) || (!showPromo && !ca)) return null;

  // if (Context.partner?.client?.ticketType === 'TCA') {
    return <TCATickets {...props} promo={!ca} type={Context.partner?.client?.ticketType} />
  // }
  //
  // return <TCTickets {...props} />
}

export default Ticket;
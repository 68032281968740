import { Component } from "react";

// This page is the redirection page when something went wrong from client ticketPage
class TempPage extends Component {
    render () {
        return (
            <div>
                <p>L'ID du client ou le token client est incorrect</p>
            </div>
        )
    }
}

export default TempPage;
import React, { Component } from "react";
import "../style/semantic/semantic.scss"
import "../style/addProduct.scss";
import AddProductHome from "../containers/Product/AddProductHome";
import { AddProductSuccess } from "../containers/Product/AddProductSuccess";
import { AddProductInfo } from "../containers/Product/AddProductInfo";
import {authCall, uploadImageMethod} from "../tools/apitools";
import { Context } from "../tools/context";
import {withRouter} from "react-router-dom";

class AddProduct extends Component {
  constructor(props) {
      super(props);
    this.state = {
      transformDirection: "0%",
      homeData: {},
      additionalInfo: {},
      screen: 1,
      previousData: {},
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    const productParam = new URLSearchParams(window.location.search);
    if (productParam.has("product_id")) {
      const productId = productParam.get("product_id");
      this.setState({ productId });
      this.getProduct(productId);
    }
  }

  getProduct = async (productId) => {
    authCall(
      `api/seller/offers/${productId}`,
      {},
       (result) => {
        console.log(result);
        if(result){
        const {
          description,
          value,
          threshold,
          discountType,
          thresholdType,
          id,
          campaignId,
          giftData,
        } = result;

        const previousData = {
          productDescription: description,
          offersValue: discountType === 'GWP' ? 'service' : 'reduction' ,
          discountValue: value,
          discountType: discountType === 'GWP' ? 'value' : discountType && discountType.toLowerCase(),
          thresholdValue: threshold,
          thresholdType:
          thresholdType && thresholdType.toLowerCase() === "basket"
              ? "Basket"
              : thresholdType &&  thresholdType.toLowerCase() === "points"
              ? "Points"
              : "Orders",
          isErrorPopup: false,
          id,
          campaignId,
          giftData,
        }

        this.setState({previousData});
      }
      },
      () => {}
    );
  };

  handleSubmit = (datas) => {
    console.log('submit')
    this.setState(() => (datas),
      () => {
      const {homeData, additionalInfo, previousData} = this.state;
        const data = {
          sellerId: Context.seller && Context.seller.id,
          sellerName:
            Context.seller && Context.seller.name && Context.seller.name,
          giftDTO: {
            description: homeData.productDescription,
            name: homeData.productDescription.toUpperCase(),
            value: homeData.discountValue,
            threshold: homeData.thresholdValue,
            thresholdType: homeData.thresholdType.toUpperCase(),
            discountType: homeData.offersValue === 'service'
              ? 'GWP'
              : homeData.discountType.toUpperCase(),
          },
          data: additionalInfo
        };

        if (previousData.id) {
          data.giftDTO.campaignId = previousData.campaignId;
          data.giftDTO.id = previousData.id;
        }
        authCall(
          "api/seller/offers",
          {
            method: this.state.previousData.id ? "PUT" : "POST",
            body: JSON.stringify(data),
          },
          (result) => {
            this.setState({previousData: {
                ...this.state.previousData,
                id: result.id,
                campaignId: result.campaignId,
              }})
          },
          (e) => {
            console.error(e);
          }
        );
        this.setState({
          screen: 2,
          transformDirection: `translateX(-100%)`,
        })
      });
  }

  handleUpload = (file) => {
    uploadImageMethod('api/seller/offers/image',
      file, `${Context.seller.id}/${this.state.previousData.id}`,
      false, () => undefined, () => undefined);
  }

  render() {
    return (
      <React.Fragment>
        <div id="page-container">
          <main
            className="page-container"
            style={{ transform: this.state.transformDirection }}
          >

            <AddProductHome
              screen={this.state.screen}
              setHomeData={this.handleSubmit}
              transformScreen={this.transformScreen}
              productId={this.state.productId}
              previousData={this.state.previousData}
            />
            <AddProductSuccess
              history={this.props.history}
              {...this.state}
              setSuccessData={this.setState}
            />
            <AddProductInfo
              screen={this.state.screen}
              setAdditionalInfo={this.handleSubmit}
              previousData={this.state.previousData}
              uploadImage={this.handleUpload}
            />
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddProduct)
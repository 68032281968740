import React, { Component } from 'react';
import {t} from '../../tools/i18n'
import SelectField from './SelectField';
import FilteredSelectField from "./FilteredSelectField";
import ImportField from "./ImportField";
import CheckboxField from "./CheckboxField";
import TextareaField from "./TextareaField";
import DateField from "./DateField";
import PhoneField from "./PhoneField";

export default class FormField extends Component {
  render(){
    const {id, label,
      handleChange, optional,
      type, pattern,
      errorMsg, value,
      customError, subLabel,
      placeholder, disabled, labelOptional,
      rows, i18n
    } = this.props;
    const placeHolder =
      (labelOptional && `${i18n ? t(`form.${id}`) : label}${!optional ? '*' : ''}`)
      || placeholder;

    switch (type) {
      case 'select':
        return <SelectField {...this.props} />
      case 'filteredSelect':
        return <FilteredSelectField {...this.props} />
      case 'import':
        return <ImportField {...this.props} />
      case 'checkbox':
        return <CheckboxField {...this.props} />
      case 'textArea':
        return <TextareaField {...this.props} />
      case 'date':
        return <DateField {...this.props} />
      case 'phone':
        return <PhoneField {...this.props} />
      default:
        return (
          <>
            <p>
             {
               !labelOptional &&  <label htmlFor={id}>
               {i18n ? t(`form.${id}`) : label}{!optional ? <span
               className="required">*</span> : null} <span
               className="subLabel">{subLabel}</span>
             </label>
             }
              <input
                required={!optional}
                type={type || 'text'}
                pattern={pattern}
                id={id}
                onChange={handleChange}
                value={value}
                placeholder={placeHolder}
                disabled={disabled}
                onInvalid={(e) => {
                  if (customError) e.target.setCustomValidity(i18n ? t(`register.customErrors.${id}`) : customError);
                }}
                onInput={(e) => {e.target.setCustomValidity('')}}
              />
              {errorMsg ?
                <span className="error">
                  {errorMsg}
                </span>
                : null}
            </p>
          </>
        )
    }
  }
}
import React, { Component } from 'react'
import AppButton from '../AppButton';
import CommerceBoxPopup from './CommerceBoxPopup';

export default class PartnerPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commerceBoxOpen: false,
            partnerPopupOpen: true,
        }
        this.setState = this.setState.bind(this);
    }

    componentWillMount(){
        document.body.classList.add("has-popup");
     }

     componentDidUpdate(){
        if (!this.state.partnerPopupOpen){
            this.props.closePopup({partnerPopupOpen: false});
        }
    }
 
     componentWillUnmount(){
        document.body.classList.remove("has-popup");
     }

    render() {
        return (
            <>
                <div className="card card-offer flex-equal card-padding" style={{ position: "absolute", top: `${this.props.parentWidth.current.offsetTop + "px"}`, left: `${this.props.parentWidth.current.offsetLeft + "px"}`, width: `${this.props.parentWidth.current.offsetWidth + "px"}`, maxWidth: "818px", zIndex: "999999"}}>
                    <div className="content-wrap">
                        <p className="offer">
                            <img style={{ height: "55px", marginTop: "0px", marginBottom: "-10px" }}
                                src="/assets/tcaAssets/16094107/image-1%403x.png" />
                        </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <AppButton event="Ouvrir" onClick={() => this.setState({ commerceBoxOpen: true })} style={{ borderColor: "rgba(58, 69, 129, 0.08)", padding: "12px" }} className="ouvrir btn btn-secondary btn-light btn-inline btn-sm">
                            Ouvrir
                        </AppButton>
                    </div>
                </div>

                <div id="partnerErrorPopup">
                    <div id="popupCommerceBoxContent">
                        <div className="popupContentWrapper">
                            <div className="popupImageHeader partnerPopupImageHeader">
                                <div>
                                    <img className="popupImage" src="/assets/tcaAssets/16094107/image-1%403x.png" />
                                </div>
                                <div>
                                    <p style={{ color: "#3a4581", fontSize: "12px", fontWeight: "bold", paddingTop: "14px" }}>
                                        Bienvenue sur votre nouvel Espace Pro !
                              </p>
                                </div>
                                <div className="popupCloseButtonWrapper">
                                    <svg className="partnerPopupCloseButton" onClick={() => this.props.closePopup({partnerPopupOpen : false})} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.063 12.063">
                                        <g id="Symbol_85_1" data-name="Symbol 85 – 1">
                                            <path id="Union_3" data-name="Union 3" d="M6.031,7.128,1.1,12.063,0,10.966,4.935,6.031,0,1.1,1.1,0,6.031,4.935,10.966,0l1.1,1.1L7.128,6.031l4.935,4.935-1.1,1.1Z" fill="#3a4581"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#3a4581" }}>Cliquez sur le bouton Sauve ton commerce pour accéder à vos fonctionnalités</p>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.commerceBoxOpen && <CommerceBoxPopup closePopup={this.setState} /> }
            </>
        )
    }
}

import React, { Component } from 'react';
import '../../style/errorPopup2.scss';
import AppButton from '../AppButton';
import AppLink from '../AppLink';

class ErrorPopup2 extends Component {
 
    render() {
        return (
            <div className="error-popup" style={this.props.style || {}}>
                    <p style={{fontWeight: '500'}}>{this.props.message}</p>
                    <AppButton event="Fermer" onClick={() => this.props.onClose()}>Fermer</AppButton>
                    <AppLink anchor href="mailto:contact@bienvenus.com"> Contacter le service client</AppLink>
            </div>
        )
    }
}

export default ErrorPopup2;
import React from "react";
import { withRouter, matchPath } from "react-router-dom";
import {track} from "../tools/tracking";
import {ContextUtil} from "../tools/context";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
      || (this.props.loading != prevProps.loading && !this.props.loading)
    ) {
      ContextUtil.update('domainType', this.getMatch(this.props));
      window.scrollTo(0, 0);
      track('viewPage', {url: this.props.location.pathname});
    }
  }

  getMatch = props => {
    let matchP = matchPath(props.location.pathname,
      props.routes.map(r => r.path));
    if (matchP) return 'seller';

    matchP = matchPath(props.location.pathname,
      props.clientRoutes.map(r => r.path));
    if (matchP) return 'client';

    return 'public';
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
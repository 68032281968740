import React, { Component } from "react";
import { magasinDialpadButtons } from "../Utils/Constants";
import { Link } from "react-router-dom";
import "../style/magasinDailpad.scss";
import Context from "../tools/context";
import MagasinStoreHeader from "../components/MagasinStoreHeader";
import PhoneField from "../components/formField/PhoneField";
import AppLink from "../components/AppLink";
import AppButton from "../components/AppButton";
import { clientT } from "../tools/i18n";

export default class MagasinDialpad extends Component {

  constructor() {
    super();
    this.state = {
      phoneNumber:''
    }
    this.phoneRef = React.createRef();
    this.completeNumber = {};
  }

  handlePhoneNumber = (e) => {
    const newNo = this.state.phoneNumber + e.target.value;
    this.phoneRef.current.notifyPhoneNumberChange(newNo);
    this.setState({phoneNumber: this.phoneRef.current.formatNumber(newNo)});

    if(this.completeNumber?.target?.value?.valid) {
        this.props.onPhoneInputEnd(this.completeNumber?.target?.value?.formatted);
    }
  };

  clearInput = () => {
    this.setState({ phoneNumber: "" });
  };

  removePhoneDigit = () => {
    const newNo = this.state.phoneNumber && this.state.phoneNumber.slice(0, -1);
    this.setState({ phoneNumber: this.phoneRef.current.formatNumber(newNo) });
  };

  render() {
    const { phoneNumber } = this.state;

    return (
      <div className="page magasin-init" id="magasinDialpad">
          <div className="page-content">
            <section className="hero">
              <div className="content-wrap">
                <header className="page-header">
                  <AppLink href="/dashboard-new" className="btn btn-link">
                    <span className="icon icon-close icon-white"></span>
                  </AppLink>
                </header>
                <MagasinStoreHeader sellerId={Context.seller.id} storeName={Context.seller.name}/>
                <h2 dangerouslySetInnerHTML={{__html: clientT('magasinDialpad.dialpadText') }} />
                <div className="form-group white-text">
                  <label htmlFor="initPhone">
                    {clientT('magasinDialpad.initPhoneLabel')}
                  </label>
                  <div className="querryBox">
                    <div>
                      <div className="input-group">
                        <div className={'form-control'}
                             style={{
                               background: 'rgb(255, 255, 255)',
                               boxShadow: 'none',
                               padding: '0 14px'
                             }}
                        >
                          <PhoneField
                            inputRef={this.phoneRef}
                            handleChange={(e) => this.completeNumber = e}
                            value={{number: phoneNumber}}
                            fieldOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <AppButton
                        className="btn btn-red removeBtn"
                        onClick={this.removePhoneDigit}
                      >
                        <img
                          src="/assets/tcaAssets/25112931/clear.png"
                          alt="clear"
                        />
                      </AppButton>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="content-wrap">
              <div className="num-pad">
                {magasinDialpadButtons.map((value, index) => {
                  return (
                    <AppButton
                      key={index}
                      onClick={this.handlePhoneNumber}
                      value={value}
                      className={`btn btn-red ${value == "0" ? "m-35" : ""}`}
                      event={value}
                    >
                      {value}
                    </AppButton>
                  );
                })}
              </div>
            </section>
          </div>

      </div>
    );
  }
}

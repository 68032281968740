import React from 'react';
import Context from "../../../tools/context";
import Coche from "../../../components/Coche";
import TicketsInfoPopup from "../../../components/Popup/TicketsPopup";
import {popupTypes} from "../../../Utils/Constants";
import {clientT} from "../../../tools/i18n";

const TCATickets = ({ticket, selected, handleSelection, promo, type}) => {
  const [notYetEligible, setNotYetEligible] = React.useState(false);

  let bodyBase = () =>{
   switch(type) {
     case 'TCA':
       return {
         background: './assets/tca_ticket_background.png',
         backgroundFallback: './assets/tca_ticket_background.png',
         logo: '',
         condition : 'Tous professionnels du tourisme',
         textColor : '#ffffff',
         bgColor: '#108D94',
         couponColor: '#ffffff',
         descriptColor: '#ffffff',
         dateColor: null,
         borderWidth: 0,
       };
     default:
       return {
         background: null,
         backgroundFallback: null,
         logo: '',
         condition : 'Tous professionnels du tourisme',
         textColor : '#273680',
         bgColor: '#ffffff',
         couponColor: '#273680',
         descriptColor: '#273680',
         dateColor: null,
         borderWidth: '5px',
       };
   }
  };

  let body = bodyBase();
  if (Context.partner?.client?.ticketStyle) {
    body = {
      ...body(),
      ...Context.partner.client.ticketStyle,
    }
  }

  ticket?.campaignData
    ?.filter(d => Object.keys(body).includes(d.key) && d.value)
    .forEach(data => {
      let value = data.value;
      if (data.key === 'background' && data.value.startsWith('/')) {
        value = `https://cdn.cibler.io/campaigns/assets/${ticket.campaignId}/mobile${data.value}`;
      }
      body[data.key] = value;
    });

  ticket.condition = body.condition;
  const [background, setBackground] = React.useState(body.background);

  const isDateValid = () => {
    if (!ticket.validityStartDate) return true;
    const date = new Date(ticket.validityStartDate);

    return (date - new Date()) < 0;
  }

  const getType = (discountType, value, description) => {
    switch(discountType.toLowerCase()) {
      case 'value':
        return `${value}€ de remise`;
      case 'percent':
        return `${value}% de remise`;
      default:
        return description;
    }
  }

  const renderThreshold = (thresholdType, thresholdValue) => {
    const value = thresholdValue;
    if (thresholdType && thresholdType.toLowerCase() === 'basket') {
      return `dès ${value}€ d'achat`;
    }
    if (thresholdType &&  thresholdType.toLowerCase() === 'points') {
      return `pour ${value} points accumulés`;
    }
    return `pour ${value} achats`;
  }

  const description = () => {
    return ticket.threshold ? <>
      <div style={{fontWeight: 'normal'}}>{clientT('myTickets.step1.forAPurchase')}</div>
      <div>{clientT('myTickets.step1.purchaseValue', {threshold: ticket.threshold})}</div>
    </> : <div style={{fontWeight: 'normal'}}>{clientT('myTickets.step1.noThreshold')}</div>;
  }

  const startValidity = () => {
    if (isDateValid()) return false;
    const date = new Date(ticket.validityStartDate);
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

    return <div style={{fontWeight: 'normal'}}>{clientT('myTickets.step1.validityFrom')}{' '}
      <span style={{fontWeight: 'bold', color: body.dateColor}}>
      {date.getDate()}/{month}/{date.getFullYear()}
    </span></div>
  }

  const expiration = () => {
    if (!ticket.expiration) return false;
    const date = new Date(ticket.expiration);
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

    return <div style={{fontWeight: 'normal'}}>{clientT('myTickets.step1.validityTo')}{' '}
      <span style={{fontWeight: 'bold', color: body.dateColor}}>
      {date.getDate()}/{month}/{date.getFullYear()}
    </span></div>
  }

  const attributed = () => {
    if (!ticket.burnDate) return false;

    const date = new Date(ticket.burnDate);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return <div>
      {clientT('usedTickets.affectedTo', {
        sellerRecipient: ticket.sellerRecipient,
        sellerRecipientId: ticket.sellerRecipientId,
        date: `${day}/${month}/${date.getFullYear()}`,
        hour: `${date.getHours()}:${minutes}`
      })}
    </div>;
  }

  const subConditions = () => {
    if (body.condition.toLowerCase() !== 'tous commerces') return false;

    return <div className="subtext">
      {Context.partner.exclusionText || 'Sauf alcool, tabac et jeux d\'argent'}
    </div>
  }

  const renderDescription = () => {
    if (promo) return null;
    // if (body.condition?.toLowerCase() === 'ticket evasion')
    //   return <div className="evasion"><img src="/assets/tca_evasion_mono.png" /></div>;

    return <div className="info" style={{color: body.descriptColor}}>
        {description()}
        {startValidity()}
        {expiration()}
        {attributed()}
      </div>;
  }

  const renderCondition = () => {
    if (promo) {
      let description = ticket.value
        ? getType(ticket.discountType, ticket.value, ticket.description) + " " + renderThreshold(ticket.thresholdType, ticket.threshold) || ticket.description
        : ticket.description || 'Découvrir notre établissement'
      return <div className={`code ${promo && 'promo'}`} style={{color: body.couponColor}}>
        <div>{description}</div>
        <div style={{marginTop: '15px'}}>{ticket.campaignName}</div>
      </div>;
    }

    return <div className="code" style={{color: body.couponColor}}>
      <div dangerouslySetInnerHTML={{__html: body.condition}} />
      {/*{subConditions()}*/}
    </div>
  }

  const handleClick = () => {
    if (!isDateValid()) return setNotYetEligible(true);

    handleSelection && handleSelection(ticket);
  }

  return (
    <>
    <div className="TCATickets"
      style={{
        backgroundImage: `url("${background}")`,
        backgroundColor: body.bgColor,
        borderColor: body.textColor,
        borderWidth: body.borderWidth,
        borderStyle: 'solid',
      }}
      onClick={handleClick}
    >
      <div className={`value ${promo && 'promo'}`} style={{color: body.textColor}}>
        {ticket.value}€
        <div className="couponCode">{ticket.prize}</div>
      </div>
      {renderDescription()}
      {renderCondition()}
      {handleSelection && isDateValid() && !promo &&
        <Coche height="21px" width="21px" className="coche" color={body.textColor} selected={selected}/>
      }
      <div style={{display: 'none'}}><img
        src={body.background} onError={() => {setBackground(body.backgroundFallback)}}
      /></div>
    </div>
    {notYetEligible &&
      <TicketsInfoPopup
        type={popupTypes.notYetEligible}
        close={(e) => setNotYetEligible(false)}
      />
    }
    </>
  )
}

export default TCATickets;
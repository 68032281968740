import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "../style/Magasin.scss";
import MagasinInit from '../containers/MagasinInit';
import MagasinDialpad from '../containers/MagasinDialpad';
import MagasinValidate from '../containers/MagasinValidate';
import MagasinHome from '../containers/MagasinHome';
import MagasinTransaction from '../containers/MagasinTransaction';
import MagasinGifts from '../containers/MagasinGifts';
import MagasinSummary from '../containers/MagasinSummary';
import MagasinFeedback from '../containers/MagasinFeedback';
import {siteContext} from "../tools/toolbox";
import {
  onPhoneInputEnd, validatePhoneCode,
  postMember, authCall,
  getIsMember, loginWithUserPhone
} from '../tools/apitools'
import Context from "../tools/context";
import UsedBudgetPopup from "../components/Popup/UsedBudgetPopup";
class Magasin extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      phoneNumber: '',
      giftSummaryCards : '',
      screen : 1,
      isSeller: false,
      clientToken: '',
      transactionAmount: 0,
      clientData: {},
      giftList: [],
      activeGift: {},
      validationError: false,
      showControlPopup: false,
      validationId: null,
      newClient: false,
      magasinLite: false
    }
    this.state = {
      ...this.initialState,
    }

    this.isSponsored = false;
    this.tabletSubscription = Context?.seller?.data?.some(d => d.key==='tablet_subscription' && d.value==='true');
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.getGiftsSponsor();
    if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state && this.props.history.location.state.magasinLite){
      this.setState({screen: 2, magasinLite: true});
    }
  }

  getGiftsSponsor = async () => {
    await authCall(`api/sellers/gifts/sponsor?shortCode=${Context.seller.shortcode}`,
      {method: 'GET'},
      result => {

        if (!result.length) return;

        this.isSponsored = true;
        if(siteContext().budgetControled === new Date().getMonth() || !this.tabletSubscription) return;
        const sum = result.reduce((acc, cur) => (cur.usedBudget || 0)+acc, 0);
        if (sum >= 1000) {
          this.setState({showControlPopup: true})
        }
      }, () => undefined);
  }

  //TODO Rename variable data resource
  setIsSeller = (isSeller) => {
    this.setState({isSeller})
    this.goToScreen(2);
  }

  onPhoneInputEnd = async (phoneNumber, noCheck) => {
    if (noCheck) {
      phoneNumber = this.state.phoneNumber;
    } else {
      const newClient = !(await getIsMember(phoneNumber));
      this.setState({
        phoneNumber,
        newClient,
      })

      if (newClient) {
        if (this.tabletSubscription || !this.isSponsored) {
          this.goToScreen(4);
          return;
        } else {
          const body = {
            phone: phoneNumber
          }
          loginWithUserPhone(body, result => {
            this.setState({validationId: result.validationRequestId})
            this.goToScreen(3);
          }, () => {})
          return;
        }
      }
    }

    //TODO if sponsor mode required => /login api call + phone validate else go to  https://cibler.io/api/bienvenus/login?customerId
    postMember({
        phone: phoneNumber,
      },
      (result) => {
      result.phoneNumber=phoneNumber;
        this.setState({clientData: result});
        if (this.state.magasinLite){
          this.goToScreen(6);
        }else{
          this.goToScreen(5);
        }
      }, r => {
        alert('error')
        console.log(r)}
    );
  }
  back = (previous) => {
    this.goToScreen(previous.screen);
  }

  backAndResetState = () => {
    if (this.state.magasinLite)
      this.initialState.magasinLite = true;
    this.initialState.screen = 2;
    this.setState(this.initialState)
  }

  validateCodeInput = (code) => {
    //TODO api validate code input https://cibler.io/api/bienvenus/validate
    validatePhoneCode({
        phone: this.state.phoneNumber,
        validationKey: code,
        validationRequestId: this.state.validationId,
      },
      (result) => {
        this.setState({clientToken: result, validationError: false})
        if (this.state.newClient) {
          this.goToScreen(4);
        } else {
          this.onPhoneInputEnd(null, true);
          this.goToScreen(5);
        }
      }, r => {
        this.setState({validationError: true})
        console.log(r)}
    );

  }

  consentBtn = () => {
    this.onPhoneInputEnd(null, true);
    this.goToScreen(5);
  }

  getGifts = () => {
    authCall('api/sellers/user/gifts?eligibleOnly=true',
      {
        method: 'POST',
        body: this.state.clientData.userId
      },
      (result) => {
        this.setState({giftList: result});
      }, (r) => {
        console.log(r)
      })
  }

  validateTransactionAmount = (transactionAmount)=>{
    this.setState({transactionAmount})
    this.goToScreen(6);
  }

  proceedCoupons = (activeGift,giftCode) => {
    this.setState({activeGift,giftCode})
    this.goToScreen(7);
  }

  transactionProceed = (totalSpent) =>{
    const now = new Date();
    const body = {
      giftId: this.state.activeGift.id ,
      orderInfo: {
        clientGUID: this.state.clientData.userId,
        giftCode:this.state.giftCode ? this.state.giftCode:null,
        orderDate: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
        orderLines: [],
        totalSpent,
      },
      userId: this.state.clientData.userId,
    }

    authCall('api/seller/postorder',
      {
        method: 'POST',
        body: JSON.stringify(body)
      },
      (r) => {
        console.log(r);
        this.goToScreen(8)
      },
      () => {}
      )
  }

  render() {
    const screens = [
      () => <MagasinInit setIsSeller={this.setIsSeller} />,
      () => <MagasinDialpad onPhoneInputEnd={this.onPhoneInputEnd} />,
      () => <MagasinValidate {...this.state} goBack={this.back} validateCodeInput={this.validateCodeInput} />,
      () => <MagasinHome {...this.state} goBack={this.back} consentBtn={this.consentBtn} />,
      () => <MagasinTransaction {...this.state} onLoad={this.getGifts} goBack={this.back} validateTransactionAmount={this.validateTransactionAmount} />,
      () => <MagasinGifts {...this.state} goBack={this.back} backAndResetState={this.backAndResetState} proceedCoupons={this.proceedCoupons} />,
      () => <MagasinSummary {...this.state} goBack={this.back} transactionProceed={this.transactionProceed} />,
      () => <MagasinFeedback {...this.state} backAndResetState={this.backAndResetState} />
    ]
    return (
      <>
        <div id="page-container">
          <main className="page-container" style={{ height: '100vh' }}>
            {screens[this.state.screen-1]()}
          </main>
        </div>
        {this.state.showControlPopup && <UsedBudgetPopup close={() => this.setState({showControlPopup: false})}/>}
      </>
    )
  }

  goToScreen = screen => this.setState({ screen })
}

export default withRouter(Magasin);

import React, { Component } from 'react';
import AppButton from '../components/AppButton';
import MagasinPointsCard from '../components/MagasinPointsCard';

export default class MagasinSummary extends Component {

    state={
        transactionAmount : this.props.transactionAmount ? this.props.transactionAmount : 0
    }

    transactionProceed = async () => {
        this.props.transactionProceed(this.state.transactionAmount - this.discount());
    }

    discount = () => {
        const amount = this.state.transactionAmount;
        const gift = this.props.activeGift;
       if(!gift || Object.keys(gift).length === 0){
           return 0
       }
        return gift.discountType === 'PERCENT'
          ? (Math.round((amount*(gift.value/100))*100))/100
          : gift.value;
    }

    render() {
        const {activeGift, magasinLite} = this.props;
        const total = this.state.transactionAmount - this.discount();
        const newPoints = total > 0 ? Math.round(total) : 0;
        return (
          <div className="page magasin-transaction magasin-summary" id="magasinSummary">
              <div className="page-content">
                  <section className="hero">
                      <div className="content-wrap">
                          <header className="page-header">
                              <svg xmlns="http://www.w3.org/2000/svg" width="131.738" height="100" viewBox="0 0 131.738 100"
                                   className="icon">
                                  <g id="Groupe_99" data-name="Groupe 99" transform="translate(18 -61)">
                                      <g id="Groupe_100" data-name="Groupe 100" transform="translate(-18 61)">
                                          <g id="logo">
                                              <g id="Groupe_2" data-name="Groupe 2" transform="translate(25.425)">
                                                  <path id="Tracé_217" data-name="Tracé 217"
                                                        d="M180.672,185.844h-17.6a1.117,1.117,0,0,0-1.117,1.117v12.1a1.117,1.117,0,0,0,1.117,1.117h17.6a1.117,1.117,0,0,0,1.117-1.117v-12.1A1.116,1.116,0,0,0,180.672,185.844Zm-16.486,12.1v-9.867h15.369v6.285l-4.634-4.634a1.116,1.116,0,0,0-1.579,1.579l6.213,6.213v.423Z"
                                                        transform="translate(-123.539 -134.619)" fill="#fff" />
                                                  <path id="Tracé_218" data-name="Tracé 218"
                                                        d="M173.242,192.63a1.117,1.117,0,0,0-1.579,1.579l2.782,2.782a1.117,1.117,0,0,0,1.579-1.579Z"
                                                        transform="translate(-126.445 -136.73)" fill="#fff" />
                                                  <path id="Tracé_219" data-name="Tracé 219"
                                                        d="M182.443,192.956h-9.91V171.57a5.559,5.559,0,0,0,3.7-5.046v-4.309a1.1,1.1,0,0,0-.363-.815,1.516,1.516,0,0,0-.2-.464h0l-4.467-6.714a4.042,4.042,0,0,0-3.372-1.806H130.911a4.041,4.041,0,0,0-3.372,1.806l-4.467,6.714a1.505,1.505,0,0,0-.19.416,1.1,1.1,0,0,0-.437.864v4.309A5.532,5.532,0,0,0,126,171.508v21.448H115.544a1.117,1.117,0,0,0,0,2.234h66.9a1.117,1.117,0,0,0,0-2.234ZM174,166.524c0,1.822-1.812,3.306-4.039,3.306s-4.038-1.483-4.038-3.306v-3.193H174Zm-10.311,0c0,1.822-1.812,3.306-4.038,3.306s-4.039-1.483-4.039-3.306v-3.193h8.077Zm-10.311,0c0,1.822-1.812,3.306-4.039,3.306s-4.038-1.483-4.038-3.306v-3.193h8.077Zm-10.311,0c0,1.822-1.812,3.306-4.039,3.306s-4.038-1.483-4.038-3.306v-3.193h8.077Zm9.328-11.875.829,6.449h-7.761l.877-6.449Zm15.435,0a1.812,1.812,0,0,1,1.512.81l3.752,5.639h-7.47l-2.584-6.449Zm-7.2,0,2.584,6.449h-7.685l-.877-6.449ZM143.208,161.1h-7.684l2.584-6.449h5.978ZM129.4,155.459a1.812,1.812,0,0,1,1.512-.81h4.79l-2.584,6.449h-7.469Zm-4.72,11.065v-3.193h8.077v3.193c0,1.822-1.812,3.306-4.038,3.306S124.679,168.347,124.679,166.524Zm9.746,26.432V177.724h7.262v6.5h-.667a1.117,1.117,0,0,0,0,2.234h.667v6.5Zm9.5,0V176.607a1.116,1.116,0,0,0-1.117-1.117h-9.5a1.117,1.117,0,0,0-1.117,1.117v16.349h-3.959V172.042c.161.011.321.022.485.022a6.535,6.535,0,0,0,5.155-2.392,6.751,6.751,0,0,0,10.311,0,6.751,6.751,0,0,0,10.311,0,6.75,6.75,0,0,0,10.311,0,6.536,6.536,0,0,0,5.155,2.392c.115,0,.227-.01.34-.016v20.908Z"
                                                        transform="translate(-108.823 -125.336)" fill="#fff" />
                                                  <path id="Tracé_220" data-name="Tracé 220"
                                                        d="M162.708,220.317a1.117,1.117,0,0,0-1.117-1.117H128.407a1.117,1.117,0,0,0,0,2.234h33.185A1.117,1.117,0,0,0,162.708,220.317Z"
                                                        transform="translate(-112.803 -143.43)" fill="#fff" />
                                                  <path id="Tracé_221" data-name="Tracé 221"
                                                        d="M175.565,227.253H154.676a1.119,1.119,0,0,0,0,2.234h20.889a1.119,1.119,0,0,0,0-2.234Z"
                                                        transform="translate(-120.961 -143.993)" fill="#fff" />
                                                  <path id="Tracé_222" data-name="Tracé 222"
                                                        d="M183.992,141.507,149.943,120a5.94,5.94,0,0,0-6.4,0l-34.048,21.5a7.159,7.159,0,0,0-3.2,6.058v43.007a7.16,7.16,0,0,0,3.2,6.059l34.048,21.5a5.942,5.942,0,0,0,6.4,0l34.048-21.5a7.157,7.157,0,0,0,3.2-6.059V147.565A7.159,7.159,0,0,0,183.992,141.507Zm.964,49.065a4.66,4.66,0,0,1-2.081,3.943l-34.048,21.5a3.865,3.865,0,0,1-4.162,0l-34.048-21.5a4.661,4.661,0,0,1-2.08-3.943V147.565a4.66,4.66,0,0,1,2.08-3.942l34.048-21.5a3.862,3.862,0,0,1,4.162,0l34.049,21.5a4.659,4.659,0,0,1,2.081,3.942Z"
                                                        transform="translate(-106.302 -119.069)" fill="#fff" />
                                              </g>
                                              <path id="Tracé_223" data-name="Tracé 223"
                                                    d="M82.482,178.32l1.491,3.02,3.333.484-2.412,2.351.569,3.319-2.981-1.567L79.5,187.495l.569-3.319-2.412-2.351,3.333-.484Z"
                                                    transform="translate(-77.659 -133.152)" fill="#fff" />
                                              <path id="Tracé_224" data-name="Tracé 224"
                                                    d="M242.975,178.32l1.49,3.02,3.333.484-2.412,2.351.569,3.319-2.981-1.567-2.981,1.567.569-3.319-2.412-2.351,3.333-.484Z"
                                                    transform="translate(-116.061 -133.152)" fill="#fff" />
                                              <g id="Groupe_3" data-name="Groupe 3" transform="translate(30.834 5.891)">
                                                  <path id="Tracé_225" data-name="Tracé 225"
                                                        d="M158.3,139.427h-.634a1.117,1.117,0,1,0,0,2.234h.634a1.117,1.117,0,1,0,0-2.234Z"
                                                        transform="translate(-127.274 -131.265)" fill="#fff" />
                                                  <path id="Tracé_226" data-name="Tracé 226"
                                                        d="M182.155,147.992l-30.93-19.782a3.771,3.771,0,0,0-4.1,0l-30.93,19.782a4.669,4.669,0,0,0-2.052,3.937v39.565a4.67,4.67,0,0,0,2.052,3.938l30.93,19.783a3.772,3.772,0,0,0,4.1,0l30.93-19.782a4.67,4.67,0,0,0,2.053-3.939V151.929A4.669,4.669,0,0,0,182.155,147.992Zm-.182,43.5a2.128,2.128,0,0,1-.936,1.795l-30.93,19.783a1.72,1.72,0,0,1-1.871,0l-30.93-19.783a2.128,2.128,0,0,1-.935-1.795V151.929a2.129,2.129,0,0,1,.935-1.795l17.224-11.016h6.313a1.244,1.244,0,0,0,0-2.474H138.4l9.838-6.292a1.718,1.718,0,0,1,1.871,0l30.93,19.782a2.13,2.13,0,0,1,.935,1.795Z"
                                                        transform="translate(-114.138 -127.603)" fill="#fff" />
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                              <h1>Récapitulatif</h1>
                          </header>
                          <MagasinPointsCard {...this.props.clientData} />
                      </div>
                  </section>
                  <section className="content-wrap hero">
                      <div className="sheet-summary">
                          <div className="summary-line mb-2">
                              <p>Montant de la transaction</p>
                              <p className="amount">
                                  <strong><span id="transactionAmount">{this.state.transactionAmount}</span> €</strong>
                              </p>
                          </div>
                          <div className="summary-line advantagesText">
                              <p>
                                  <small>Avantages :</small>
                              </p>
                          </div>
                          <div id="discountSummary">
                              <div className="summary-line marginTop-3 offer-summary-invoice">
                                  <p>
                                      {activeGift && activeGift.description && activeGift.description}
                                  </p>
                                  <p className="amount">
                                      <strong><span id="offerAmount">- {this.discount()}</span> €</strong>
                                  </p>
                              </div>
                          </div>
                          <div className="summary-line offer-summary-invoice" style={{ marginTop: "10px" }}>
                              <p style={{ fontWeight: "bold" }}>Total</p>
                              <p className="amount">
                                  <strong><span id="totalAmount">{ total}</span> €</strong>
                              </p>
                          </div>
                          <hr className="advantageSeparatorLine" />
                          <p className="points-earned">
                              Nouveaux points : <span id="pointsEarned">{newPoints}</span>
                          </p>
                          <div className="summary-buttons">
                              <AppButton 
                                onClick={() => 
                                this.props.goBack({
                                    screen: 6
                                })}
                                event="Annuler"
                                id="magasinSummaryBackBtn"  className="btn btn-secondary">Annuler</AppButton>
                              <AppButton event="Terminer" className="btn btn-primary" id="transactionComplete" onClick={this.transactionProceed}>Terminer</AppButton>
                          </div>
                      </div>
                      {
                          Object.keys(activeGift).length > 0 &&  <div className="offer-summary" style={{marginBottom: '20px'}}>
                          <div className="card card-offer card-summary" style={{ justifyContent: "start" }}>
                                <div>
                                {activeGift && activeGift.discountType && activeGift.discountType.toLowerCase() == "value"
              ? <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: "5px"}} width="20" height="20"
                     viewBox="0 0 25 25">
                <g>
                  <path fill="#0091ff"
                        d="M17.5 5A12.5 12.5 0 1 0 30 17.5 12.483 12.483 0 0 0 17.5 5zm4.083 17.139c0 .083-.028.139-.028.194a.4.4 0 0 1-.056.167.736.736 0 0 1-.139.167 2.643 2.643 0 0 1-.417.306 6.742 6.742 0 0 1-.778.389 7.909 7.909 0 0 1-1.056.333 4.831 4.831 0 0 1-1.306.139 5.659 5.659 0 0 1-2.222-.417 4.355 4.355 0 0 1-1.694-1.222 5.407 5.407 0 0 1-1.083-1.972 5.482 5.482 0 0 1-.194-.722h-2.471l.472-1.028h1.861c-.028-.306-.056-.611-.056-.944 0-.361.028-.722.056-1.083h-2.333l.472-1.028h2.028a5.067 5.067 0 0 1 .194-.694 5.776 5.776 0 0 1 1.139-2.083 4.99 4.99 0 0 1 1.75-1.306 5.425 5.425 0 0 1 2.25-.444 4.393 4.393 0 0 1 1.056.109c.333.083.667.167.944.25a4.064 4.064 0 0 1 .778.361 3.1 3.1 0 0 1 .472.333c.083.083.139.167.167.194a.4.4 0 0 1 .056.167.542.542 0 0 1 .028.222c0 .083.028.194.028.306a1.145 1.145 0 0 1-.028.333.523.523 0 0 1-.056.222.607.607 0 0 1-.083.139.212.212 0 0 1-.139.056.7.7 0 0 1-.389-.194 3.18 3.18 0 0 0-.639-.417 3.718 3.718 0 0 0-.944-.417 3.754 3.754 0 0 0-1.333-.194 3.427 3.427 0 0 0-1.528.333 3.164 3.164 0 0 0-1.194 1 4.646 4.646 0 0 0-.75 1.583 1.2 1.2 0 0 1-.028.139h4.75l-.472 1.028H14.25c-.028.306-.056.639-.056.972 0 .361.028.722.056 1.056h4.944l-.472 1.028h-4.278v.056a4.273 4.273 0 0 0 .75 1.556 3.283 3.283 0 0 0 1.194.944 3.709 3.709 0 0 0 1.612.305 4.638 4.638 0 0 0 2.306-.611 3.18 3.18 0 0 0 .639-.417.981.981 0 0 1 .389-.194c.056 0 .083 0 .111.028a.383.383 0 0 1 .083.111c.028.056.028.139.056.222a1.358 1.358 0 0 1 .028.361c-.001.111-.029.222-.029.278z"
                        transform="translate(-5 -5)"/>
                </g>
              </svg>
              : <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: "5px"}} width="20" height="20"
                     viewBox="0 0 25 25">
                <path id="Tracé_679" data-name="Tracé 679"
                      d="M26.78,13.25h0a2.534,2.534,0,0,1-.658-2.474V10.75a2.556,2.556,0,0,0-1.816-3.132h0A2.538,2.538,0,0,1,22.489,5.8V5.775a2.556,2.556,0,0,0-3.132-1.816A2.534,2.534,0,0,1,16.883,3.3h0a2.574,2.574,0,0,0-3.633,0h0a2.55,2.55,0,0,1-2.474.658H10.75A2.556,2.556,0,0,0,7.617,5.775h0A2.538,2.538,0,0,1,5.8,7.591H5.775a2.544,2.544,0,0,0-1.816,3.132h0A2.579,2.579,0,0,1,3.3,13.2h0a2.574,2.574,0,0,0,0,3.633A2.534,2.534,0,0,1,3.958,19.3v.026a2.556,2.556,0,0,0,1.816,3.132h0a2.538,2.538,0,0,1,1.816,1.816v.026a2.556,2.556,0,0,0,3.132,1.816h0a2.579,2.579,0,0,1,2.474.658h0a2.574,2.574,0,0,0,3.633,0h0a2.55,2.55,0,0,1,2.474-.658h.026a2.556,2.556,0,0,0,3.132-1.816,2.538,2.538,0,0,1,1.816-1.816h.026a2.556,2.556,0,0,0,1.816-3.132h0a2.579,2.579,0,0,1,.658-2.474h0A2.527,2.527,0,0,0,26.78,13.25ZM9.96,11.723a1.764,1.764,0,1,1,1.764,1.764A1.764,1.764,0,0,1,9.96,11.723Zm2.264,7.713a1.054,1.054,0,0,1-.763.316,1.1,1.1,0,0,1-.763-.316,1.08,1.08,0,0,1,0-1.527L17.909,10.7a1.08,1.08,0,1,1,1.527,1.527Zm6.186.763a1.764,1.764,0,1,1,1.764-1.764A1.764,1.764,0,0,1,18.409,20.2Z"
                      transform="translate(-2.55 -2.55)" fill="#f7b500"></path>
              </svg>
            }
                                </div>
                                <div className="inner-content-wrap">
                                    <p style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "12px" }}></p>
                                    <p className="offer"><strong>{activeGift && activeGift.description && activeGift.description}</strong></p>
                                    {activeGift && activeGift.sponsor && activeGift.sponsor
                                        ? <div className="sponsor-div">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.125" height="15" viewBox="0 0 13.125 15">
                                            <path id="Tracé_666" data-name="Tracé 666"
                                                    d="M29,26v8.563c0,4.125,4.063,5.781,6.563,6.438,2.5-.625,6.563-2.312,6.563-6.437V26Zm8.75,10.156-2.187-1.219-2.187,1.219.5-2.438-1.844-1.687,2.5-.281,1.031-2.281,1.031,2.281,2.5.281L37.25,33.719Zm-3.469-1.219Z"
                                                    transform="translate(-29 -26)" fill="#0091ff"></path>
                                            </svg>
                                            <p className="sponsor-text-custom">Offert
                                            par <strong>{activeGift && activeGift.sponsor && activeGift.sponsor}</strong></p>
                                        </div>
                                        : <p className="sponsor-text-custom">Reduction</p>
                                        }
                                </div>
                            </div>
                      </div>
                      }

                      {
                          magasinLite &&  <div className="i-infoWrapper">             
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.5 0C4.25383 0 0 4.25342 0 9.49959C0 14.7462 4.25383 19 9.5 19C14.7462 19 19 14.7462 19 9.49959C19 4.25342 14.7466 0 9.5 0ZM10.9522 14.6869C10.9522 15.4835 10.2999 16.1291 9.50415 16.1291C8.70795 16.1291 8.05607 15.4835 8.05607 14.6869V8.37704C8.05607 7.58084 8.70795 6.93518 9.50415 6.93518C10.2999 6.93518 10.9522 7.58043 10.9522 8.37704V14.6869ZM9.5 5.74379C8.62128 5.74379 7.90886 5.03136 7.90886 4.15306C7.90886 3.27393 8.62128 2.56233 9.5 2.56233C10.3783 2.56233 11.0911 3.27393 11.0911 4.15306C11.0911 5.03136 10.3783 5.74379 9.5 5.74379Z" fill="white"/>
                          </svg>
                          <p>Le montant de la carte cadeau sera directement ajouté à votre solde</p>
                        </div>
                      }
                   
                  </section>
              </div>
          </div>
        )
    }
}
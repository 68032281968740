import * as React from "react";

function SvgPinHole(props) {
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 93.03 127.03"
      {...props}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Traits_de_coupe" data-name="Traits de coupe">
          <path
            fill={props.color || 'red'}
            d="M46.51,0A46.52,46.52,0,0,0,0,46.51C0,74,46.51,127,46.51,127S93,76,93,46.51A46.52,46.52,0,0,0,46.51,0Zm0,60.42A15.73,15.73,0,1,1,62.24,44.69,15.73,15.73,0,0,1,46.51,60.42Z"/>
        </g>
      </g>
    </svg>
  );
}

export default SvgPinHole;

import React, {useState, useEffect} from "react";
import {clientT, t} from "../../../tools/i18n";
import AppButton from "../../../components/AppButton";
import {authClient} from "../../../tools/apitools";
import Context from "../../../tools/context";
import {siteContext} from "../../../tools/toolbox";
import {withRouter, useHistory} from "react-router-dom";
import moment from "moment";

const ListRefund = () => {
  const [refunds, setRefunds] = useState([]);

  useEffect(async () => {
    try {
      const res = await authClient(
        `api/prizes/refund/${Context.partner.customerId}`,
      );

      setRefunds(res)
      console.log(res)
    } catch (err) {
      console.error(err);
    }
  }, [])

  const getStatusClass = status => {
    switch (status) {
      case 'REFUNDASKED':
        return 'blue';
      case 'REFUNDED':
        return 'green';
      case 'CANCELED':
        return 'red';
      default:
        return 'red';
    }
  }

  return (
    <>
      <div className="Refund List">
        <div className="title">{clientT('refund.list.title')}</div>
        {refunds.length
          ? refunds.map((ref) => ref.withdraw && (
            <div className={`refund ${getStatusClass(ref.withdraw.status)}`}>
              <div className="label">
                <span>{clientT(`refund.list.status.${ref.withdraw.status}`)}</span>
              </div>
              <div className={"infos"}>
                <div className='info'>
                  <span>{clientT('refund.list.number')} :</span> {ref.withdraw.id}
                </div>
                <div className='info'>
                  <span>{clientT('refund.list.date')} :</span> {moment(ref.withdraw.creationDate).format('DD/MM/YYYY')}
                </div>
                <div className='info'>
                  <span>{clientT('refund.list.amount')} :</span> {ref.withdraw.amount}€
                </div>
                <div className='info'>
                  <span>{clientT('refund.list.seller')} :</span> {ref.withdraw.sellerName}
                </div>
              </div>
            </div>
          ))
          : <div className="bigText" style={{marginTop: '20px'}} dangerouslySetInnerHTML={{__html: clientT('refund.list.noRefund')}}>
            </div>
        }
      </div>
    </>
  );
}

export default withRouter(ListRefund);
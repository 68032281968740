import React from 'react';
import Context from '../../tools/context';
import GenericFooter from './GenericFooter';

const Footer = () => {
  if (Context.partner.footer)
    return <GenericFooter />
  else
    return null;
};

export default Footer;

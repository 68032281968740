import React, {Component} from 'react';
import AppButton from '../components/AppButton';
import AppLink from '../components/AppLink';
import { Context } from '../tools/context';

export default class MagasinHome extends Component {
  render() {
    const {phoneNumber, isSeller, goBack, consentBtn} = this.props;
    return (
      <div className="page magasin-home" id="magasinHome">
        <div className="page-content">
          <section>
            <div className="content-wrap">
              <header className="magasin-icon-close-div">
                <AppLink className="btn btn-link" href="/dashboard-new">
                  <span className="icon icon-close"/>
                </AppLink>
              </header>
              <div className="shop-icon shop-icon-div">
                <img
                  src="/assets/tcaAssets/18103806/Draw_Congrats.png"/>
                <div className="shop-icon-text-div">
                  <h2 className="dataUserText">{isSeller ? "Bienvenue parmi nous !" : "Nouveau client détecté !"}</h2>
                </div>
              </div>
            </div>
          </section>
          <section className="content-wrap">
            <div className="form-group white-text">
              <label htmlFor="phoneInput"
                     className="phoneInputLabelTxt magasin-home-text-12">{isSeller ? "Votre numéro de téléphone" : "Numéro de téléphone du client"}</label>
              <div className="input-group">
                <input type="tel" defaultValue={phoneNumber} className="form-control" placeholder="00 00 00 00 00"/>
                <span className="icon icon-clear" id="clear"/>
              </div>
              <div className="back-to-init-div magasin-home-text-12">
                <AppLink href="#" style={{color: "white", textDecoration: "underline", fontWeight: "300", cursor: "pointer"}}
                   onClick={() => 
                    goBack({
                      screen: 2,
                    })
                   }
                   className="back-to-init-btn">J’ai fait une erreur</AppLink>
              </div>
            </div>
            <div className="form-group radio-check-div">
                  <span className="check-main-block">
                    <label>
                      <span
                        className="checklist-checkboxtext">{isSeller ? `J’accepte de recevoir les meilleures offres de ${Context.seller.name}` : "J’ai l’autorisation de créer ce compte"}</span>
                      <input type="radio" className="checklist-checkbox" id="phoneAuthorize"/>
                      <span className="checkmark"/>
                    </label>
                  </span>
            </div>

            <div className="consent-btn-div">
              <AppLink href="#" onClick={consentBtn}>
                <AppButton className="consent-btn">Oui c’est correct !</AppButton>
              </AppLink>
            </div>
          </section>
        </div>

      </div>
    )
  }
}

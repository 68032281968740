import React, {useState} from 'react';
import "../../style/clientLayout.scss"
import { sellerLoggedIn } from '../../tools/toolbox';
import {Context} from "../../tools/context";
import {useHistory} from "react-router-dom";



const ClientLayout = ({children}) => {
  const [show, toggleMenu] = useState(false)
  const history = useHistory()
  if(!sellerLoggedIn()){
    history.push('/connexion');
    return <div/>
  }else{
    return (
        <div className="seller-layout">

          {children}
        </div>
    )
  }
}

export default ClientLayout
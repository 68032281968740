import React, {Component} from 'react';
import '../../style/connexionClient.scss';
import {loginWithUserPhone, reverseLogin, validatePhoneCode} from '../../tools/apitools'
import {Context, ContextUtil} from '../../tools/context';
import {customer, siteContext, saveContext, setCookie, getQueryString} from '../../tools/toolbox';
import { withRouter} from "react-router-dom";
import PhoneField from "../../components/formField/PhoneField";
import TicketsPage from "./TicketsPage";
import {popupTypes} from "../../Utils/Constants";
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import AppButton from '../../components/AppButton';
import i18n, {clientT, t} from "../../tools/i18n";

class ConnexionClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNo: '',
      validationCode: '',
      enableValidationWrapper: false,
      newUser: false,
      validationRequestId: '',
      showReversePopup: false,
      reverseCode: '',
      errorPopup: false,
      userId: '',
    }
  }

  componentDidMount = ()=>{
    if(siteContext().clientToken){
      this.redirect();
    }
  }
  submitPhoneNumber = () => {
    const {phoneNo, validationCode} = this.state;
    const loginData = {
      id: phoneNo.number,
      phone: phoneNo.formatted,
      validationKey: validationCode
    };
    loginWithUserPhone(loginData, data => {
      this.setState({
        newUser: data?.newUser,
        validationRequestId: data?.validationRequestId,
        enableValidationWrapper: true,
        userId: data?.userId
      });
    }, (e) => {
      this.setState({loginError: true})
    })
  }

  submitValidate = (code, callback) => {
    const {phoneNo, validationCode, validationRequestId} = this.state;
    validatePhoneCode({
        customerId: customer(),
        id: phoneNo.number,
        phone: phoneNo.formatted,
        validationKey: code || validationCode,
        validationRequestId,
        reverseLogin: !!code,
      },
      (result) => {
        callback && callback();
        ContextUtil.update('clientToken', result);
        siteContext().clientToken = result;
        siteContext().userID = this.state.userId;
        saveContext();
        setCookie('loginToken', result, 7);
        this.redirect();
      }, e => {
        if (callback) return;
        this.setState({errorPopup: true})
      }
    );
  }

  submitReverse = async () => {
    const {phoneNo, validationCode} = this.state;
    const body = {
      id: phoneNo.number,
      phone: phoneNo.formatted,
      validationKey: validationCode,
      reverseLogin: true,
    };

    const code = await reverseLogin(body);

    if (!code) return this.setState({errorPopup: true});

    this.setState({showReversePopup: true, reverseCode: code})

    const inter = setInterval(() => {
      this.submitValidate(code, () => {
        clearInterval(inter);
      })
    }, 3000)
    setTimeout(() => {
      clearInterval(inter);
    }, 600000)
  }

  redirect = () => {
    if (getQueryString('redirect')) {
      window.location = `${Context.partner?.clientNavigation?.homeUrl || ''}${decodeURIComponent(getQueryString('redirect'))}`;
    }

    !this.state.newUser ? this.props.history.replace('/portefeuille') : this.props.history.replace('profile-client')
  }

  handleInput = (validate) => (e) => {
    if (e.key !== 'Enter') return;
    if (!this.state.phoneNo.valid) return;

    if (validate) {
      this.submitValidate();
    } else {
      this.submitPhoneNumber();
    }
  }

  render() {
    const {phoneNo, validationCode, enableValidationWrapper} = this.state;
    const footer = Context.partner?.client?.connectionFooter ?
      <div className="connexionclient__footerWrapper"
           dangerouslySetInnerHTML={{__html: Context.partner.client.connectionFooter}}/>
      : '';
    const steps = clientT('clientConnexion.steps');
    return (
      <div className="connexionclient__backgroundBlock"
           style={Context.partner?.client?.connexionBackground ? {
        // backgroundBlendMode: 'darken',
        backgroundImage: `url('${Context.partner?.client?.connexionBackground}')`,
        // backgroundSize: 'cover',
        // backgroundPosition: '50%',
        // backgroundColor: 'rgba(0,0,0,0.5)',
      } : {}}>
      <div className={`main connexionclient__mainWrapper`}>
        {/*{Context.partner?.client?.connexionBackground ? 'withBackground' : ''}`}>*/}
        <p className="connexionclient__mainHeading">{t('clientConnexion.title')}</p>
        <hr className="connexionclient__horizontalBar"/>
        <div className={`connexionclient__stepsContainer ${Context.partner?.platform === 'ticketcommercant' && 'ticketCo'}`}>
          {
            (steps.map ? steps : [steps]).map((text, index) => {
              return <div key={index}>
                <p>{index + 1}</p>
                <p className="stepLabel">{text}</p>
              </div>
            })
          }
        </div>
        <div className="connexionclient__loginForm">
          <label htmlFor="" dangerouslySetInnerHTML={{__html: clientT('clientConnexion.instructions')}} />
          <div className="formControl" style={{display: 'flex', alignItems: 'center', background: '#fff'}}>
            <PhoneField
              fieldOnly
              onKeyPress={this.handleInput()}
              handleChange={(e) => {
                this.setState({phoneNo: e.target.value})
              }}
              id="phoneNo"
              value={phoneNo}
            />
          </div>
          <AppButton event="Recevoir un code par SMS" client id="submitPhoneNo" className="formControl mainButton" onClick={this.submitPhoneNumber}
                  style={{opacity: `${!phoneNo.valid ? "0.5" : ''}`}} disabled={!phoneNo.valid}>{t('clientConnexion.recieveCode')}
          </AppButton>
          <div style={{visibility: enableValidationWrapper ? 'visible' : 'hidden'}}>
            <input id="validationCode" className="formControl validationCode" type="text"
                   onKeyPress={this.handleInput(true)}
                   placeholder={t('clientConnexion.validationCode')} value={validationCode} onChange={(e) => {
              this.setState({validationCode: e.target.value})
            }}/>
            <AppButton event="Connexion" client id="submitValidationCode" className="formControl mainButton" onClick={() => this.submitValidate()}
                    style={{opacity: `${!validationCode ? "0.5" : ''}`}} disabled={!validationCode}>{t('common.login')}
            </AppButton>
            <AppButton
              className="reverseLoginBtn"
              event="Je n'ai pas reçu mon code"
              client
              onClick={this.submitReverse}>{t('clientConnexion.notRecieved')}</AppButton>
          </div>
          {this.state.showReversePopup && <TicketsInfoPopup
            type={popupTypes.reverseLogin}
            replaceKeys={[{k: 'code', v: this.state.reverseCode}]}
            close={() => {this.setState({showReversePopup: false})}}
          />}
        </div>
        {this.state.errorPopup && <TicketsInfoPopup
          type={popupTypes.loginError}
          close={() => {this.setState({errorPopup: false})}}
        />}
        {footer}
      </div>
      </div>
    )
  }
}

export default withRouter(ConnexionClient);
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import {siteContext} from "../tools/toolbox";

class DefaultPage extends Component {
  getRedirection = () => {
    if (siteContext().clientToken) return '/portefeuille';
    if (siteContext().accessToken) return '/home';
    if (!this.props.redirect) return '/landing';

    if (document.location.host.includes('pro.')) {
      return '/connexion';
    }

    return '/connexion-client';
  }

  componentDidMount() {
    if (!this.props.loaded) return;
    this.props.history.replace(`${this.getRedirection()}${document.location.search}`);
  }

  componentDidUpdate() {
    if (!this.props.loaded) return;
    this.props.history.replace(`${this.getRedirection()}${document.location.search}`);
  }

  render() {
        return (
            <div>
              {/* This page does not exist */}
            </div>
        );
    }
}

export default withRouter(DefaultPage);
import React, {Component} from "react";
import Script from 'react-load-script';
import {withRouter} from "react-router-dom";
import '../style/menu.scss';
import {Context} from '../tools/context'
import {logout} from "../tools/toolbox";
import AppButton from "./AppButton";
import AppLink from "./AppLink";
import {t} from '../tools/i18n';
import {siteContext} from "../tools/toolbox";
import {authCall} from "../tools/apitools";
class Menu extends Component {
  constructor(props) {
    super(props);

    this.routes = [
      {path: '/dashboard-new'},
      {path: '/home'},
      {path: '/profile'},
      {path: '/mes-clients'},
      {path: '/mon-solde'},
      {path: '/mes-avantages'},
      {path: '/magasin-home'},
    ]
    if (Context.partner.navigation && Context.partner.navigation.faq) {
      this.routes.push({
        path: Context.partner.navigation.faq,
        key: 'faq',
        external: ! Context.partner.navigation.faq.startsWith("/"),
        subHeading: true,
      })
    }
    if (Context.partner.navigation && Context.partner.navigation.contact) {
      this.routes.push({
        path: Context.partner.navigation.contact,
        key: 'contact',
        external: true,
        zendesk: Context.partner.features && Context.partner.features.zendesk,
        subHeading: true,

      })
    }


    if (Context.role?.ROLE_BACKUSER) {
      this.routes = this.routes.concat([
        {path: '/adminPage'},
      ]);
    }

    if (Context.partner.navigation) {
      this.routes = this.routes.concat(Context.partner.navigation.add || []);
      (Context.partner.navigation.remove || []).forEach(r => {
        const index = this.routes.findIndex(p => p.path === r);
        if (index >= 0) {
          this.routes.splice(index, 1);
        }
      })
    }

    this.alts = siteContext().alts?.filter((alt, index, self) =>
      index === self.findIndex((t) => (
        t.id === alt.id
      ))
    )
  }

  componentDidMount(){
    const iframeTimeout = setInterval(function(){
      if (window.zE){
        window.zE('webWidget', 'hide');
        clearInterval(iframeTimeout);
      }
    }, 500);
  }

  handleDeconnection = () => {
    logout();
    this.props.history.push('/connexion');
  }

  activateZendesk = () => {
    window.zE.activate({
      hideOnClose: true
    });
  }

  handleState = (open) => {
    if (this.props.onChange)
      this.props.onChange(open)
  }

  render() {
    return (
        <div className="menu" key="menu">
          <AppButton className="burgerIcon" onClick={() => this.handleState(!this.props.open)}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'>
              <defs/>
              <path d='M0 14v-2h10v2zm0-6V6h16v2zm0-6V0h16v2z'/>
            </svg>
          </AppButton>
          <div className="burgerMenu" style={
            this.props.open ? {transform: 'translateX(0)'} : {}}>
            <AppButton className="close" onClick={() => this.handleState(!this.props.open)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='21.936' height='21.936'
                viewBox='0 0 21.936 21.936'>
                <g id='Close' transform='translate(1 1)'>
                  <path id='Path' d='M0,0,5.981,5.981' transform='translate(6.978 6.978)' fill='none'
                        strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='2'/>
                  <path id='Path-2' data-name='Path' d='M0,5.981,5.981,0' transform='translate(6.978 6.978)' fill='none'
                        strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='2'/>
                </g>
              </svg>
            </AppButton>
            <div className="infoBlock">
              {Context.seller && Context.seller.id ?
                <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
                      <image xlinkHref={`https://cdn.cibler.io/seller/${Context.seller.id}/storeimage.png`} x="-25"
                             width="150"/>
                    </pattern>
                  </defs>
                  <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img)"/>
                </svg>
                :
                <img
                  src="/assets/tcaAssets/25124433/facade-ceramique-9A.png"
                  className="sideMenuImg"/>
              }
              {/* <img
              src="/assets/side_menu_header_icon.png"
              className="sideMenuImg"
            /> */}
              <div className="sideMenuHeading">{Context.seller && Context.seller.name}</div>
            </div>
            {this.alts && this.alts.map &&
              <div className="sellerSwitch">
                <select
                    className='sellerSelect'
                    defaultValue={Context.seller.name}
                    onChange={(e) => this.switchSeller(e.target.value)}
                >
                  {this.renderSellerOptions()}
                </select>
              </div>
            }
            <div className="navBlock">
              {this.routes.map((r, i) => (
                <div className={`navLink`} key={r.path + i}>
                  {r.external && r.zendesk
                    ? <AppLink onClick={r.zendesk && this.activateZendesk} href={r.path}
                         className={`${r.subHeading ? 'sideMenuSubLink' : 'null'} `}
                         target={"_blank"}>{t(`menu.${r.key || r.path}`, {fallback: r.label})}</AppLink>
                    : <AppLink target={r.external &&  "_blank"} href={r.path}>{t(`menu.${r.key || r.path}`, {fallback: r.label})}</AppLink>
                  }
                </div>
              ))}

              {/* <div className="sideMenuSubLink">Une question, une remarque ?</div>
            <div className="sideMenuSubLink">Comment ça marche ?</div> */}

              <div className="sideMenuFooter">
                <AppLink href="#" className="disconnect" onClick={this.handleDeconnection}>
                  {t('menu.logout')}
                </AppLink>
                {Context.partner.navigation && Context.partner.navigation.cgu &&
                <AppLink className="disconnect"
                   target='_blank'
                   href={Context.partner.navigation.cgu}>{t('cgu')}</AppLink>
                }
                {/*<Trans i18nKey={'menu.part2'} components={{applink: <AppLink/>}} />*/}
              </div>
            </div>
          </div>
        </div>
    );
  }

  renderSellerOptions = () => {
    if (!this.alts || !this.alts.map)
      return null;
    return this.alts.map((alt) =>
    <option className="sellerOption" label={alt.name} key={alt.id}>{alt.id}</option>
    );
  }

  switchSeller(sellerId) {
    authCall(
        `api/sellers/switch/${sellerId}`,
        {},
        (result) => {
          document.location.reload()
        },
        () => {}
    );
  }
}

export default withRouter(Menu)

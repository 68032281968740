import React, {Component} from 'react';
import '../../style/ticketConsumer.scss';
import {Context} from "../../tools/context";
import {authClient} from '../../tools/apitools';
import InfoPicto from "../../components/InfoPicto";
import AppLink from '../../components/AppLink';
import {clientT, t} from "../../tools/i18n";
import {track} from "../../tools/tracking";
import {getQueryString} from '../../tools/toolbox';

class TicketConsumer extends Component {
  constructor() {
    super();
    this.state = {partnerCode: '', partnerName: '', partnerError: ''};
  }

  componentDidMount() {
    let {selectedTickets, shortcode} = this.props;

    let newShortCode = getQueryString('shortcode');
    if (newShortCode) {
      shortcode = newShortCode;
    }

    if (shortcode) {
      this.handleInput({target: {value: shortcode}});
    }

    if (!Array.isArray(selectedTickets)) return;
    track('viewTicketConsumer', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
      shortcode: shortcode
    });
  }

  onSellerDataResponse = (res) => {
    this.setState({partnerName: res.name, partnerError: ''});
    this.props.dispPopup(true, this.state.partnerCode, res.name);
  }

  onSellerDataError = (err) => {
    this.setState({partnerError: clientT('myTickets.step2.error')})
  }

  handleInput = async (event) => {
    let res;
    const value = event.target.value.toUpperCase();

    event.target.value = value;
    this.setState({partnerCode: value});

    if (value.length === 6 || value.length === 7) {
      authClient(`api/public/seller/data/${value}`, {method: 'GET'}, this.onSellerDataResponse, this.onSellerDataError)
    } else {
      this.setState({partnerName: '', partnerError: ''});
      this.props.dispPopup(false);
    }
  }

  render() {
    const {selectedTickets} = this.props;
    const {partnerError} = this.state;
    const redirectionLink = (Context.partner.client?.sellerListRedirection || '')
      .replace('{{coupon}}', selectedTickets[0].prize);
    const infoPopupBtn = Context.partner.infoBurnPopup
    return (
      <div className='tickets-consumer'>
        {Context.partner?.client?.consumerTitle ?
          <p dangerouslySetInnerHTML={{__html: Context.partner?.client?.consumerTitle}}></p> : <>
        <h1 className='tickets-consumer-title'>{clientT('myTickets.step2.title')}</h1>
          <p>{clientT('myTickets.step2.subtitle')}</p></>}
        <div className="ticket">
          <input
            maxLength="7"
            type="text"
            value={this.state.partnerCode}
            placeholder={clientT('myTickets.step2.placeholder')}
            onChange={(event) => {
             this.handleInput(event)
            }}
            onFocus={(e) => setTimeout(
             () => e.target.scrollIntoView({behavior: 'smooth'}), 500)}
          />
          {infoPopupBtn && <div className="ticketInfoWrapper" onClick={() => {
            this.props.infoPopup()
          }}>
            <InfoPicto height='13px' width='13px' style={{marginRight: '2px'}}/>
            <span>Plus d’infos </span>
          </div>}
        </div>
        {partnerError && <div className="ticket-detail">
          <p>{partnerError}</p>
        </div>}
        {redirectionLink && <div style={{marginTop: '40px'}}>
          <InfoPicto height={'20px'} width={'20px'} style={{marginRight: '5px', marginBottom: '-5px'}}/>
          <AppLink
            client
            className="ticket-redirection"
            href={redirectionLink}>{t('myTickets.step2.redirection')}
          </AppLink>
        </div>}
      </div>
    )
  }
}

export default TicketConsumer;

import React from 'react';
import { Context } from '../../tools/context';
import '../../style/home.scss';
import DefaultPage from "../DefaultPage";
import LandingTicket from "./LandingTicket";


const Landing = (props) => {
  if (Context.partner.platform === 'tourismebyca') {
      return <DefaultPage redirect loaded />
  }

  if(Context.partner.customLanding){
      window.location.href=Context.partner.customLanding;
  }

  return <LandingTicket {...props} platform={Context.partner.platform}/>
}

export default Landing;

import React, {Component} from 'react';
import QrReader from 'react-qr-reader';
import {authCall} from "../tools/apitools";
import AppButton from './AppButton';

export default class QrScanner extends Component {

  state = {
    result: "",
    qrOpen: false,
    codeInput: "",
    giftData: null,
    errorMessage: null,
    scaned: false,
  }

  handleScan = data => {
    if (data) {
      console.log("Message " + data);
      let regex =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
      if (regex.test(data)) {
        // window.location.href = `${data}&from=bvn&accesstoken=${"sellerData.vendorUuid"}`;
        var url = new URL(data);
        var couponId = url.searchParams.get("coupon");
        if(couponId == null){
          couponId = url.searchParams.get("code");
        }
        console.log("QR INPUT", couponId);

        if (couponId) {
          this.setState({
            qrOpen: false,
            codeInput: couponId,
            errorMessage: null,
            scaned: true,
          }, () => {
            this.fetchCoupon();
          });

          return;
        }
      }

      this.setState({
        qrOpen: false,
        scaned: true,
        errorMessage: 'Le QR code n\'est pas reconnu.',
      })
    }
  }
  handleError = err => {
    console.error(err)
  }

  fetchCoupon = () => {
    authCall(
      `api/coupon/${this.state.codeInput}`,
      {},
      async (result) => {
        console.log("OFFERS RESPONSE", result);
        if (result) {
          if (result.used) {
            this.setState({errorMessage: "Ce coupon a déjà été utilisé. Retrouvez dans Mon Solde > Historique d'encaissement, le détail des coupons reçus.Vous pourrez identifier la date de remise, le code du coupon ainsi que le numéro de téléphone associé au coupon."})
          } else {
            this.setState({
              giftCode: this.state.codeInput,
              giftData: result,
            });
          }
        } else {
          this.setState({errorMessage: 'Ce code n’est pas reconnu par nos services'});
        }
      },
      () => {
        this.setState({errorMessage: 'Ce code n’est pas reconnu par nos services'});
      }
    );
  }

  transactionProceed = () => {
    this.props.proceedQrCoupon(this.state.giftData, this.state.giftCode);
  }

  clearInput = () => {
    this.setState({codeInput: "", scaned: false, errorMessage: null})
  }

  scanFeedback = () => {
    const {giftData, errorMessage, scaned} = this.state;
    const {validateCoupon} = this.props;

    if (errorMessage) {
      return (
        <div style={{padding: "16px", position: "fixed", bottom: "150px", width: "100%"}}>
          <div className={`card card-offer`}>
            <div>
              <p className="offer"><strong>{errorMessage}</strong></p>
            </div>
            <div>
              <div className="times-cust">
                <i className="fa fa-times"/>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (!validateCoupon || !scaned) return null;

    return (
      <div style={{padding: "16px", position: "fixed", bottom: "150px", width: "100%"}}>
        <div data-campaignid={giftData && giftData.campaignId && giftData.campaignId}
             data-threshold={giftData && giftData.threshold && giftData.threshold}
             data-thresholdtype={giftData && giftData.thresholdType && giftData.thresholdType}
             className={`card card-offer ${giftData && giftData.paused ? "inactive" : ""} `}>
          <div>
            {giftData && giftData.discountType && giftData.discountType.toLowerCase() == "value"
              ? <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: "5px"}} width="20" height="20"
                     viewBox="0 0 25 25">
                <g>
                  <path fill="#0091ff"
                        d="M17.5 5A12.5 12.5 0 1 0 30 17.5 12.483 12.483 0 0 0 17.5 5zm4.083 17.139c0 .083-.028.139-.028.194a.4.4 0 0 1-.056.167.736.736 0 0 1-.139.167 2.643 2.643 0 0 1-.417.306 6.742 6.742 0 0 1-.778.389 7.909 7.909 0 0 1-1.056.333 4.831 4.831 0 0 1-1.306.139 5.659 5.659 0 0 1-2.222-.417 4.355 4.355 0 0 1-1.694-1.222 5.407 5.407 0 0 1-1.083-1.972 5.482 5.482 0 0 1-.194-.722h-2.471l.472-1.028h1.861c-.028-.306-.056-.611-.056-.944 0-.361.028-.722.056-1.083h-2.333l.472-1.028h2.028a5.067 5.067 0 0 1 .194-.694 5.776 5.776 0 0 1 1.139-2.083 4.99 4.99 0 0 1 1.75-1.306 5.425 5.425 0 0 1 2.25-.444 4.393 4.393 0 0 1 1.056.109c.333.083.667.167.944.25a4.064 4.064 0 0 1 .778.361 3.1 3.1 0 0 1 .472.333c.083.083.139.167.167.194a.4.4 0 0 1 .056.167.542.542 0 0 1 .028.222c0 .083.028.194.028.306a1.145 1.145 0 0 1-.028.333.523.523 0 0 1-.056.222.607.607 0 0 1-.083.139.212.212 0 0 1-.139.056.7.7 0 0 1-.389-.194 3.18 3.18 0 0 0-.639-.417 3.718 3.718 0 0 0-.944-.417 3.754 3.754 0 0 0-1.333-.194 3.427 3.427 0 0 0-1.528.333 3.164 3.164 0 0 0-1.194 1 4.646 4.646 0 0 0-.75 1.583 1.2 1.2 0 0 1-.028.139h4.75l-.472 1.028H14.25c-.028.306-.056.639-.056.972 0 .361.028.722.056 1.056h4.944l-.472 1.028h-4.278v.056a4.273 4.273 0 0 0 .75 1.556 3.283 3.283 0 0 0 1.194.944 3.709 3.709 0 0 0 1.612.305 4.638 4.638 0 0 0 2.306-.611 3.18 3.18 0 0 0 .639-.417.981.981 0 0 1 .389-.194c.056 0 .083 0 .111.028a.383.383 0 0 1 .083.111c.028.056.028.139.056.222a1.358 1.358 0 0 1 .028.361c-.001.111-.029.222-.029.278z"
                        transform="translate(-5 -5)"/>
                </g>
              </svg>
              : <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: "5px"}} width="20" height="20"
                     viewBox="0 0 25 25">
                <path id="Tracé_679" data-name="Tracé 679"
                      d="M26.78,13.25h0a2.534,2.534,0,0,1-.658-2.474V10.75a2.556,2.556,0,0,0-1.816-3.132h0A2.538,2.538,0,0,1,22.489,5.8V5.775a2.556,2.556,0,0,0-3.132-1.816A2.534,2.534,0,0,1,16.883,3.3h0a2.574,2.574,0,0,0-3.633,0h0a2.55,2.55,0,0,1-2.474.658H10.75A2.556,2.556,0,0,0,7.617,5.775h0A2.538,2.538,0,0,1,5.8,7.591H5.775a2.544,2.544,0,0,0-1.816,3.132h0A2.579,2.579,0,0,1,3.3,13.2h0a2.574,2.574,0,0,0,0,3.633A2.534,2.534,0,0,1,3.958,19.3v.026a2.556,2.556,0,0,0,1.816,3.132h0a2.538,2.538,0,0,1,1.816,1.816v.026a2.556,2.556,0,0,0,3.132,1.816h0a2.579,2.579,0,0,1,2.474.658h0a2.574,2.574,0,0,0,3.633,0h0a2.55,2.55,0,0,1,2.474-.658h.026a2.556,2.556,0,0,0,3.132-1.816,2.538,2.538,0,0,1,1.816-1.816h.026a2.556,2.556,0,0,0,1.816-3.132h0a2.579,2.579,0,0,1,.658-2.474h0A2.527,2.527,0,0,0,26.78,13.25ZM9.96,11.723a1.764,1.764,0,1,1,1.764,1.764A1.764,1.764,0,0,1,9.96,11.723Zm2.264,7.713a1.054,1.054,0,0,1-.763.316,1.1,1.1,0,0,1-.763-.316,1.08,1.08,0,0,1,0-1.527L17.909,10.7a1.08,1.08,0,1,1,1.527,1.527Zm6.186.763a1.764,1.764,0,1,1,1.764-1.764A1.764,1.764,0,0,1,18.409,20.2Z"
                      transform="translate(-2.55 -2.55)" fill="#f7b500"></path>
              </svg>
            }
          </div>
          <div className="inner-content-wrap">
            <p style={{color: "rgba(0, 0, 0, 0.85)", fontSize: "12px"}}></p>
            <p className="offer"><strong>{giftData && giftData.description && giftData.description}</strong></p>
            {giftData && giftData.sponsor && giftData.sponsor
              ? <div className="sponsor-div">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.125" height="15" viewBox="0 0 13.125 15">
                  <path id="Tracé_666" data-name="Tracé 666"
                        d="M29,26v8.563c0,4.125,4.063,5.781,6.563,6.438,2.5-.625,6.563-2.312,6.563-6.437V26Zm8.75,10.156-2.187-1.219-2.187,1.219.5-2.438-1.844-1.687,2.5-.281,1.031-2.281,1.031,2.281,2.5.281L37.25,33.719Zm-3.469-1.219Z"
                        transform="translate(-29 -26)" fill="#0091ff"></path>
                </svg>
                <p className="sponsor-text-custom">Offert
                  par <strong>{giftData && giftData.sponsor && giftData.sponsor}</strong></p>
              </div>
              : <p className="sponsor-text-custom">Reduction</p>
            }
          </div>
          <div style={{marginLeft: "auto"}}
               className={`${giftData && giftData.threshold > 0 ? "points-required" : ""}`}>
            <AppButton onClick={this.transactionProceed} className="btn btn-inline btn-secondary offer-btn"
                    data-sponsor={giftData && giftData.sponsor || false} data-apply=""
                    data-id={`${giftData && giftData.sponsor ? giftData.prize : "giftData.id"}`}
                    event="Appliquer">
              Appliquer
            </AppButton>
          </div>
        </div>
      </div>
    )
  }

  handleClickSearchCoupon = () => {
    if (this.state.scaned) {
      this.clearInput();
      return;
    }

    this.setState({
      errorMessage: null,
      scaned: true,
    });
    this.fetchCoupon();
  }

  render() {
    const {codeInput, qrOpen, scaned} = this.state;
    const {validateCoupon} = this.props;
    return (
      <div id="popupCancel" className="qrCodeReader">
        <div className="qrCodeWrapper">
          <h3 className="qrHeading">Scannez le QR code</h3>
          <span onClick={() => {
            this.props.closeQrPopup()
          }} className="icon icon-close" id="sidebarClose" />
        </div>
        {
          qrOpen ?
            <div id="qrScanner" style={{position: 'absolute', bottom: 0, top: 0, margin: 'auto', maxHeight: '600px', width: '100%'}}>
              <QrReader
                id="qrReader"
                delay={100}
                facingMode={'environment'}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{width: '100%'}}
              />
            </div>
            :
            <div className="qrCamera">
              <svg onClick={() => this.setState({qrOpen: true, scaned: false, errorMessage: null})} xmlns="http://www.w3.org/2000/svg" width="50"
                   height="50" viewBox="0 0 50 50">
                <defs>
                  <clipPath id="clipPath">
                    <rect width="50" height="50" fill="none"/>
                  </clipPath>
                </defs>
                <g id="Camera" clipPath="url(#clipPath)">
                  <rect id="Rectangle_252" data-name="Rectangle 252" width="50" height="50" fill="none"/>
                  <path id="Tracé_110" data-name="Tracé 110"
                        d="M25,32.667A6.54,6.54,0,0,0,25,19.6a6.54,6.54,0,0,0,0,13.067Zm0-19.6c6.875,0,12.5,5.88,12.5,13.067S31.875,39.2,25,39.2,12.5,33.32,12.5,26.133,18.125,13.067,25,13.067ZM43.75,49H6.25a6.042,6.042,0,0,1-4.375-1.96A6.6,6.6,0,0,1,0,42.467v-29.4A6.415,6.415,0,0,1,6.25,6.533h5l4.688-4.9A5.068,5.068,0,0,1,19.688,0H30.625a4.925,4.925,0,0,1,3.438,1.633l4.688,4.9h5A6.415,6.415,0,0,1,50,13.067v29.4A6.415,6.415,0,0,1,43.75,49Z"
                        transform="translate(0 1)" fill="rgba(255, 255, 255, 0.75)" fillRule="evenodd"/>
                </g>
              </svg>
            </div>
        }
        {this.scanFeedback()}
        {
          validateCoupon &&
          <div className="qrValidateContainer">
            <div className="qrValidateSubContainer">
              <p className="qrValidateTxt">Ou entrez un code </p>
              <div className="qrValidateWrapper">
                <div className="input-group">
                  <input type="text" value={codeInput} onChange={(e) => this.setState({codeInput: e.target.value})}
                         className="form-control" placeholder="000 000 000 000"/>
                </div>
                <div>
                  <AppButton onClick={this.handleClickSearchCoupon}
                          className={`btn ${scaned ? "clearBtn" : "qrValiderBtn"}`}>
                    {
                      scaned ? <img
                        src="/assets/tcaAssets/25112931/clear.png"
                        alt="clear"/> : "Valider"
                    }
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

import React, { Component } from 'react';

export default class ImportField extends Component {
  constructor(props) {
    super(props);

    console.log(props)
    this.state = {
      file: null,
      fileSrc: props.value || '',
    }
  }

  localHandle = (e) => {
    console.log(e.target.files)
    if (!e.target.files || !e.target.files.length) return false;
    const file = e.target.files[0];
    const fileSrc = window.URL.createObjectURL(file);
    this.setState({ file, fileSrc })
    this.props.handleChange({
      target: {
        id: e.target.id,
        value: file,
      }
    })
  }

  render(){
    const {id, label, subLabel,
      optional} = this.props;

    return (
      <p>
        {label ?
        <label htmlFor={id}>
          {label}{!optional ? <span
          className="required">*</span> : null} <span
          className="subLabel">{subLabel}</span>
        </label> : null}
        <input
          id={id}
          required={!optional}
          type="file"
          accept="image/jpeg,.jpg,.jpeg,image/gif,.gif,image/png,.png,image/bmp,.bmp"
          onChange={this.localHandle}
         />
        {
          this.state.fileSrc &&
          <img
            onError={() => this.setState({fileSrc: ''})}
            src={this.state.fileSrc}
          />
        }
      </p>
    )
  }
}
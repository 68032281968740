import React, { Component } from 'react'
import AppLink from '../AppLink';

export default class ErrorPopup extends Component {

    componentWillMount(){
        this.props.error && document.body.classList.add("has-popup");
    }

    componentWillUnmount(){
        this.props.error && document.body.classList.remove("has-popup");
    }
 
    render() {
        return (
             <div id="popup">
            <div id="popupContent"> 
                    <div style={{height: "150px", display: "flex",justifyContent: "center",alignItems: "center",textAlign: "center",flexFlow: "column nowrap"}}><span style={{fontWeight: '500'}}>Une erreur est survenue</span> <br />
                    <AppLink className="btn btn-primary btn-flat" href="/">RETOUR</AppLink>
                    <AppLink anchor href="mailto:contact@bienvenus.com" style={{fontWeight: "500", color: "#3a4581"}}> Contacter le service client</AppLink>
                    </div>
            </div>
          </div>
        )
    }
}

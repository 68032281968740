import React, { Component } from 'react';

export default class TextareaField extends Component {
  render(){
    const {id, label,
      handleChange, optional,
      errorMsg, value,
      customError, subLabel,
      placeholder, disabled,labelOptional,
      rows
    } = this.props;

    const placeHolder = (labelOptional && `${label}${!optional ? '*' : ''}`) || placeholder;

    return (
      <p>
        {
          !labelOptional && <label htmlFor={id}>
            {label}{!optional ? <span
            className="required">*</span> : null} <span
            className="subLabel">{subLabel}</span>
          </label>
        }
        <textarea
          required={!optional}
          id={id}
          onChange={handleChange}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          onInvalid={(e) => {
            if (customError) e.target.setCustomValidity(customError);
          }}
          onInput={(e) => {e.target.setCustomValidity('')}}
          rows={rows}
        />
        {errorMsg ?
          <span className="error">
                      {errorMsg}
                    </span>
          : null}
      </p>
    )
  }
}
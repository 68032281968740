import React, {Component} from "react";
import {
  withRouter,
  Link,
} from 'react-router-dom';
import {logToOauth} from "../../tools/apitools";
import Logo from "../../components/Logo";
import '../../style/login.scss';
import {Context} from "../../tools/context";
import { isFirstTimeLogin, markFirstTimeLoginDone } from "../../tools/toolbox";
import { siteContext } from "../../tools/toolbox";
import LoginTiCo from "./LoginTicketCommercant";
import LoginBvn from "./LoginBvn";
import AppLink from "../../components/AppLink";
import AppButton from "../../components/AppButton";
import { clientT } from "../../tools/i18n";

class Index extends Component {
  constructor() {
    super();

    this.state = {
      login: '',
      password: '',
      rememberme: false,
      errorMessage: null,
    }
  }

  componentDidMount() {
    if(siteContext().accessToken){
      if (siteContext().role.ROLE_ADMIN || siteContext().role.ROLE_BACKUSER ) {
        window.location.href = '/adminPage';
      } else {
        window.location.href = '/dashboard-new';
      }
    }
    let status = new URLSearchParams(this.props.location.search).get("status");
    if (status && status === 'denied') {
      this.setState({errorMessage: "Désolé ce compte ne respecte pas les conditions d'éligibilité"})
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({errorMessage: null});
    const {match, location, history} = this.props;
    logToOauth({...this.state},
      () => {
        if (siteContext().role.ROLE_ADMIN || siteContext().role.ROLE_BACKUSER ) {
          window.location.href = '/adminPage';
        } else {
          window.location.href = '/dashboard-new';
        }
      },
      (serverError) =>
        this.setState({errorMessage: serverError
            ? clientT('login.errors.serverError')
            : clientT('login.errors.credentialError')}
        ));
    return false;
  };

  renderLayout = () => {
    if (Context?.partner?.ticketcommercant) {
      return LoginTiCo;
    } else {
      return LoginBvn;
    }
  }

  render() {
    const Layout = this.renderLayout();
    return (
      <Layout>
          <div className="loginTitle">
            {clientT('login.title')}
          </div>
          {this.state.errorMessage ?
            <div className="error">{this.state.errorMessage}</div>
          : null}
          <form onSubmit={this.handleClick}>
            <div>
              <label htmlFor="username">Email<span
                className="required"
              >*</span></label>
              <input
                required
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                value={this.state.login}
                onChange={(e) => {
                  this.setState({login: e.target.value})
                }}
              />
            </div>
            <div>
              <label htmlFor="password">{clientT('login.passwordLabel')}<span
                className="required">*</span></label>
              <input
                required
                name="password"
                id="password"
                type="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={(e) => {
                  this.setState({password: e.target.value})
                }}
              />
            </div>
            <div style={{marginTop: '40px'}}>
              <AppButton
                type="submit"
                name="login"
                id="login"
                value="Identification"
                className="button button1"
                event="Login"
              >
               {clientT('login.loginLabel')}
              </AppButton>
            </div>
            <div>
              <AppLink href="/reset_password">{clientT('login.resetPassword')}</AppLink>
            </div>
            <div>
              <AppLink
                href={Context.partner?.sponsor === 'passprovence' ? "https://www.myprovence.pro/labo-de-provence/culture/pass-myprovence" : "/inscription"}
                className="button button3"
              >
               {clientT('login.register')}
              </AppLink>
            </div>
          </form>
      </Layout>
    );
  }
}

export default withRouter(Index);
import mixpanel from 'mixpanel-browser';
import amplitude from 'amplitude-js';
import Context from './context';
import {siteContext} from '../tools/toolbox';

export const init = () => {
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  const contactEmail = siteContext()?.seller?.contactEmail || '';
  const userID = siteContext()?.userID || '';
  mixpanel.init('2018d6a6559ff38fd4893d4fc4d4d164', {debug: true});
  amplitude.getInstance().init('e8ff23d049fa1e0756bedea86c5ac872');
  if (contactEmail){
    amplitude.getInstance().setUserId(contactEmail);
    mixpanel.identify(contactEmail);
  }
  else if (userID){
    amplitude.getInstance().setUserId(userID);
    mixpanel.identify(userID)
  }
}

export const track = (action, data) => {
  if (Context.domainType !== 'seller' && !Context.features.enableUserTracking) return;
  if (siteContext().accessToken) {
    data.connectionType = 'seller';
  } else if (siteContext().clientToken) {
    data.connectionType = 'client';
  } else {
    data.connectionType = 'anonymous';
  }
  data.domainType = Context.domainType;
  mixpanel.track(action, data);
  amplitude.getInstance().logEvent(action, data);
}

export const setUserData = (data) => {
  mixpanel.register(data);
  amplitude.getInstance().setUserProperties(data);
}

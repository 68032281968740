import React, { Component } from "react";
import {Context} from '../../tools/context'
import "../../style/adscard1.scss";
import AppLink from "../../components/AppLink";

export default class TCACard extends Component {
  constructor() {
    super();

    this.state = {
      imageIndex: 0
    }
  }

  // componentDidMount() {
  //   setInterval(() => {
  //     this.setState({imageIndex: 0})
  //   }, 5000);
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.screen !== this.props.screen){
      this.setState({imageIndex: 0});

      setTimeout(() =>
        this.setState({imageIndex: 0})
      , 5000);
    }
  }

  getType = (value) => {
    if (this.props.homeData.offersValue === 'service') {
      return this.props.homeData.productDescription;
    }
    switch(this.props.homeData.discountType) {
      case 'value':
        return `${value}€ de remise`;
      case 'percent':
        return `${value}% de remise`;
      default:
        return value;
    }
  }

  renderThreshold = () => {
    const {homeData} = this.props;
    const value = homeData.thresholdValue;
    if (homeData.thresholdType === 'Basket') {
      return `dès ${value}€ d'achat`;
    }
    if (homeData.thresholdType === 'Points') {
      return `pour ${value} points accumulés`;
    }
    return `pour ${value} achats`;
  }

  getTCACategory = (cat) => {
    switch (cat) {
      case 'hotel':
      case 'gite':
      case 'camping':
      case 'chambre_dhotes':
        return 'ou-sejourner';
      case 'sport':
      // case 'musées':
      case 'jeux_jouets':
      case 'high_tech':
      case 'cinema':
      case 'librairie_presse':
      case 'club_associations':
      case 'ateliers':
      case 'evenementiel':
      case 'photographie':
        return 'loisirs';
      case 'agriculture':
      case 'fleuriste':
      case 'vignoble':
        return 'producteurs-et-artisans';
      case 'restaurant':
      case 'cafe':
      case 'bar':
      case 'traiteur':
        return 'a-table';
      case 'voyage':
        return 'services-au-tourisme';
      case 'musee':
        return 'monuments-et-musees';
      default:
        return null;
    }
  }

  CategoryLabel = {
    'ou-sejourner': 'OÙ SÉJOURNER ?',
    'loisirs': 'LOISIRS',
    'producteurs-et-artisans': 'PRODUCTEURS ET ARTISANS',
    'a-table': 'À TABLE !',
    'services-au-tourisme': 'SERVICES AU TOURISME',
    'monuments-et-musees': 'MONUMENTS ET MUSÉES',
  }

  render() {
    const {previousData, additionalInfo} = this.props;

    const cat = this.getTCACategory(this.props.additionalInfo.category || Context.seller.category);

    const imageList = [
      `https://cdn.cibler.io/static/seller/${Context.seller.id}/${previousData.id}.jpg`,
      `https://cdn.cibler.io/static/seller/${Context.seller.id}/store.jpg`,
      `https://tourismebyca.s3.eu-west-3.amazonaws.com/wp-content/static/${cat}.png`
    ];
    return (
      <>
        <div className="adsCardContainer">
          <div className="images">
            <img
              className="adsImage"
              src={`${imageList[this.state.imageIndex]}?dummy=${new Date().getTime()}`}
              onError={() =>  {
                if (this.state.imageIndex >= imageList.length) return;

                this.setState({imageIndex: this.state.imageIndex +1})
              }}
            />
          </div>
          <div className="floatingBlock">
            <i className="head"></i>
            <div className="content">
              <div className="category">
                {this.CategoryLabel[cat]}
                <img
                  className="adsCoverImage"
                  src={`https://tourismebyca.s3.eu-west-3.amazonaws.com/wp-content/static/icons/categ-${cat}.png`}
                />
              </div>
              <div style={{ fontWeight: "bold" }} className="storetext"></div>
              <div className="title">
                <div className="subtitle"> {this.getType(this.props.homeData.discountValue)} {this.renderThreshold()}</div>
                <div className="subtext">
                  {additionalInfo.conditions}
                </div>
              </div>
            </div>
            <AppLink
              href={`https://tourismebyca.fr/bon-plan/?id=${previousData.id}`}
              className="sellerBtn"
            >
              J’en profite <span className="icon"></span>
            </AppLink>
          </div>
        </div>
      </>
    );
  }
}

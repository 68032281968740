import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './style/custom.scss';
import AdminApp from "./AdminApp";
import MainApp from "./MainApp";

function App() {
  return (
    <Switch>
      <Route path={'/Account'} component={AdminApp} />
      <Route component={MainApp} />
    </Switch>
  )
}

export default App;

import React from 'react';

const CommentCaMarche = () => {
  return (
    <div>
      <div>
        <h1 id='commentçamarche'className='process_title'>Comment ça marche?</h1>
      </div>

      <div className='process_Wrapper'>
        <div>
          <picture className='backGroundImage2'>
            <source
              media='(max-width: 992px)'
              srcset='/assets/tcaAssets/26170155/750x118.png'
            />
            <img
              src='/assets/tcaAssets/08173558/commentcamarche-1.png'
              alt='some picture'
            />
          </picture>
        </div>

        <div className='helpDesk'>
          <p>CE N'EST PAS CLAIR ?</p>
          <p>UN NUMERO VERT VOUS ACCOMPAGNE</p>
          <p> 09 80 80 37 49</p>
        </div>
      </div>
    </div>
  );
};

export default CommentCaMarche;

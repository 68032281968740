import { clientT } from "../../tools/i18n";
import React, { Component } from "react";
import AppButton from "../../components/AppButton";
import "../../style/adscard2.scss";

export default class GenericCard extends Component {
  getType = (value) => {
    if (this.props.homeData.offersValue === 'service') {
      return this.props.homeData.productDescription;
    }
    switch(this.props.homeData.discountType) {
      case 'value':
        return clientT('genericCard.discount', {value, type: '€'});
      case 'percent':
        return clientT('genericCard.discount', {value, type: '%'});
      default:
        return value;
    }
  }

  renderThreshold = () => {
    const {homeData} = this.props;
    const value = homeData.thresholdValue;
    console.log('value', value)
    if (homeData.thresholdType === 'Basket') {
      return clientT('genericCard.basketThreshold', {value});
    }
    if (homeData.thresholdType === 'Points') {
      return clientT('genericCard.pointsThreshold', {value});
    }
    return clientT('genericCard.amountThreshold', {value});
  }

  render() {
    console.log(this.props)
    const {homeData} = this.props;
    return (
      <div
        data-campaignid="63062832"
        data-threshold="20"
        data-thresholdtype="BASKET"
        className="card card-offer adInnerCard"
      >
        <div>
          <img
            src="/assets/tcaAssets/31150920/Coin.png"
            className="ui avatar image"
          />
        </div>
        <div className="inner-content-wrap">
          <p
            style={{
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: "12px",
              opacity: 0.5,
            }}
          >
             {clientT('genericCard.until')}
          </p>
          <p className="offer">
            <strong>{this.getType(homeData.discountValue)} {this.renderThreshold()}</strong>
          </p>
          <div className="sponsor-div">
            <p className="sponsor-text-custom"> {clientT('genericCard.reduction')}</p>
          </div>
        </div>
        <div className="points-required" style={{ marginLeft: "auto" }}>
          <AppButton
            className="btn btn-inline adBtn offer-btn previewBtn"
            data-sponsor="true"
            data-apply=""
            data-id="EP6285DHJAOB9"
          >
            {clientT('genericCard.apply')}
          </AppButton>
        </div>
      </div>
    );
  }
}

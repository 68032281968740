import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "moment/locale/fr";

import "../../style/homeMyprovence.scss";
import { Context } from "../../tools/context";
import AppLink from "../../components/AppLink";
import {siteContext} from "../../tools/toolbox";
moment.locale("fr");


const HomeMyProvence = ({location}) => {
    const status = Context.seller.data.find(k => k.key === `${Context.partner.sponsor}_status`)

    if (status && status.value === 'accepted') {
        window.location.replace('/dashboard-new')
    }else{
        siteContext().accessToken = null;
    }

    return (
    <div className="tmp_container myprovence_container">
      <div className="tmp_wrapper myprovence_wrapper">
        <div className="tmp_welcome">
          <h1>Nous avons bien reçu votre demande d'inscription !</h1>
          <div className="framed_text">
            <p>L'équipe Provence Tourisme va instruire votre dossier dans les plus brefs délais.</p>
            <p>
              <span>Important :</span> Si votre établissement est éligible au Pass My Provence,
                  vous recevrez par email une convention de partenariat. Celle-ci doit être signée afin de finaliser votre inscription et avoir accès à votre espace.
                </p>
          </div>
          <div className="tmp_welcome_text">
            <p>
              Lancement grand public
                  <span className="date">&nbsp;le 24 octobre 2022</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HomeMyProvence;

import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import {reactFormatter, ReactTabulator} from 'react-tabulator';
import {authCall, logToOauth, getAccount, getSeller} from '../tools/apitools';
import Logo from '../components/Logo';
import '../style/adminPage.scss';
import {Context, ContextUtil} from '../tools/context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {getCategoryKeys} from '../tools/formFieldsDatabase';
import {siteContext, saveContext} from '../tools/toolbox';
import Loader from '../components/Loader';
import AppLink from '../components/AppLink';
import AppButton from '../components/AppButton';
import {clientT} from "../tools/i18n";
import {getQueryString} from "../tools/toolbox";
class AdminPage extends Component {
    constructor() {
        super();
        this.shortcodeFilter = getQueryString("filter");
        this.tabRef = React.createRef();
        this.formatters = {
            registrationDate: this.formatCreationDate,
            convention: this.formatConvention,
            partnerStatus: this.formatStatus,
            partnerKitCom: this.formatKitCom,
            id: this.formatButton,
            category: this.formatCategory,
            testAccount: this.formatTestAccount,
            withdrawStatus: this.formatWithdrawStatus,
        };

        this.clickhandler = {
            id: this.handleCellClick,
        };

        this.categoryKeys = getCategoryKeys();

        this.baseTable = [
            {title: 'Email', field: 'contactEmail'},
            {title: 'Nom', field: 'name'},
            {title: 'Téléphone', field: 'contactPhone'},
            {
                title: 'Date de création',
                field: 'registrationDate',
            },
            {title: 'Catégorie', field: 'category', width: 300},
            {
                title: 'Iban',
                field: 'iban',
                hozAlign: 'center',
                formatter: 'tickCross',
                formatterParams: {allowTruthy: true},
            },
            {
                title: 'Ticket Reçu',
                field: 'debit',
                hozAlign: 'center',
            },
            {
                title: 'Versé',
                field: 'credit',
                hozAlign: 'center',
            },
            {title: 'Shortcode', field: 'shortcode'},
            {
                title: Context.partner?.features?.enableSignatureDate ? "Signature" : 'Convention',
                field: Context.partner?.features?.enableSignatureDate ? "signature" : 'convention',
                hozAlign: 'center',
                headerSort: false,
            },
            {
                title: 'Status',
                field: 'partnerStatus',
                hozAlign: 'center',
                cssClass: 'select',
            },
            {
                title: 'Virements',
                field: 'withdrawStatus',
                hozAlign: 'center',
                cssClass: 'select',
            },
            {
                title: 'Kit envoyé',
                field: 'partnerKitCom',
                width: 120,
                hozAlign: 'center',
                vertAlign: true,
            },
            {
                title: '',
                field: 'id',
                headerSort: false,
                hozAlign: 'center',
                cssClass: 'selectable',
                cellClick: this.handleCellClick,
            },
        ];

        if (Context?.partner?.sponsor === 'passprovence') {
            this.baseTable.splice(5, 3);
        }

        if (Context?.partner?.adminPageTable) {
            this.baseTable = Context.partner.adminPageTable;
        }
        if(siteContext().adminToken){

            siteContext().accessToken = siteContext().adminToken;
            siteContext().refreshToken = siteContext().adminRefresh;
            siteContext().adminToken = null;
            siteContext().adminRefresh = null;
            saveContext()
        }
        this.state = {
            vendors: [],
            filter: ''||this.shortcodeFilter,
            errorMessage: null,
            actionToTrigger: ""
        };
    }


    componentDidMount() {
        // document.title = 'Page d\'administration';
        this.initSellers();
    }

    initSellers = () => {
        authCall(
            `/api/sellers/${Context.partner.sponsor}`,
            {},
            (r) => {
                console.log(r);
                this.setState({vendors: r.map(d => ({...d, ...d.datamap}))});
            },
            () =>
                this.setState({
                    errorMessage:
                    clientT('adminPage.errorMessageLoading'),
                })
        );
    }

    handleChange = (value, cell, key) => {
        const id = cell.getRow().getData().id;

        const newState = [...this.state.vendors];
        const row = newState.find((s) => s.id === id);
        if (!row) return false;
        const oldData = key === 'status' ? row.partnerStatus : row.partnerKitCom;
        key === 'status'
            ? (row.partnerStatus = value)
            : (row.partnerKitCom = value);

        this.setState({vendors: newState});

        const body = {};
        if (key === 'status') {
            body[`${Context.partner.sponsor}_status`] = value;
        } else {
            body[`${Context.partner.sponsor}_kitdecommunication`] = value;
        }

        authCall(
            `api/seller/data/${id}`,
            {
                method: 'PUT',
                body: JSON.stringify(body),
            },
            () => {
                this.setState({
                    errorMessage: '',
                });
            },
            () => {
                key === 'status'
                    ? (row.partnerStatus = oldData)
                    : (row.partnerKitCom = oldData);
                this.setState({
                    vendors: newState,
                    errorMessage: clientT('adminPage.errorMessageRecording'),
                });
            }
        );
    };

    handleWithdrawStatusChange = (value, cell) => {
        const id = cell.getRow().getData().id;

        const newState = [...this.state.vendors];
        const row = newState.find((s) => s.id === id);
        if (!row) return false;
        const oldData = row.withdrawStatus;
        row.withdrawStatus = value;

        this.setState({vendors: newState});

        authCall(
          `api/sellers/${id}/withdrawStatus/${value}`,
          {
              method: 'POST',
          },
          () => {
              this.setState({
                  errorMessage: '',
              });
          },
          () => {
              row.withdrawStatus = oldData;
              this.setState({
                  vendors: newState,
                  errorMessage:  clientT('adminPage.errorMessageRecording'),
              });
          }
        );
    };

    formatKitCom = (cell, params, onRendered) => {
        return reactFormatter(
            <input
                type='checkbox'
                onChange={(e) =>
                    this.handleChange(e.target.checked, cell, 'kitdecommunication')
                }
                checked={cell.getValue() === 'true' || cell.getValue() === true}
            />
        )(cell, params, onRendered);
    };

    sellerSignin = (cell) => {
        const {history} = this.props;
        const sellerToConnectEmail = cell.getRow().getData().contactEmail;
        let reqParams = {
            method: 'POST',
            body: JSON.stringify({
                username: sellerToConnectEmail,
                password: 'nopassword',
                grantType: 'password',
                scope: 'read+write',
            }),
        };

        siteContext().adminToken = siteContext().accessToken;
        siteContext().adminRefresh = siteContext().refreshToken;

        authCall(
            '/api/users/auth?shortcode='+cell.getRow().getData().shortcode,
            reqParams,
            async (result) => {
                this.setState({displaySigninPopup: false});
                siteContext().accessToken = result.access_token;
                siteContext().refreshToken = result.refresh_token;
                saveContext();
                await getAccount();
                await getSeller();
                window.location.href='/home';
            },

            () => {
                console.log('reject');
            }
        );
    };

    handleReset  = (cell) => {
        const {history} = this.props;
        const sellerToConnectEmail = cell.getRow().getData().contactEmail;
        let reqParams = {
            method: 'POST',
            body: JSON.stringify({
                username: sellerToConnectEmail,
                password: 'nopassword',
                grantType: 'password',
                scope: 'read+write',
            }),
        };


        authCall(
            '/api/users/forceReset',
            reqParams,
            async (result) => {
                let newpwd = result.password;
                this.setState({displayNewPwd: true,newPwd:newpwd});
            },
            () => {
                console.log('reject');
            }
        );
    };

    handleTriggerAction = (event, cell) => {
        switch (event.target.value) {
            case 'seller_data': //Open new tab  to /adminPage/${seller_id}
                this.handleCellClick(event, cell);
                event.target.value = '';
                break;
            case 'seller_signIn': //Sign in seller account
                this.sellerSignin(cell);
                event.target.value = '';
                break;
            case 'flag_test_account': //Flag seller as test account
                this.handleTestAccount(cell);
                event.target.value = '';
                break;
            case 'reset':
                this.handleReset(cell);
                event.target.value = '';
                break;
            default:
                console.log('No action triggered');
        }
    };

    formatTestAccount = (cell, params, onRendered) => {
        return reactFormatter(
            <input
                type='checkbox'
                onChange={(e) => {
                    this.handleTestAccount(e.target.checked, cell, 'testAccount');
                }}
            />
        )(cell, params, onRendered);
    };

    formatActionsList = (cell, params, onRendered) => {
        return reactFormatter(
            <div>
                <select onChange={(event) => this.handleTriggerAction(event, cell)}>
                    <option value='' selected disabled>Action</option>
                    <option value='seller_data'>{clientT('adminPage.actionList.sellerData')}</option>
                    <option value='flag_test_account'>{clientT('adminPage.actionList.flagTestAccount')}</option>
                    <option value='seller_signIn'>Se connnecter</option>
                    <option value='reset'>Reset MDP</option>
                </select>
            </div>
        )(cell, params, onRendered);
    };

    formatConvention = (cell, params, onRendered) => {
        const mail = cell.getRow().getData().contactEmail;
        const url = `https://app.pandadoc.com/a/#/documents-next?sortBy=status&direction=desc&customDate=date_modified&search=${mail}`;
        return reactFormatter(
            <div className='contract_signature_icon'>
                <AppLink target='_blank' href={url}>
                    <FontAwesomeIcon icon='file-signature' size='lg'/>
                </AppLink>
            </div>
        )(cell, params, onRendered);
    };

    formatStatus = (cell, params, onRendered) => {
        const cellValue = cell.getValue();
        return reactFormatter(
            <select
                className={cellValue ? cellValue.toLowerCase() : ''}
                defaultValue={cellValue ? cellValue.toLowerCase() : ''}
                onChange={(e) => this.handleChange(e.target.value, cell, 'status')}
            >
                <option className='accepted' value='accepted'>
                    Accepté
                </option>
                <option className='pending' value='pending'>
                    En attente
                </option>
                <option className='created' value='created'>
                    Crée
                </option>
                <option className='denied' value='denied'>
                    Refusé
                </option>
            </select>
        )(cell, params, onRendered);
    };

    formatWithdrawStatus = (cell, params, onRendered) => {
        const cellValue = cell.getValue();
        return reactFormatter(
            <select
                className={cellValue ? cellValue.toLowerCase() : ''}
                defaultValue={cellValue}
                onChange={(e) => this.handleWithdrawStatusChange(e.target.value, cell)}
            >
                <option className='accepted' value='OK'>
                    OK
                </option>
                <option className='pending' value='PROCESSING'>
                    En cours
                </option>
                <option className='denied' value='BLOCKED'>
                    Bloqué
                </option>
                <option className='denied' value='BANNED'>
                    Bannis
                </option>
            </select>
        )(cell, params, onRendered);
    };

    formatButton = (cell, params, onRendered) => {
        return '+';
    };

    handleCellClick = (e, cell) => {
        window.open(`${window.location.href}/${cell.getValue()}`, '_blank').focus();
        // this.props.history.push(`/adminPage/${cell.getValue()}`)
    };

    handleTestAccount = (cell) => {
        const id = cell.getRow().getData().id;

        const newState = [...this.state.vendors];
        const row = newState.find((s) => s.id === id);
        if (!row) return false;
        const oldData = row.testAccount;
        const filteredVendorsList = newState.filter((v) => {
            return v.id !== id;
        });
        this.setState({vendors: filteredVendorsList});
        const body = {testAccount: true};
        authCall(
            `api/seller/data/${id}`,
            {
                method: 'PUT',
                body: JSON.stringify(body),
            },
            () => {
                this.setState({
                    errorMessage: '',
                });
            },
            () => {
                row.testAccount = oldData;
                this.setState({
                    vendors: newState,
                    errorMessage:  clientT('adminPage.errorMessageRecording'),
                });
            }
        );
    };

    test = (input, filter) => {
        return (input + '' || '').toLowerCase().includes(filter.toLowerCase());
    };

    formatCreationDate = (cell, params, onRendered) => {
        const dateToFormat = cell.getRow().getData().registrationDate;
        const dateFormatted = moment(dateToFormat).format('DD/MM/YYYY');
        return reactFormatter(<div>{dateFormatted}</div>)(cell, params, onRendered);
    };

    formatSignatureDate = (cell, params, onRendered) => {
        const dateToFormat = cell.getRow().getData().signature;
        const dateFormatted = dateToFormat && moment(dateToFormat).format('DD/MM/YYYY') || '';
        return reactFormatter(<div>{dateFormatted}</div>)(cell, params, onRendered);
    };

    formatCategory = (cell) => {
        return this.categoryKeys[cell.getValue()] || cell.getValue();
    };

    downloadCsv = () => {
        this.tabRef.current.table.download('csv', 'data.csv', {delimiter: ';'});
    };

    render() {
        const kitKey = `${Context.partner.sponsor}_kitdecommunication`;

        const columns = this.baseTable.map((e) => ({
            ...e,
            formatter: this.formatters[e.field] || null,
            cellClick: this.clickhandler[e.field] || null,
        }));

        columns.unshift({
            formatter: 'rowSelection',
            titleFormatter: 'rowSelection',
            hozAlign: 'center',
            headerSort: false,
            download: false,
            maxWidth: 75,
        });

        columns.splice(1, 0, {
            title: 'Actions',
            field: 'id',
            formatter: this.formatActionsList,
            hozAlign: 'left',
            resizable: false,
            minWidth: 155,
            headerSort: false,
            download: false,
        });


        const options = {
            downloadDataFormatter: (data) => data,
            downloadReady: (fileContents, blob) => blob,
            downloadRowRange: 'selected',
            layout: 'fitDataFill',
            resizableColumns:true,
            pagination: 'local',
            paginationSize: 20,
            autoResize: true,
            initialSort: [
                {column:"registrationDate", dir:"desc"}
            ]
        };

        const {filter} = this.state;
        const filtered = filter
            ? this.state.vendors.filter(
                (v) =>
                    this.test(v.contactEmail, filter) ||
                    this.test(v.name, filter) ||
                    this.test(v.contactPhone, filter) ||
                    this.test(v.category, filter) ||
                    this.test(v.iban, filter) ||
                    this.test(v.shortcode, filter)
            )
            : this.state.vendors;
        const popupMessage = this.state.displayNewPwd ? "Nouveau mdp : "+this.state.newPwd:"";
        return (
            <div className='adminPage'>
                {this.state.loaderOn ? <Loader /> : null}
                <div className='title'>{clientT('adminPage.title')}</div>
                {this.state.errorMessage ? (
                    <div className='register_error'>{this.state.errorMessage}</div>
                ) : null}
                <div>
                    <input
                        className='adminSearch'
                        placeholder={clientT('adminPage.searchplaceholder')}
                        onChange={(e) => this.setState({filter: e.target.value})}
                    />
                </div>
                <div className='action_list'>
                    {siteContext().role.ROLE_ADMIN && (
                        <AppButton
                            onClick={() => {
                                // this.props.history.replace('/customeraccount');
                                window.open(
                                  `https://prod.bienvenus.com/customeraccount?part=${
                                      Context.partner.sponsor}&ta=${siteContext().accessToken}`);
                            }}
                            event="Connexion compte client"
                        >
                          {clientT('adminPage.customerAccountLogin')}
                        </AppButton>
                    )}

                    <AppButton event="Export CSV" onClick={this.downloadCsv}>{clientT('adminPage.exportCsv')}</AppButton>
                </div>
                <div id='message'>{popupMessage}</div>
                <ReactTabulator
                    ref={this.tabRef}
                    data={[...filtered]}
                    columns={columns}
                    layout={'fitData'}
                    options={options}
                />
            </div>
        );
    }
}

export default withRouter(AdminPage);

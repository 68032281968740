import Pin from "../../components/Pin";
import {getMetaCategoryColors} from "../../tools/toolbox";
import React from "react";
import PinHole from "../../components/PinHole";
import Icon from "../../components/Icon";
import {clientT} from "../../tools/i18n";
import AppLink from '../../components/AppLink';

const PinTicketCo = ({data, coupon, onClick}) => {
  return (
    <div className="PinPopup" onClick={onClick}>
      <div
        className="categButton"
      >
        <PinHole height={37} width={30} color={getMetaCategoryColors(data.metaCateg)}/>
        <div className="label" style={{fontSize: '17px'}}>{data.metaCateg}</div>
      </div>
      <div className="info">
        <div style={{fontSize: '20px', marginBottom: '5px'}}>{data.name}</div>
        <div style={{fontSize: '15px'}}>{data.street}
          <div>{data.zipcode} {data.city}</div>
          <div style={{fontSize: '13px', marginBottom: '5px'}}>
            <AppLink
              href={`/portefeuille?${coupon ? `coupon=${coupon}&` : ''}shortcode=${data.shortcode}`}
              dangerouslySetInnerHTML={{__html: clientT('refund.ask.getRefund', {shortcode: data.shortcode})}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const PinTicketPei = ({data, coupon, onClick}) => {
  console.log(data);
  return (
    <div className="PinPopup" style={{
      padding: 0,
      overflow: 'hidden',
    }}
         onClick={onClick}
    >
      <div
        style={{
          background: getMetaCategoryColors(data.metaCateg),
          padding: '13px 0',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <div className="label" style={{fontSize: '17px'}}>{data.metaCateg}</div>
      </div>
      <div className="info" style={{
        color: '#565656',
        padding: '0 10px 0 30px',
        textAlign: "left"
      }}>
        <div style={{fontSize: '16px', marginBottom: '5px'}}>{data.name}</div>
        <div style={{display: 'flex'}}>
          <div style={{marginTop: '5px', marginRight: '5px'}}>
            <PinHole height={18} width={20} color={getMetaCategoryColors(data.metaCateg)}/>
          </div>
          <div>
            <div style={{fontSize: '13px'}}>{data.street}</div>
            <div style={{fontSize: '13px'}}>{data.zipcode} {data.city}</div>
          </div>
        </div>
        {data.website &&
          <div style={{fontSize: '13px', margin: '5px 0', display: 'flex', alignItems: "center"}}>
            <Icon name={'website'} style={{height: '20px', marginRight: '5px'}}/>
            <a href={data.website} target="_blank">{clientT('form.website')}</a>
          </div>
        }
        <div style={{fontSize: '13px', marginBottom: '5px'}}>
          <AppLink
            href={`/portefeuille?${coupon ? `coupon=${coupon}&` : ''}shortcode=${data.shortcode}`}
            dangerouslySetInnerHTML={{__html: clientT('refund.ask.getRefund', {shortcode: data.shortcode})}}
          />
        </div>
      </div>
    </div>
  )
}

const PinPopup = ({data, coupon, platform, onClick}) => {
  return <PinTicketPei data={data} coupon={coupon} onClick={onClick} />;

  switch (platform) {
    case 'ticketpei':
    case 'myprovence':
      return <PinTicketPei data={data} coupon={coupon} onClick={onClick} />
    default:
      return <PinTicketCo data={data} coupon={coupon} onClick={onClick} />
  }
}

export default PinPopup;


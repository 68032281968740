import React, {Component} from 'react'
import {Context} from "../tools/context";
import '../style/custom.scss';
import LinkWrapper from "./LinkWrapper";

export default class LogoTicketCommercant extends Component {
  render() {
    const src = Context.partner.customLogo
      ? `/assets/ticket_co_${Context.partner.sponsor}.png`
      : null;
    if (!src) return null;

    const logoRedirection = Context.partner.logoClickRedirection || '/';
    return (
      <div
        className={`LogoBlock ${this.props.big ? 'big' : ''} tico`}
        style={this.props.style}
      >
        <LinkWrapper to={logoRedirection}>
        <img
          src={src}
          alt="" title="logo" 
          className="logoImage"/>
        </LinkWrapper>
      </div>
    );
  }
}

import React from "react";

const Image = ({id, src, fallback, className, ...props}) => {
  const [hide, setHide] = React.useState();
  const [useFall, setUseFall] = React.useState();

  const handleError = () => {
    if (useFall) return setHide(true);

    if (fallback) return setUseFall(true);

    setHide(true);
  }

  if (hide) return false;

  return <img
    {...props}
    className={className}
    id={id}
    alt={props.alt}
    src={useFall ? fallback : src}
    onError={handleError}
  />
}

export default Image;
import React, { Component } from "react";
import { magasinTransactionScreenButtons } from "../Utils/Constants";
import MagasinPointsCard from "../components/MagasinPointsCard";
import AppLink from "../components/AppLink";
import AppButton from "../components/AppButton";

export default class MagasinTransaction extends Component {
  constructor() {
    super();

    this.state = {
      transactionAmount: "",
    };
  }
  componentDidMount() {
    if (this.props.clientData?.userId) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.clientData.userId
      && prevProps.clientData.userId !== this.props.clientData.userId) {
      this.props.onLoad();
    }
  }

  handleTransactionInput = (e) => {
    let prevtransactionAmount = this.state.transactionAmount;
    const value = e.target.value;
    const transactionAmount = prevtransactionAmount + value;
    this.setState({ transactionAmount });
  };

  removePhoneDigit = () => {
    const initNumber = this.state.transactionAmount;
    const newNo = initNumber && initNumber.slice(0, -1);
    this.setState({ transactionAmount: newNo });
  };

  clearInput = () => {
    this.setState({ transactionAmount: "" });
  };

  validateTransactionAmount = () => {
    this.props.validateTransactionAmount(parseFloat(this.state.transactionAmount));
  };

  render() {
    const { phoneNumber, screen, goBack } = this.props;
    return (
      <div className="page magasin-transaction" id="magasinTransactionAmount">
          <React.Fragment>
            <div className="page-content">
              <section className="hero">
                <div className="content-wrap">
                  <header className="magasin-back-icon-div">
                    <AppLink
                      className="btn btn-link txtLeft"
                      href="#"
                      onClick={() => 
                        goBack({
                          screen: 2,
                        })}
                    >
                      <span className="icon back-arrow-icon icon-arrow-left icon-white iconBack"/>
                    </AppLink>
                  </header>
                  <MagasinPointsCard {...this.props.clientData} />
                  <p className="amountLabelTxt pl-10">
                    Entrez le montant de la transaction
                  </p>
                  <div className="querryBox">
                    <div className="magasin-transaction-amount-box">
                      <span className="amountSpan magasin-transaction-amount-span input-group">
                        <input
                          type="tel"
                          className="magasin-transaction-input form-control"
                          size="4"
                          placeholder="00.00"
                          value={this.state.transactionAmount}
                          onChange={() => {}}
                          style={{ pointerEvents: "none" }}
                        />
                        <span style={{ color: "#5e5e5e" }}>€</span>
                        {/* <span className="icon icon-clear" onClick={this.clearInput}></span> */}
                      </span>
                    </div>
                    <div>
                      <AppButton
                        className="btn btn-red removeBtn"
                        onClick={this.removePhoneDigit}
                        event="Remove phone number digit"
                      >
                        <img
                          src="/assets/tcaAssets/25112931/clear.png"
                          alt="clear"
                        />
                      </AppButton>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section className="content-wrap transaction-numpad-wrapper">
              <div className="transaction-num-pad">
                {magasinTransactionScreenButtons.map((value, index) => {
                  return (
                    <AppButton
                      key={index}
                      onClick={
                        value != "Valider"
                          ? this.handleTransactionInput
                          : this.validateTransactionAmount
                      }
                      value={value}
                      className={`btn btn-red`}
                      event={value}
                    >
                      {value}
                    </AppButton>
                  );
                })}
              </div>
            </section>
          </React.Fragment>

      </div>
    );
  }
}

import React, { Component } from "react";
import "../../style/addProductInfo.scss";
import { Dropdown } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker,
{ registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import {catagoryData, categories} from "../../Utils/Constants";
import {Context} from '../../tools/context'
import ReactMapGL, {Marker} from "react-map-gl";
import {getCategories} from '../../tools/formFieldsDatabase'
import {addressGeocoding, reverseGeocoding} from "../../tools/apitools";
import AppButton from "../../components/AppButton";
import { clientT } from "../../tools/i18n";

export class AddProductInfo extends Component {
  constructor() {
    super();

    registerLocale('fr', fr)

    this.mapParams = {
      width: 300,
      height: 300,
    };

    this.lastGeocode = new Date();

    this.state = {
      purpose: "",
      category: null,
      address: "",
      zipcode: '',
      city: "",
      expirationDate: "",
      image: "",
      imageSrc: '',
      hasImage: undefined,
      conditions: "",
      description: "",
      longitude: 0,
      latitude: 0,
      showMap: false,
      validAddress: false,
      validatedAddress: true,
      mapData: {
        zoom: 15,
      }
    };

    this.category = getCategories().map((c, i) => ({
      key: i+1,
      text: c.label,
      value: c.value,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.previousData !== this.props.previousData && this.props.previousData.giftData) {
      const newState = {};
      this.props.previousData.giftData.forEach(d => {
        if (!d.value || (this.state[d.key] && this.state[d.key] !== d.value)) return;

        newState[d.key] = d.value;

        if (d.key === 'hasImage' && d.value) {
          newState.imageSrc = `https://cdn.cibler.io/static/seller/${Context.seller.id}/${this.props.previousData.id}.jpg`
        }
      })
      this.setState(newState);
    }
  }

  handleSubmit = () => {
    if (this.state.address && !this.state.validatedAddress) {
      this.setState({showMap: true});

      return;
    }

    const additionalInfo = {
      ...this.state,
      image: undefined,
      imageSrc: undefined,
      mapData: undefined,
      showMap: undefined,
      validAddress: undefined,
      validatedAddress: undefined,
      website:undefined
    }

    if (this.state.image) {
      additionalInfo.hasImage = true;
      this.props.uploadImage(this.state.image);
    }

    this.props.setAdditionalInfo({ additionalInfo });
  };

  handleMarkerPositionChange = (e) => {
    console.log((this.lastGeocode - new Date()))
    if ((new Date() - this.lastGeocode) < 200) return;
    this.lastGeocode = new Date();
    reverseGeocoding(this.state.longitude, this.state.latitude).then((data) => {
      if (data?.features?.length > 0) {
        this.setState(prevState => ({
          address: data.features[0].properties.name,
          zipcode: data.features[0].properties.postcode,
          validAddress: true,
        }));
      }
    });
  };

  handleMapInputs = (e) => {
    this.setState(() => ({
      [e.target.id]: e.target.value,
      validatedAddress: false,
    }), () => {
      if (this.state.address.length > 2 && this.state.zipcode) {
        addressGeocoding(this.state.address, this.state.zipcode).then((data) => {
          if (data.results.length > 0) {
            this.setState(prevState => ({
              longitude: data.results[0].geometry.location.lng,
              latitude: data.results[0].geometry.location.lat,
              validAddress: true,
            }));
          } else {
            this.setState({
              validAddress: false,
            });
          }
        }).catch(() => {
          this.setState({validAddress: false})
        });
      }
    });
  };

  renderMap = () => {
    return (
      <div className="mapContainer" style={{position: "absolute"}}>
        <div className="mapWrapper">
          <div>
            <div className="mapQuestion">
              <p> Voici l'adresse indiquée.</p>
              <p>La position est-elle correcte?</p>
            </div>
          </div>
          <ReactMapGL
            width={this.mapParams.width}
            height={this.mapParams.height}
            zoom={this.state.mapData.zoom}
            longitude={- -this.state.longitude}
            latitude={- -this.state.latitude}
            mapStyle={'mapbox://styles/mapbox/streets-v11'}
            mapboxApiAccessToken="pk.eyJ1IjoibHZlcmRvbmsiLCJhIjoiY2s4OHY0b2llMDBkeTNpbXBhNmRnMmU3dCJ9.adHYqa4eOwGNKzBR9EJB7A"
            onViewportChange={(nextViewport) => {
              this.setState({
                mapData: {
                  ...this.props.mapData,
                  ...nextViewport,
                },
                longitude: nextViewport.longitude,
                latitude: nextViewport.latitude,
                validAddress: true,
              });
              // this.handleMarkerPositionChange();
            }}
          >
            <Marker
              longitude={this.state.longitude}
              latitude={this.state.latitude}
            >
              <img
                className="mapMarker"
                alt="mapmarker"
                src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22%2368737d%22%3E%20%20%3Cdefs%2F%3E%20%20%3Cpath%20d%3D%22M%204%2017%20C%204%2022%2010%2028%2015%2028%20V%2032%20H%2017%20V%2028%20C%2022%2028%2028%2022%2028%2017%20H%2032%20V%2015%20H%2028%20C%2028%2010%2022%204%2017%204%20V%200%20H%2015%20V%204%20C%2010%204%204%2010%204%2015%20H%200%20V%2017%20H%2012%20V%2015%20H%207%20C%207%2011%2011%207%2015%207%20V%2012%20H%2017%20V%207%20C%2021%207%2025%2011%2025%2015%20C%2025%2015%2025%2015%2025%2015%20H%2020%20V%2017%20H%2025%20C%2025%2021%2021%2025%2017%2025%20V%2020%20H%2015%20V%2025%20C%2011%2025%207%2021%207%2017%20Z%22%2F%3E%3C%2Fsvg%3E"
              />
            </Marker>
          </ReactMapGL>
          <input
            style={{marginTop: '20px'}}
            required
            placeholder="adresse"
            id="address"
            value={this.state.address}
            className="form-control false mb-25"
            onChange={this.handleMapInputs}
          />
          <input
            required
            placeholder="code postal"
            id="zipcode"
            value={this.state.zipcode}
            className="form-control false mb-25"
            onChange={this.handleMapInputs}
          />
            <div className="mapButtons">
              <AppButton className="button button2"
                      style={{fontSize: '14px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({showMap: false});
                      }}
                      event="Annuler"
              >
                Annuler
              </AppButton>
              <AppButton
                className={`button button1 ${this.state.validAddress ? '' : 'disabled'}`}
                style={{fontSize: '14px'}}
                disabled={!this.state.validAddress}
                onClick={() => {
                  this.setState({
                    showMap: false,
                    validatedAddress: true,
                  }, this.handleSubmit);
                }}
                event="Confirmer"
              >
                Confirmer
              </AppButton>
            </div>
        </div>
      </div>
    );
  };

  render() {
    const { screen } = this.props;

    return (
      <React.Fragment>
        {screen === 3 || screen === 4 ? (
          <div className="page productBox">
            <div className="p-2">
              <div className="titleHeader screen2Heading">
              {clientT('addProductInfo.title')}
              </div>
              <div className="titleChild screen2Subheading">
              {clientT('addProductInfo.description')}
              </div>
              <div>
                <div className="screen2Label ml-11">
                {clientT('addProductInfo.labelProduct')}
                  <span className="star">*</span>
                </div>
                <input
                  id="purpose"
                  type="text"
                  className="form-control false mb-32 ml-6"
                  placeholder= {clientT('addProductInfo.productPlaceholder')}
                  value={this.state.purpose}
                  onChange={(e) => this.setState({ purpose: e.target.value })}
                />
              </div>
              <div className="ml-7">
                <div className="screen2Label">
                {clientT('addProductInfo.categoryTitle')}
                </div>
                <div className="custom-select tableSelect mb-26">
                  <Dropdown
                    onChange={(e, { value }) => {
                      this.setState({ category: value });
                    }}
                    options={this.category}
                    placeholder={clientT('addProductInfo.categoryPlaceholder')}
                    selection
                    value={this.state.category}
                  />
                </div>
              </div>
              <div>
                <div className="screen2Label ml-5">{clientT('addProductInfo.addressTitle')}</div>
                <input
                  id="address"
                  type="text"
                  className="form-control false mb-25"
                  placeholder={clientT('addProductInfo.addressPlaceholder')}
                  value={this.state.address}
                  onChange={this.handleMapInputs}
                />
              </div>
              <div>
                <div className="screen2Label ml-5">{clientT('addProductInfo.postalcodeTitle')}</div>
                <input
                  id="zipcode"
                  type="number"
                  className="form-control false mb-25"
                  placeholder={clientT('addProductInfo.zipcodePlaceholder')}
                  value={this.state.zipcode}
                  onChange={this.handleMapInputs}
                />
              </div>
              <div>
                <div className="screen2Label ml-5">{clientT('addProductInfo.cityTitle')}</div>
                <input
                  id="city"
                  type="text"
                  className="form-control false mb-25"
                  placeholder={clientT('addProductInfo.defaultCityPlaceholder')}
                  value={this.state.city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                />
              </div>
              <div>
                <p className="screen2Label mb-25">
                {clientT('addProductInfo.expirationDateTitle')}
                </p>
                <DatePicker
                  locale="fr"
                  id="date"
                  selected={this.state.expirationDate ? new Date(this.state.expirationDate) : ''}
                  placeholderText= {clientT('addProductInfo.expirationDatePlaceHolder')}
                  dateFormat={"dd/MM/yyyy"}
                  className="form-control false mb-25"
                  onChange={(date) => {
                    this.setState({ expirationDate: date });
                  }}
                />
              </div>
              <div className="imageBox mb-37 ml-12">
                <div className="screen2Label imgInputLabel">
                {clientT('addProductInfo.imageLabel')}
                  <br />
                  {this.state.image && <span className="imgName">{this.state.image.name}</span>}
                  {this.state.imageSrc && <img src={this.state.imageSrc} />}
                </div>
                <div className="imgInputBox">
                  <input
                    type="file"
                    className="imgInput"
                    accept="image/jpeg,.jpg,.jpeg,image/png,.png"
                    onChange={(e) => {
                      console.log(e.target.files)
                      if (!e.target.files || !e.target.files.length) return false;
                      const image = e.target.files[0];
                      const imageSrc = window.URL.createObjectURL(image);
                      this.setState({ image, imageSrc })
                    }}
                  />
                  <div className="cameraIcon">
                    <svg width="50" height="50" viewBox="0 0 50 50">
                      <defs>
                        <clipPath id="clip-path">
                          <rect width="50" height="50" fill="none" />
                        </clipPath>
                      </defs>
                      <g id="Camera" clipPath="url(#clip-path)">
                        <rect
                          id="Rectangle_252"
                          data-name="Rectangle 252"
                          width="50"
                          height="50"
                          fill="none"
                        />
                        <path
                          id="Tracé_110"
                          data-name="Tracé 110"
                          d="M25,32.667A6.54,6.54,0,0,0,25,19.6a6.54,6.54,0,0,0,0,13.067Zm0-19.6c6.875,0,12.5,5.88,12.5,13.067S31.875,39.2,25,39.2,12.5,33.32,12.5,26.133,18.125,13.067,25,13.067ZM43.75,49H6.25a6.042,6.042,0,0,1-4.375-1.96A6.6,6.6,0,0,1,0,42.467v-29.4A6.415,6.415,0,0,1,6.25,6.533h5l4.688-4.9A5.068,5.068,0,0,1,19.688,0H30.625a4.925,4.925,0,0,1,3.438,1.633l4.688,4.9h5A6.415,6.415,0,0,1,50,13.067v29.4A6.415,6.415,0,0,1,43.75,49Z"
                          transform="translate(0 1)"
                          fill="rgba(0,136,142,0.75)"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="addIcon">
                    <svg width="16" height="16" viewBox="0 0 11 11">
                      <defs>
                        <clipPath id="clip-path">
                          <rect width="11" height="11" fill="none" />
                        </clipPath>
                      </defs>
                      <g id="more" clipPath="url(#clip-path)">
                        <path
                          id="Union_1"
                          data-name="Union 1"
                          d="M4.812,11V6.188H0V4.812H4.812V0H6.188V4.812H11V6.188H6.188V11Z"
                          fill="#ffffff"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mb-24 ml-12">
                <textarea
                  id="condition"
                  type="text"
                  className="form-control pt-25 false screen2InfoBox"
                  placeholder={clientT('addProductInfo.conditionDescription')}
                  value={this.state.conditions}
                  onChange={(e) => {
                    this.setState({ conditions: e.target.value });
                  }}
                />
              </div>
              <div className="mb-37 ml-12">
                <textarea
                  id="description"
                  type="text"
                  className="form-control false pt-25 screen2InfoBox"
                  placeholder={clientT('addProductInfo.decription')}
                  value={this.state.description}
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                />
              </div>
              <div className="mb-37 ml-12">
                <textarea
                    id="website"
                    type="text"
                    className="form-control false pt-25 screen2InfoBox"
                    placeholder={clientT('addProductInfo.website')}
                    value={this.state.website}
                    onChange={(e) => {
                      this.setState({ website: e.target.value });
                    }}
                />
              </div>
              <div className="screen2NextBtnBox mb-27 ml-21 mr-10">
                <AppButton
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSubmit}
                  event="Valider mon bon plan"
                >
                 {clientT('addProductInfo.validatePlanButton')}
                </AppButton>
              </div>
              {this.state.showMap && this.renderMap()}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

import React, { Component } from 'react';
import moment from 'moment';
import {clientT} from "../../tools/i18n";

export default class CheckboxField extends Component {
  localHandle = (e) => {
    this.props.handleChange({
      target: {
        id: e.target.id,
        value: this.props.noBool ? e.target.checked ? moment().format("DD/MM/YYYY HH:mm") : false : e.target.checked,
      }
    })
  }

  render(){
    const {id, label,
      optional, value,
      customClass, i18n} = this.props;

    return (
      <div className={`${customClass ? "terms_and_conditions_wrapper" : "signUpcheckboxes"}`}>    
          <input
            type="checkbox"
            id={id}
            onChange={this.localHandle}
            required={!optional}
            checked={value}
          /> 
        <label
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html:
              i18n ? (clientT(`form.${id}`, {fallback: ''}) || label) : label}}
        />
      </div>
    )
  }
}
import React from 'react';
import {render} from 'react-dom';
import App from './App';
import "typeface-roboto";
import {BrowserRouter} from 'react-router-dom';
import PageLoader from "./containers/PageLoader";
import {useTranslation} from "react-i18next";

const I18nWrapper = () => {
  const {i18n} = useTranslation();
  return <App/>
}

render(<BrowserRouter>
  <React.Suspense fallback={<PageLoader loading />}>
    <I18nWrapper />
  </React.Suspense>
</BrowserRouter>, document.getElementById('root'));

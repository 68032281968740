import React, {forwardRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import '../style/dateRangePicker.scss';
import i18n, {t} from 'i18next';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

function DateRangePicker({dateRange, onChange}) {
  const [startDate, endDate] = dateRange;
  const dateRangesLabels = t('dashboardNew.dateRanges');
  const {today, this_month, two_months, three_months, six_months, this_year, all_time} = dateRangesLabels;
  const presets = [
    {id: 0, value: today, numberOfDays: 1},
    {id: 1, value: this_month, numberOfDays: 30},
    {id: 2, value: two_months, numberOfDays: 60},
    {id: 3, value: three_months, numberOfDays: 91},
    {id: 4, value: six_months, numberOfDays: 162},
    {id: 5, value: this_year, numberOfDays: 365},
    {id: 6, value: all_time, numberOfDays: -1},
  ]
  const [currentPreset, setCurrentPreset] = useState(presets.length - 1);

  const InputDatePicker = forwardRef(({value, onClick}, ref) => (
    <div className={"InputDatePicker"} onClick={onClick}>
      <FontAwesomeIcon icon={faCalendarAlt} />
      <input
        type={"text"}
        value={value || all_time}
        className={"date-picker-input"}
        ref={ref}
      />
    </div>
  ));

  const handlePreset = (preset) => {
    setCurrentPreset(preset.id);

    if (preset.numberOfDays === -1) return onChange([null, null]);

    const currentDate = new Date();
    const startDate = new Date(new Date().setDate(currentDate.getDate() - preset.numberOfDays));
    const dateRange = [startDate, currentDate];

    onChange(dateRange);
  }

  return (
    <div className="DateRangePicker">
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        monthsShown={2}
        placeholderText={all_time}
        dateFormat={"dd/MM/yyyy"}
        maxDate={new Date()}
        className="date-picker"
        customInput={<InputDatePicker />}
        locale={i18n.language === 'fr' ? fr : en}
      >
        {presets.length &&
          <div className="presets">
            {presets.map((preset) => {
              return (
                <button
                  type={"button"}
                  value={preset.value}
                  className={`preset ${preset.id === currentPreset ? 'current' : ''}`}
                  onClick={() => handlePreset(preset)}
                >
                  {preset.value}
                </button>
              )
            })}
          </div>
        }
      </DatePicker>
    </div>
  );
}

export default DateRangePicker;
import React, {useEffect, useState} from "react";
import Webcam from "react-webcam";
import {clientT, t} from "../../../tools/i18n";
import AppButton from "../../../components/AppButton";
import {ajaxCall, authClient, getUserData} from "../../../tools/apitools";
import Context from "../../../tools/context";
import {siteContext, getQueryString, customer} from "../../../tools/toolbox";
import {withRouter, useHistory} from "react-router-dom";
import ImageZone from "../../../components/ImageZone";
import {Loader} from 'semantic-ui-react';

const AskRefund = (props) => {
  const [file, setFile] = useState(null);
  const [showCam, setShowCam] = useState(false);
  const [tempPicture, setTempPicture] = useState(null);
  const {shortCode, ticketId} = props.match.params;
  const ticketList = decodeURIComponent(ticketId).split('|')
  const [user, setUser] = useState({});
  let history = useHistory();
  const keys = ['firstname', 'name'];
  const [seller, setSeller] = useState({});

  useEffect(() => {
    getUserData(keys, (res) => {
      console.log(res)
      setUser(res);
    }, (e) => {
      console.error(e);
    });
  }, []);

  useEffect(() => {
    getSeller(shortCode);
  }, []);

  const getSeller = async (shortCode) => {
    let s = null;
    if (shortCode === '-1') return;

    const res = await ajaxCall(
      `api/sellers/${Context.partner.sponsor}?customerId=${customer()}`,
      {}, true)
      .then((r) => {
        if (!r.ok) return;
        return r.json();
      });

    s = res.find((s) => s.shortcode === shortCode);
    if (!s) return;
    setSeller(s);
  }

  const callRefund = async () => {
    let refundRes;
    const myHeaders = new Headers();
    myHeaders.append("CIBLER_AUTH", siteContext().clientToken);

    const formData = new FormData();
    formData.append('codes', ticketList);
    formData.append('file', file);

    refundRes = await fetch(
      siteContext().endpoint + `api/prizes/refund/${Context.partner.customerId}`,
      {
        headers: myHeaders,
        method: 'POST',
        body: formData
      });

    if (!refundRes || !refundRes.ok) return;
    history.push('/refund/confirmation')
  };

  const handleConfirmation = () => {
    if (getQueryString('noBurn')) {
      try {
        authClient(
          `api/bienvenus/coupons/burn/${Context.partner.customerId}/?shortcode=${shortCode}&couponBurn=true`,
          {
            method: 'POST',
            body: JSON.stringify(ticketList)
          },
          callRefund
        );
      } catch (err) {
        console.error(err);
      }
    } else {
      callRefund();
    }
  }

  const handleUserChange = (value, key) => {
    setUser({...user, [key]: value})
  }

  const videoConstraints = {
    height: window.innerHeight,
    width: window.innerWidth,
    facingMode: 'environment'
  };

  const areDatasValid = () => {
    if (Context.partner?.features.userRefundFields) {
      return !!(user.firstname && user.name && user.iban && user.bic && file);
    }
    return !!file
  }

  return (
    <>
      <div className="Refund Ask">
        {Context.partner?.features.userRefundFields &&
          <>
            <div className={"title"}>{clientT('refund.ask.title')}</div>
            <div className={"subTitle"}>{clientT('refund.ask.subtitle')}</div>
          </>
        }
        <div className={"sellerWrapper"}>
          <div className={"sellerInfo sellerName"}>{seller.name}</div>
          {seller.name ?
            <>
              <div className={"sellerInfo sellerAddress"}>
              <div className={"sellerAddressIcon"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.03 127.03" height="18" width="20"><g id="Calque_2" data-name="Calque 2"><g id="Traits_de_coupe" data-name="Traits de coupe"><path fill="#94c11a" d="M46.51,0A46.52,46.52,0,0,0,0,46.51C0,74,46.51,127,46.51,127S93,76,93,46.51A46.52,46.52,0,0,0,46.51,0Zm0,60.42A15.73,15.73,0,1,1,62.24,44.69,15.73,15.73,0,0,1,46.51,60.42Z"></path></g></g></svg>
              </div>
              <div>
                <div>{(seller.street || '') + ' ' + (seller.city || '') + ' ' + (seller.zipcode || '')}</div>
              </div>
              </div>
              <div className="sellerInfo sellerShortcode">
                <div dangerouslySetInnerHTML={{__html: clientT('refund.ask.shortcode', {shortcode: seller.shortcode})}} />
                <div className="sellerShortcodeHelperMark">
                  <svg width="19" height="14" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><path d="M 500 0C 224 0 0 224 0 500C 0 776 224 1000 500 1000C 776 1000 1000 776 1000 500C 1000 224 776 0 500 0C 500 0 500 0 500 0M 501 191C 626 191 690 275 690 375C 690 475 639 483 595 513C 573 525 558 553 559 575C 559 591 554 602 541 601C 541 601 460 601 460 601C 446 601 436 581 436 570C 436 503 441 488 476 454C 512 421 566 408 567 373C 566 344 549 308 495 306C 463 303 445 314 411 361C 400 373 384 382 372 373C 372 373 318 333 318 333C 309 323 303 307 312 293C 362 218 401 191 501 191C 501 191 501 191 501 191M 500 625C 541 625 575 659 575 700C 576 742 540 776 500 775C 457 775 426 739 425 700C 425 659 459 625 500 625C 500 625 500 625 500 625"/></svg>
                  <div className="sellerShortcodeHelper" dangerouslySetInnerHTML={{__html: clientT('refund.ask.shortcodeHelper')}} />
                </div>
              </div>
            </> : (<Loader active inline='centered' style={{marginBottom: '35px'}} />)
          }
        </div>
        {Context.partner?.features.userRefundFields &&
          <>
            <div className={"userInfos"}>
              <div className={"field"}>
                <input
                  className={"subField"}
                  type={"text"}
                  value={user.firstname}
                  placeholder={clientT('form.firstname')}
                  onChange={(e) => handleUserChange(e.target.value, "firstname")}
                />
                <h5 className={"subFieldIcon"}>{clientT('form.firstname')}</h5>
              </div>
              <div className={"field"}>
                <input
                  className={"subField"}
                  type={"text"}
                  value={user.name}
                  placeholder={clientT('form.lastname')}
                  onChange={(e) => handleUserChange(e.target.value, "name")}
                />
                <h5 className={"subFieldIcon"}>{clientT('form.lastname')}</h5>
              </div>
              <div className={"field"}>
                <input
                  type={"text"}
                  className={"subField"}
                  placeholder={"XX 0000 0000 0000 0000 0000"}
                  value={user.iban}
                  pattern="^[a-zA-Z]{2}\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\S{4}\s?\S{7,}$"
                  onChange={(e) => handleUserChange(e.target.value, "iban")}
                />
                <h5 className={"subFieldIcon"}>IBAN</h5>
              </div>
              <div className={"field"}>
                <input
                  type={"text"}
                  className={"subField"}
                  placeholder={"0000 0000"}
                  value={user.bic}
                  minLength={"8"}
                  maxLength={"11"}
                  onChange={(e) => handleUserChange(e.target.value, "bic")}
                />
                <h5 className={"subFieldIcon"}>BIC</h5>
              </div>
            </div>
          </>
        }
        {!Context.partner?.features.userRefundFields &&
          <>
            <div className="title">{clientT('refund.ask.uploadTitle')}</div>
            <div className="subTitle">{clientT('refund.ask.uploadSubtitle')}</div>
            <div className="list">
              <div>
                <span>1</span> <div>{clientT('refund.ask.step1')}</div>
              </div>
              <div>
                <span>2</span> <div>{clientT('refund.ask.step2')}</div>
              </div>
            </div>
          </>
        }
        <div className="inputBlock">
          <ImageZone file={file} setFile={setFile} />
        </div>
        <div className="or">OU</div>
        <button className="takePhoto" onClick={() => setShowCam(true)}>{clientT('refund.ask.takePhoto')}</button>
        {
          showCam && !tempPicture && <div
            className="pictureOverlayer">
            <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            height={window.innerHeight}
            width={window.innerWidth}
          >
            {({ getScreenshot }) => (
              <button
                className="snap"
                onClick={() => {
                  const imageSrc = getScreenshot()
                  setTempPicture(imageSrc);
                }}
              >
                <svg width="50" height="50" viewBox="0 0 50 50">
                  <defs>
                    <clipPath id="clip-path">
                      <rect width="50" height="50" fill="none" />
                    </clipPath>
                  </defs>
                  <g id="Camera" clipPath="url(#clip-path)">
                    <rect
                      id="Rectangle_252"
                      data-name="Rectangle 252"
                      width="50"
                      height="50"
                      fill="none"
                    />
                    <path
                      id="Tracé_110"
                      data-name="Tracé 110"
                      d="M25,32.667A6.54,6.54,0,0,0,25,19.6a6.54,6.54,0,0,0,0,13.067Zm0-19.6c6.875,0,12.5,5.88,12.5,13.067S31.875,39.2,25,39.2,12.5,33.32,12.5,26.133,18.125,13.067,25,13.067ZM43.75,49H6.25a6.042,6.042,0,0,1-4.375-1.96A6.6,6.6,0,0,1,0,42.467v-29.4A6.415,6.415,0,0,1,6.25,6.533h5l4.688-4.9A5.068,5.068,0,0,1,19.688,0H30.625a4.925,4.925,0,0,1,3.438,1.633l4.688,4.9h5A6.415,6.415,0,0,1,50,13.067v29.4A6.415,6.415,0,0,1,43.75,49Z"
                      transform="translate(0 1)"
                      fill="rgba(0,136,142,0.75)"
                      fillRule="evenodd"
                    />
                  </g>
                </svg>
              </button>
            )}
          </Webcam>
            <button className="close" onClick={() => setShowCam(false)}>X</button>
          </div>
        }
        {Context.partner?.features.userRefundFields &&
          <>
            <div className="list">
              <div>
                <span>1</span> <div>{clientT('refund.ask.step1')}</div>
              </div>
              <div>
                <span>2</span> <div>{clientT('refund.ask.step2')}</div>
              </div>
            </div>
          </>
        }
        {showCam && tempPicture && <div className="pictureOverlayer">
          <img src={tempPicture}/>
          <button className="cancel" onClick={() => setTempPicture(null)}>X</button>
          <button className="valid"
            onClick={() => {
            fetch(tempPicture)
              .then(res => res.blob())
              .then(blob => {
                const file = new File([blob], "File name",{ type: "image/png" })
                setFile(file);
                setTempPicture(null);
                setShowCam(false);
              })
          }}>✔&#xfe0e;</button>
          <button className="close" onClick={() => setShowCam(false)}>X</button>
        </div>}
        {areDatasValid() &&
        <div className={`validationLayer`}>
          {clientT('refund.ask.oneProof')}

            <div className="buttons">
              <AppButton
                className={`secButton`}
                client
                event="Précédent"
                onClick={() => {
                }}>{t('common.previous')}</AppButton>
              <AppButton
                className={`mainButton`}
                event="Suivant"
                client
                onClick={handleConfirmation}>{t('common.next')}</AppButton>
            </div>
        </div>}
      </div>
    </>
  );
}

export default withRouter(AskRefund);
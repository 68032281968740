import React, { Component } from 'react';
import '../../style/TicketsPage.scss';
import moment from 'moment';
import { Context } from '../../tools/context';
import Ticket from "./ticket";
import {getPartnerDenomination, getTicketDenomination} from "../../tools/toolbox";
import AppLink from '../../components/AppLink';
import AppButton from '../../components/AppButton';
import {t, clientT} from '../../tools/i18n'
import {track} from "../../tools/tracking";
import LoaderSpinner from 'react-loader-spinner';

class MyTickets extends Component {
  constructor() {
    super();

    this.state = {
      activationForm: false,
      activationCode: '',
    }
  }

  componentDidMount() {
    const {selectedTickets, shortcode} = this.props;

    if (!Array.isArray(selectedTickets)) return;
    track('viewMyTickets', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
      shortcode: shortcode
    });
  }

  getNextSunday = () => {
    const sunday = 7;
    const today = moment().isoWeekday();

    return today <= sunday
      ? moment().isoWeekday(sunday)
      : moment().add(1, 'weeks').isoWeekday(sunday);
  };

  renderTiCoHeader = () => {
    if (Context.partner?.platform !== 'ticketcommercant') return null;

    return <div className="ticoHeader">
      {/* <img src={`/assets/${Context.partner.sponsor}_logo.png`}
           alt="" title="logo"
      />
      <AppLink href="https://ticket-commercant.fr//" client>Ceci n'est pas votre ville ?</AppLink> */}
      <AppLink style={{marginTop: '15px'}} href="https://bienvenus.zendesk.com/hc/fr" client>
        <AppButton client event="Comment ça marche" className="mainButton">{clientT('myTickets.buttonLabel')}</AppButton>
      </AppLink>
    </div>
  }

  renderActivation = () => {
    if (!Context.features.showTicketActivation) return null;

    if (!this.state.activationForm) {
      return (
        <div className="activation">
          <AppButton client event={`ACTIVER un code ${Context.partner.partnerPlatform}`} className="mainButton" onClick={() => this.setState({activationForm: true})}>
          {clientT('myTickets.activateCode')}
          </AppButton>
        </div>
      )
    }

    return <div className="activation">
      <div>{
        Context.partner.client?.ticketActivationLabel
        || clientT('myTickets.ticketActivationLabel')
      }
      </div>
      <div>
        {this.props.activationError && <div className="error">{this.props.activationError}</div>}
        <input
          placeholder="IFNKHXT89UI"
          value={this.state.activationCode}
          onChange={(e) => this.setState({activationCode: e.target.value})}
        />
        <AppButton event="Valider" client className="mainButton" onClick={() => this.props.handleActivateCode(this.state.activationCode)} style={{width: '100px'}}>
          {this.props.activationLoader ?
            <LoaderSpinner
              type="ThreeDots"
              color={"white"}
              height={9}
              width={40}
            /> : clientT('myTickets.codeValidationButtonLabel')
          }
        </AppButton>
      </div>
    </div>
  }

  toDisplay = () => {
    const {tickets} = this.props;
    if (!tickets.length) return <div className="noTicket">
      <div>{clientT('myTickets.step1.noticket')}</div>
      {Context.features.kawaiiMode && <div><img style={{
        width: '150px', height: 'auto', marginTop: '40px'
      }} src={'assets/icons/chatTCASleep.png'}/></div>}
    </div>

    return (
      <div className='ticketList'>
        {Context.partner?.client?.myTicketsTitle &&
        <div className="explanation">{Context.partner?.client?.myTicketsTitle}</div>}
        {tickets.map((ticket, index) => <Ticket
          key={ticket.uuid}
          ticket={ticket}
          index={index}
          selected={this.props.selectedTickets.find(t => t.uuid === ticket.uuid)}
          handleSelection={this.props.handleSelection}
        />)}
      </div>
    )
  };

  render() {
    const partnerListURL = (Context.partner.partnerListUrl || '/#map').replace('/#map', '/etablissements#map');
    const steps = clientT('myTickets.step1.steps') || [];

    return (
      <div className='my-tickets-container'>
        <h1 className="title">{clientT('myTickets.step1.title')}</h1>
        <hr />
        {this.renderTiCoHeader()}
        {this.renderActivation()}
        <div className="subtitle">{clientT('myTickets.step1.subtitle')}</div>
        {Context.partner.sponsor === 'myprovence' && <div className="steps">
          {(steps.map ? steps : [steps]).map((text, index) => {
            return <div key={index} className={"step"}>
              <div className="number">{index + 1}</div>
              <div className="stepLabel" dangerouslySetInnerHTML={{__html: text}} />
            </div>
          })}
        </div>}
        {this.toDisplay()}
        {clientT('myTickets.partnerList', {fallback: ''})
            && <AppLink client href={partnerListURL || "#"}>
              {clientT('myTickets.partnerList')}
            </AppLink>
        }

      </div>
    );
  }
}

export default MyTickets;

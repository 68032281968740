import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import { reactFormatter, ReactTabulator } from 'react-tabulator';
import { authCall } from '../tools/apitools';
import Logo from '../components/Logo';
import '../style/adminPage.scss';
import { Context, ContextUtil } from '../tools/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {customer, getQueryString} from "../tools/toolbox";
import moment from 'moment';
import AppButton from '../components/AppButton';
import AppLink from "../components/AppLink";

class WithdrawPage extends Component {
  constructor() {
    super();

    this.tabRef = React.createRef();

    this.state = {
      withdraws: [],
      filter: '',
      errorMessage: null,
      tableAction: '',
      statusChange_Message: '',
      withdrawStatus:getQueryString('status') != null ?getQueryString('status') : 'PENDING'
    };
  }

  componentDidMount() {
    document.title = 'Liste des requêtes';

    authCall(
      '/api/admins/withdraw?status='+ this.state.withdrawStatus ,
      {},
      (r) => {
        console.log(r);
        this.setState({ withdraws: r });
      },
      () =>
        this.setState({
          errorMessage:
            'Une erreur est survenue pendant le chargement des informations',
        })
    );
  }
  handleTableActionChoice = (event) => {
    this.setState({ tableAction: event.target.value });
  };

  handleWithdrawStatusChoice = (event) => {
    this.setState({ withdrawStatus: event.target.value });
  };

  triggerActionOnWithdrawStatus = () => {
    this.props.history.push('/wtd?status='+this.state.withdrawStatus);
    window.location.reload();
  }

  triggerActionOnTable = () => {
    if (this.state.tableAction === 'export_csv') {
      this.downloadCsv();
    } else if (this.state.tableAction === 'approve') {
      this.handleTransferStatus('VALIDATED');
    } else if (this.state.tableAction === 'decline') {
      this.handleTransferStatus('CANCELED');
    } else if (this.state.tableAction === 'export_pdf') {
      console.log('pdf export');
    }
  };

  downloadCsv = () => {
    this.tabRef.current.table.download('csv', 'data.csv', { delimiter: ';' });
  };

  handleTransferStatus = async (newStatus) => {
    // set an array  listing  ids of selected rows
    let selectedIdList = [];
    const selectedRows = this.tabRef.current.table.getSelectedData();
    selectedRows.forEach((row) => {
      selectedIdList.push(row.id);
    });
    // set post request's body
    let body = {
      status: newStatus,
      idList: selectedIdList,
    };

    await authCall(
      'api/sellers/withdraw/batch',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      (result) => {
        const withdrawsTemp = [...this.state.withdraws];
        result.forEach((w) => {
          for (let withdraw of withdrawsTemp) {
            if (w.id === withdraw.id) {
              withdraw.status = w.status;
            }
          }
        });
        this.setState({
          statusChange_Message: 'Changement de statut réalisé',
          withdraws: withdrawsTemp,
        });
      },
      () => {
        this.setState({
          statusChange_Message: 'Le changement de statut a échoué ',
        });
      }
    );
  };

  test = (input, filter) => {
    return (input + '' || '').toLowerCase().includes(filter.toLowerCase());
  };

  formatCreationDate = (cell, params, onRendered) => {
    const dateToFormat = cell.getRow().getData().creationDate;
    const dateFormatted = moment(dateToFormat).format('DD/MM/YYYY');
    return reactFormatter(<div>{dateFormatted}</div>)(cell, params, onRendered);
  };

  formatStatusChangeDate = (cell, params, onRendered) => {
    const dateToFormat = cell.getRow().getData().statusChangedDate;
    const dateFormatted = moment(dateToFormat).format('DD/MM/YYYY');
    return reactFormatter(<div>{dateFormatted}</div>)(cell, params, onRendered);
  };

  formatAmount = (cell, params, onRendered) => {
    const amount = Number.parseFloat(cell.getRow().getData().amount).toFixed(2);
    return reactFormatter(<div>{amount} €</div>)(cell, params, onRendered);
  };

  formatCheckBox = (cell, params, onRendered) => {
    return reactFormatter(<input type='checkbox' />)(cell, params, onRendered);
  };

  render() {
    const columns = [
      {
        formatter: 'rowSelection',
        titleFormatter: 'rowSelection',
        align: 'center',
        headerSort: false,
        download: false,
      },
      { title: 'ID', field: 'id', downloadTitle: 'Entete', width: 150 },
      {
        title: 'DATE CREATION',
        field: 'creationDate',
        downloadTitle: 'Date signature mandat',
        width: 180,
        formatter: this.formatCreationDate,
      },
      {
        title: 'VENDEUR',
        field: 'sellerName',
        downloadTitle: 'Nom',
        width: 200,
      },
      { title: 'IBAN', field: 'sellerIBAN', downloadTitle: 'Iban', width: 150 },
      { title: 'BIC', field: 'sellerBIC', downloadTitle: 'Bic', width: 150 },
      { title: 'DOC', field: 'refundUUID', downloadTitle: 'Bic', width: 150,formatter: this.formatLink },

      {
        title: 'MONTANT',
        field: 'amount',
        downloadTitle: 'Montant',
        width: 150,
        formatter: this.formatAmount,
        hozAlign: 'center',
      },
      {
        title: 'BUDGET',
        field: 'budgetName',
        downloadTitle: 'Rum',
        width: 200,
      },
      {
        title: 'CLIENT',
        field: 'customerName',
        downloadTitle: 'Libelle',
        width: 250,
      },
      { title: 'STATUT', field: 'status', width: 200, download: false },
      {
        title: 'CHANGEMENT STATUT',
        field: 'statusChangedDate',
        width: 200,
        formatter: this.formatStatusChangeDate,
        download: false,
      },
    ];

    const options = {
      downloadDataFormatter: (data) => data,
      downloadReady: (fileContents, blob) => blob,
      downloadRowRange: 'selected',
      pagination: 'local',
      paginationSize: 20,
    };

    const { filter } = this.state;
    const filtered = filter
      ? this.state.withdraws.filter(
          (w) =>
            this.test(w.id, filter) ||
            this.test(w.creationDate, filter) ||
            this.test(w.sellerName, filter) ||
            this.test(w.sellerIBAN, filter) ||
            this.test(w.sellerBIC, filter) ||
            this.test(w.amount, filter) ||
            this.test(w.budgetName, filter) ||
            this.test(w.customerName, filter) ||
            this.test(w.statusChangedDate, filter)
        )
      : this.state.withdraws;

    return (
      <div className='adminPage'>
        <div className='title'>REQUÊTES DE VIREMENT</div>
        {this.state.errorMessage ? (
          <div className='register_error'>{this.state.errorMessage}</div>
        ) : null}
        <div>
          <input
            className='adminSearch'
            placeholder='id,vendeur , status...'
            onChange={(e) => this.setState({ filter: e.target.value })}
          />
        </div>
        <div className='action_list'>
          <select
            value={this.state.withdrawStatus}
            onChange={this.handleWithdrawStatusChoice}
          >
            <option value=''>-</option>
            <option value='PENDING'>En attente</option>
            <option value='PROCESSING'>En cours</option>
            <option value='VALIDATED'>Validés</option>
            <option value='CANCELED'>Annulés</option>
            <option value='REFUNDASKED'>Remboursement</option>
            <option value='REFUNDED'>Remboursé</option>

          </select>
          <AppButton event="Voir les virements" onClick={this.triggerActionOnWithdrawStatus}>Voir les virements</AppButton>
        </div>

        <div className='action_list'>
          <select
            value={this.state.tableAction}
            onChange={this.handleTableActionChoice}
          >
            <option value=''>-</option>
            <option value='approve'>Valider</option>
            <option value='decline'>Refuser</option>
            <option value='export_csv'>Export CSV</option>
            <option value='export-pdf'>Export PDF</option>
          </select>
          <AppButton event="Appliquer" onClick={this.triggerActionOnTable}>Appliquer</AppButton>
        </div>

        <h1 className='statusChangeMessage'>
          {this.state.statusChange_Message}
        </h1>
        <ReactTabulator
          ref={this.tabRef}
          data={[...filtered]}
          columns={columns}
          layout={'fitData'}
          options={options}
        />
      </div>
    );
  }

  formatLink = (cell, params, onRendered) => {
      const url = cell.getRow().getData().refundUUID;
      return reactFormatter(
          <div className='contract_signature_icon'>
            <AppLink target='_blank' href={url}>
              <FontAwesomeIcon icon='file-signature' size='lg'/>
            </AppLink>
          </div>
      )(cell, params, onRendered);
    };
}

export default withRouter(WithdrawPage);

import React, {Component} from "react";
import {Context} from "../../tools/context";
import Logo from "../../components/Logo";

export default ({children}) => (
  <div className='login bvnLogin'>
    {Context.partner.login && Context.partner.login.customImage ?
      <div className="loginImageDesktop">
        <img src={`/assets/${Context.partner.sponsor}_login_desktop.png`} />
      </div>
      : null
    }
    <div className="formBody">
      <Logo/>
      {Context.partner.login && Context.partner.login.customImage ?
        <div className="loginImageMobile">
          <img src={`/assets/${Context.partner.sponsor}_login_mobile.png`} />
        </div>
        : null
      }
      {children}
    </div>
  </div>
)